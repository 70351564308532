import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import _ from 'lodash';
import moment from 'moment';
import { encode } from '@googlemaps/polyline-codec';
import { getFillRate, getLoadingTime } from '@utils/editMode';
import { convertOrderToStop } from '@utils/convertObjects';
var DISTANCE_TIME = 30;

var getStopByTrip = function getStopByTrip(deliverDetail) {
  var setIndexDeliverDetail = deliverDetail.map(function (item, index) {
    return _objectSpread(_objectSpread({}, item), {}, {
      indexStop: index
    });
  });
  var setIndexDepot = setIndexDeliverDetail.filter(function (item) {
    return item.depotInfo;
  });
  return setIndexDepot.map(function (item, index) {
    var indexStart = _.get(setIndexDepot[index], 'indexStop');

    var indexEnd = _.get(setIndexDepot[index + 1], 'indexStop');

    return setIndexDeliverDetail.slice(indexStart + 1, indexEnd);
  });
};

var getFirstTripCode = function getFirstTripCode(deliverDetail) {
  var tripCode = _.get(_.head(deliverDetail), 'tripCode');

  var indexSliceTripCode = tripCode.lastIndexOf('#');
  return tripCode.slice(0, indexSliceTripCode);
};

var removeStopFromRoute = function removeStopFromRoute(_ref) {
  var newRouteList = _ref.newRouteList,
      item = _ref.item,
      isPNS = _ref.isPNS,
      dataOrg = _ref.dataOrg;
  var routeAfterUpdate = newRouteList.find(function (i) {
    var _item$;

    return i.shiftCode === ((_item$ = item[0]) === null || _item$ === void 0 ? void 0 : _item$.route.shiftCode);
  });

  var deliverDetailShift = _.get(routeAfterUpdate, 'deliverDetail');

  var depot = _.head(deliverDetailShift);

  var dataStopByTrip = getStopByTrip(deliverDetailShift);
  var firstTripCode = getFirstTripCode(deliverDetailShift);
  var dataStopByTripAfter = dataStopByTrip;
  item.forEach(function (stop) {
    var indexStopPrev = stop.indexStopPrev;
    dataStopByTripAfter = dataStopByTripAfter.map(function (stopByTrip) {
      return stopByTrip.filter(function (stops) {
        return indexStopPrev !== (stops === null || stops === void 0 ? void 0 : stops.indexStop);
      });
    });
  });
  var dataDeliverDetailFinal = [depot];
  var numberTrip = 2;
  dataStopByTripAfter.forEach(function (stopByTrip, indexTrip) {
    if (stopByTrip.length > 0) {
      dataDeliverDetailFinal = [].concat(_toConsumableArray(dataDeliverDetailFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, depot), {}, {
        tripCode: indexTrip == dataStopByTripAfter.length - 1 ? null : "".concat(firstTripCode, "#").concat(numberTrip)
      })]);
      numberTrip++;
    }
  });

  var newShift = _objectSpread(_objectSpread({}, routeAfterUpdate), {}, {
    deliverDetail: dataDeliverDetailFinal
  });

  var shiftAfterUpdateFillRate = getFillRate(newShift);
  var shiftAfterUpdateLoadingTime = getLoadingTime(shiftAfterUpdateFillRate, false, isPNS, dataOrg);
  var isRemoveDragShift = dataDeliverDetailFinal.filter(function (i) {
    return i.storeInfo;
  });

  if (isRemoveDragShift.length < 1) {
    newRouteList = newRouteList.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.shiftCode) !== (routeAfterUpdate === null || routeAfterUpdate === void 0 ? void 0 : routeAfterUpdate.shiftCode);
    });
  }

  return newRouteList.map(function (dataRoute) {
    return routeAfterUpdate.shiftCode === (dataRoute === null || dataRoute === void 0 ? void 0 : dataRoute.shiftCode) ? shiftAfterUpdateLoadingTime : dataRoute;
  });
};

var moveStopToRoute = function moveStopToRoute(_ref2) {
  var newRouteList = _ref2.newRouteList,
      toShift = _ref2.toShift,
      position = _ref2.position,
      isPNS = _ref2.isPNS,
      stop = _ref2.stop,
      dataOrg = _ref2.dataOrg;
  var toShiftAfterUpdate = newRouteList.find(function (i) {
    return i.shiftCode === toShift.shiftCode;
  });

  var deliverDetailToShift = _.get(toShiftAfterUpdate, 'deliverDetail');

  var depotToShift = _.head(deliverDetailToShift);

  var dataStopByTripOfToShift = getStopByTrip(deliverDetailToShift);
  var firstTripCodeToShift = getFirstTripCode(deliverDetailToShift);
  var dataStopByTripOfToShiftAfter = dataStopByTripOfToShift.map(function (stopByTrip) {
    var duplicateStoreByTrip = _.find(stopByTrip, function (i) {
      var _i$storeInfo, _stop$storeInfo;

      return ((_i$storeInfo = i.storeInfo) === null || _i$storeInfo === void 0 ? void 0 : _i$storeInfo.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo = stop.storeInfo) === null || _stop$storeInfo === void 0 ? void 0 : _stop$storeInfo.customerCode);
    });

    var isTrip = position >= _.get(_.head(stopByTrip), 'indexStop') && position <= _.get(_.last(stopByTrip), 'indexStop') + 1;

    if (isTrip) {
      if (duplicateStoreByTrip) {
        var dataFinalDuplicateStop = _objectSpread(_objectSpread({}, duplicateStoreByTrip), {}, {
          orderInfo: [].concat(_toConsumableArray(duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.orderInfo), _toConsumableArray(stop === null || stop === void 0 ? void 0 : stop.orderInfo)),
          totalVolume: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalVolume) + (stop === null || stop === void 0 ? void 0 : stop.totalVolume),
          totalWeight: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalWeight) + (stop === null || stop === void 0 ? void 0 : stop.totalWeight)
        });

        return stopByTrip.map(function (stops) {
          var _stops$storeInfo, _stop$storeInfo2;

          return (stops === null || stops === void 0 ? void 0 : (_stops$storeInfo = stops.storeInfo) === null || _stops$storeInfo === void 0 ? void 0 : _stops$storeInfo.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo2 = stop.storeInfo) === null || _stop$storeInfo2 === void 0 ? void 0 : _stop$storeInfo2.customerCode) ? dataFinalDuplicateStop : stops;
        });
      } else {
        var isPositionByTrip = position === _.get(_.last(stopByTrip), 'indexStop') + 1 ? _.get(_.last(stopByTrip), 'indexStop') + 1 : _.findIndex(stopByTrip, function (i) {
          return i.indexStop === position;
        });

        if (isPositionByTrip >= 0) {
          stopByTrip.splice(isPositionByTrip, 0, stop);
          return stopByTrip;
        }
      }
    }

    return stopByTrip;
  });

  if (position === 0) {
    dataStopByTripOfToShiftAfter = [[stop]].concat(_toConsumableArray(dataStopByTripOfToShiftAfter));
  }

  if (position >= deliverDetailToShift.length) {
    dataStopByTripOfToShiftAfter = [].concat(_toConsumableArray(dataStopByTripOfToShiftAfter), [[stop]]);
  }

  var dataDeliverDetailToShiftFinal = [depotToShift];
  var numberTripToShift = 2;
  dataStopByTripOfToShiftAfter.forEach(function (stopByTrip, indexTrip) {
    if (stopByTrip.length > 0) {
      dataDeliverDetailToShiftFinal = [].concat(_toConsumableArray(dataDeliverDetailToShiftFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, depotToShift), {}, {
        tripCode: indexTrip == dataStopByTripOfToShiftAfter.length - 1 ? null : "".concat(firstTripCodeToShift, "#").concat(numberTripToShift)
      })]);
      numberTripToShift++;
    }
  });

  var newToShift = _objectSpread(_objectSpread({}, toShift), {}, {
    deliverDetail: dataDeliverDetailToShiftFinal
  });

  var toShiftAfterUpdateFillRate = getFillRate(newToShift);
  var toShiftAfterUpdateLoadingTime = getLoadingTime(toShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  return newRouteList.map(function (item) {
    return item.shiftCode === toShift.shiftCode ? toShiftAfterUpdateLoadingTime : item;
  });
};

function insert(arr, index) {
  for (var _len = arguments.length, items = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    items[_key - 2] = arguments[_key];
  }

  return [].concat(_toConsumableArray(arr.slice(0, index)), items, _toConsumableArray(arr.slice(index)));
}

var convertTimeToNext = function convertTimeToNext(_ref3) {
  var time = _ref3.time,
      isDoneTime = _ref3.isDoneTime,
      distance = _ref3.distance;
  var splitTimes = time.split(':');
  var newTime = splitTimes[0] * 60 + splitTimes[1] * 1 + DISTANCE_TIME * distance + 15 * (distance - 1) + (isDoneTime ? 15 : 0);
  var hh = Math.floor(newTime / 60);
  var mm = newTime - hh * 60;
  return "".concat(hh >= 10 ? hh : "0".concat(hh), ":").concat(mm);
};

var convertToNextStop = function convertToNextStop(stop, distance) {
  var currentDoneTime = _.get(stop, 'doneTime', '0');

  var nextDelieryTime = convertTimeToNext({
    time: currentDoneTime,
    isDoneTime: false,
    distance: distance
  });
  var nextDoneTime = convertTimeToNext({
    time: currentDoneTime,
    isDoneTime: true,
    distance: distance
  });
  return {
    deliverTime: nextDelieryTime,
    doneTime: nextDoneTime
  };
};

var updateTimeFromPosition = function updateTimeFromPosition(_ref4) {
  var deliverDetail = _ref4.deliverDetail,
      position = _ref4.position;
  return deliverDetail.map(function (deliver, index) {
    if (index <= position) return deliver;
    var newDeliveryDoneTime = convertToNextStop(deliverDetail[position], index - position);

    var newDeliver = _objectSpread(_objectSpread({}, deliver), newDeliveryDoneTime);

    return newDeliver;
  });
};

var getEncodedPathFromDeliverDetail = function getEncodedPathFromDeliverDetail(deliverDetail) {
  var xxx = [];

  for (var i = 0; i < deliverDetail.length; i++) {
    if (i > 0) {
      var lastPosition = _.get(deliverDetail[i - 1], 'depotInfo.coordinate') || _.get(deliverDetail[i - 1], 'storeInfo.coordinate');

      var currPosition = _.get(deliverDetail[i], 'storeInfo.coordinate') || _.get(deliverDetail[i], 'depotInfo.coordinate');

      var path = [[lastPosition === null || lastPosition === void 0 ? void 0 : lastPosition.latitude, lastPosition === null || lastPosition === void 0 ? void 0 : lastPosition.longitude], [currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude]];
      xxx.push(encode(path, 5));
    }
  }

  return xxx;
};

var getEncodedPathAfterDnDMissingOrder = function getEncodedPathAfterDnDMissingOrder(deliverDetail, position, encodedPath) {
  var lastPosition = _.get(deliverDetail[position - 1], 'depotInfo.coordinate') || _.get(deliverDetail[position - 1], 'storeInfo.coordinate');

  var currPosition = _.get(deliverDetail[position], 'storeInfo.coordinate') || _.get(deliverDetail[position], 'depotInfo.coordinate');

  var path = [[lastPosition === null || lastPosition === void 0 ? void 0 : lastPosition.latitude, lastPosition === null || lastPosition === void 0 ? void 0 : lastPosition.longitude], [currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude]];
  var encodedPathAdded = encode(path, 5);
  var xxx = encodedPath.map(function (item, index) {
    return index === position - 1 ? encodedPathAdded : item;
  });

  var nextPosition = _.get(deliverDetail[position + 1], 'storeInfo.coordinate') || _.get(deliverDetail[position + 1], 'depotInfo.coordinate');

  if (nextPosition) {
    var nextPath = [[currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude], [nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.latitude, nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.longitude]];
    var encodedNextPathAdded = encode(nextPath, 5);
    xxx.splice(position, 0, encodedNextPathAdded);
    return xxx;
  }

  return xxx;
};

var getEncodedPathDragShift = function getEncodedPathDragShift(_ref5) {
  var dragShift = _ref5.dragShift,
      stop = _ref5.stop;

  var encodedPathDragShift = _.get(dragShift, 'encodedPath');

  var deliverDetail = _.get(dragShift, 'deliverDetail');

  var stopIndex = _.findIndex(deliverDetail, function (d) {
    var _d$storeInfo;

    return ((_d$storeInfo = d.storeInfo) === null || _d$storeInfo === void 0 ? void 0 : _d$storeInfo.customerCode) === stop.storeInfo.customerCode;
  });

  encodedPathDragShift = encodedPathDragShift.filter(function (i, index) {
    return index !== stopIndex - 1 && index !== stopIndex;
  });

  var prevPosition = _.get(deliverDetail[stopIndex - 1], 'depotInfo.coordinate') || _.get(deliverDetail[stopIndex - 1], 'storeInfo.coordinate');

  var nextPosition = _.get(deliverDetail[stopIndex + 1], 'storeInfo.coordinate') || _.get(deliverDetail[stopIndex + 1], 'depotInfo.coordinate');

  var path = [[prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.latitude, prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.longitude], [nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.latitude, nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.longitude]];
  var encodedPathAdded = encode(path, 5);
  encodedPathDragShift.splice(stopIndex - 1, 0, encodedPathAdded);
  return encodedPathDragShift;
};

var getEnCodePathDndStopOnShift = function getEnCodePathDndStopOnShift(_ref6) {
  var shift = _ref6.shift,
      indexStopAfter = _ref6.indexStopAfter,
      indexStopBefor = _ref6.indexStopBefor,
      stop = _ref6.stop;

  var enCodePath = _.get(shift, 'encodedPath');

  var deliverDetail = _.get(shift, 'deliverDetail');

  var currPositionAfter = _.get(stop, 'depotInfo.coordinate') || _.get(stop, 'storeInfo.coordinate');

  var prevPositionAfter = _.get(deliverDetail[indexStopAfter], 'depotInfo.coordinate') || _.get(deliverDetail[indexStopAfter], 'storeInfo.coordinate');

  var nextPositionAfter = _.get(deliverDetail[indexStopAfter + 1], 'storeInfo.coordinate') || _.get(deliverDetail[indexStopAfter + 1], 'depotInfo.coordinate');

  var prevPositionBefor = _.get(deliverDetail[indexStopBefor - 1], 'depotInfo.coordinate') || _.get(deliverDetail[indexStopBefor - 1], 'storeInfo.coordinate');

  var nextPositionBefor = _.get(deliverDetail[indexStopBefor + 1], 'storeInfo.coordinate') || _.get(deliverDetail[indexStopBefor + 1], 'depotInfo.coordinate');

  var prevPathAfter = [[prevPositionAfter === null || prevPositionAfter === void 0 ? void 0 : prevPositionAfter.latitude, prevPositionAfter === null || prevPositionAfter === void 0 ? void 0 : prevPositionAfter.longitude], [currPositionAfter === null || currPositionAfter === void 0 ? void 0 : currPositionAfter.latitude, currPositionAfter === null || currPositionAfter === void 0 ? void 0 : currPositionAfter.longitude]];
  var nextPathAfter = [[currPositionAfter === null || currPositionAfter === void 0 ? void 0 : currPositionAfter.latitude, currPositionAfter === null || currPositionAfter === void 0 ? void 0 : currPositionAfter.longitude], [nextPositionAfter === null || nextPositionAfter === void 0 ? void 0 : nextPositionAfter.latitude, nextPositionAfter === null || nextPositionAfter === void 0 ? void 0 : nextPositionAfter.longitude]];
  var pathBefor = [[prevPositionBefor === null || prevPositionBefor === void 0 ? void 0 : prevPositionBefor.latitude, prevPositionBefor === null || prevPositionBefor === void 0 ? void 0 : prevPositionBefor.longitude], [nextPositionBefor === null || nextPositionBefor === void 0 ? void 0 : nextPositionBefor.latitude, nextPositionBefor === null || nextPositionBefor === void 0 ? void 0 : nextPositionBefor.longitude]];
  var prevEncodePathAfter = encode(prevPathAfter, 5);
  var nextEncodePathAfter = encode(nextPathAfter, 5);
  var slicePathEncodePathBefor = encode(pathBefor, 5);

  if (indexStopBefor > indexStopAfter) {
    enCodePath.splice(indexStopAfter - 1, 1, prevEncodePathAfter, nextEncodePathAfter);
    enCodePath.splice(indexStopBefor, 2, slicePathEncodePathBefor);
  } else {
    enCodePath.splice(indexStopAfter, 1, prevEncodePathAfter, nextEncodePathAfter);
    enCodePath.splice(indexStopBefor - 1, 2, slicePathEncodePathBefor);
  }

  return enCodePath;
};

var getEncodedPathDropShift = function getEncodedPathDropShift(_ref7) {
  var dropShift = _ref7.dropShift,
      position = _ref7.position,
      isStopCodeOnDropShift = _ref7.isStopCodeOnDropShift;

  var encodedPathDragShift = _.get(dropShift, 'encodedPath');

  var deliverDetail = _.get(dropShift, 'deliverDetail');

  if (isStopCodeOnDropShift) return encodedPathDragShift;

  var currPosition = _.get(deliverDetail[position], 'depotInfo.coordinate') || _.get(deliverDetail[position], 'storeInfo.coordinate');

  var prevPosition = _.get(deliverDetail[position - 1], 'depotInfo.coordinate') || _.get(deliverDetail[position - 1], 'storeInfo.coordinate');

  var nextPosition = _.get(deliverDetail[position + 1], 'storeInfo.coordinate') || _.get(deliverDetail[position + 1], 'depotInfo.coordinate');

  var prevPath = [[prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.latitude, prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.longitude], [currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude]];
  var nextPath = [[currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude], [nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.latitude, nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.longitude]];
  var prevEncodedPath = encode(prevPath, 5);
  var nextEncodedPath = encode(nextPath, 5);
  encodedPathDragShift.splice(position - 1, 1, prevEncodedPath, nextEncodedPath);
  return encodedPathDragShift;
};

var getEncodePathRemoveStop = function getEncodePathRemoveStop(_ref8) {
  var route = _ref8.route,
      indexStore = _ref8.indexStore;

  var encodePath = _.get(route, 'encodedPath');

  var deliverDetail = _.get(route, 'deliverDetail');

  if (indexStore) {
    var prevPosition = _.get(deliverDetail[indexStore - 1], 'depotInfo.coordinate') || _.get(deliverDetail[indexStore - 1], 'storeInfo.coordinate');

    var nextPosition = _.get(deliverDetail[indexStore + 1], 'storeInfo.coordinate') || _.get(deliverDetail[indexStore + 1], 'depotInfo.coordinate');

    var path = [[prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.latitude, prevPosition === null || prevPosition === void 0 ? void 0 : prevPosition.longitude], [nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.latitude, nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.longitude]];
    var renderEncodePath = encode(path, 5);
    encodePath.splice(indexStore - 1, 2, renderEncodePath);
    return encodePath;
  }
};

var handleAddExistMissingOrderToShift = function handleAddExistMissingOrderToShift(_ref9) {
  var shift = _ref9.shift,
      missingOrder = _ref9.missingOrder,
      routeList = _ref9.routeList;

  var deliverDetail = _.get(shift, 'deliverDetail', []);

  var deliverOfOrderOnRoute = _.find(deliverDetail, function (deliver) {
    var _deliver$storeInfo;

    return ((_deliver$storeInfo = deliver.storeInfo) === null || _deliver$storeInfo === void 0 ? void 0 : _deliver$storeInfo.customerCode) === _.get(missingOrder, 'customerInfo.customerCode');
  });

  var newDeliver = _objectSpread(_objectSpread({}, deliverOfOrderOnRoute), {}, {
    orderInfo: [].concat(_toConsumableArray(deliverOfOrderOnRoute.orderInfo), [missingOrder])
  });

  var newShift = _objectSpread(_objectSpread({}, shift), {}, {
    deliverDetail: deliverDetail.map(function (item) {
      var _item$storeInfo;

      return ((_item$storeInfo = item.storeInfo) === null || _item$storeInfo === void 0 ? void 0 : _item$storeInfo.customerCode) === newDeliver.storeInfo.customerCode ? newDeliver : item;
    })
  });

  return routeList.map(function (item) {
    return item.driverInfo.vehicleCode === newShift.driverInfo.vehicleCode ? newShift : item;
  });
};

var getNewEncodedPath = function getNewEncodedPath(_ref10) {
  var encodedPath = _ref10.encodedPath,
      deliverDetail = _ref10.deliverDetail,
      stops = _ref10.stops,
      position = _ref10.position;
  var xxx = encodedPath;

  if (stops.length == 1) {
    return getEncodedPathAfterDnDMissingOrder(deliverDetail, position + 1, encodedPath);
  }

  if (stops.length == 2) {
    xxx = getEncodedPathAfterDnDMissingOrder(deliverDetail, position + 1, encodedPath);

    var currPosition = _.get(_.last(stops), 'storeInfo.coordinate');

    var nextPosition = _.get(deliverDetail[position + 1 + 2], 'storeInfo.coordinate');

    var path = [[currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude], [nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.latitude, nextPosition === null || nextPosition === void 0 ? void 0 : nextPosition.longitude]];
    var encodedPathAdded = encode(path, 5);
    xxx = xxx.map(function (item, index) {
      return index === position + 1 ? encodedPathAdded : item;
    });

    var nextPositionMiddle = _.get(_.last(stops), 'storeInfo.coordinate');

    var currPositionMiddle = _.get(_.head(stops), 'storeInfo.coordinate');

    var pathMiddle = [[currPositionMiddle === null || currPositionMiddle === void 0 ? void 0 : currPositionMiddle.latitude, currPositionMiddle === null || currPositionMiddle === void 0 ? void 0 : currPositionMiddle.longitude], [nextPositionMiddle === null || nextPositionMiddle === void 0 ? void 0 : nextPositionMiddle.latitude, nextPositionMiddle === null || nextPositionMiddle === void 0 ? void 0 : nextPositionMiddle.longitude]];
    xxx.splice(position + 1, 0, encode(pathMiddle, 5));
    return xxx;
  }

  _.forEach(stops, function (stop, index) {
    var currPosition = _.get(stop, 'storeInfo.coordinate');

    if (index === 0) {
      xxx = getEncodedPathAfterDnDMissingOrder(deliverDetail, position + 1, encodedPath);
    } else if (index === stops.length - 1) {
      var _nextPosition = _.get(deliverDetail[position + index + 2], 'storeInfo.coordinate');

      var _path = [[currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude], [_nextPosition === null || _nextPosition === void 0 ? void 0 : _nextPosition.latitude, _nextPosition === null || _nextPosition === void 0 ? void 0 : _nextPosition.longitude]];

      var _encodedPathAdded = encode(_path, 5);

      xxx = xxx.map(function (item, index) {
        return index === position + 1 ? _encodedPathAdded : item;
      });
    } else {
      var _nextPosition2 = _.get(stops[index + 1], 'storeInfo.coordinate');

      var _path2 = [[currPosition === null || currPosition === void 0 ? void 0 : currPosition.latitude, currPosition === null || currPosition === void 0 ? void 0 : currPosition.longitude], [_nextPosition2 === null || _nextPosition2 === void 0 ? void 0 : _nextPosition2.latitude, _nextPosition2 === null || _nextPosition2 === void 0 ? void 0 : _nextPosition2.longitude]];
      xxx.splice(position + 1, 0, encode(_path2, 5));
    }
  });

  return xxx;
};

export var moveMissingOrdersToVehicle = function moveMissingOrdersToVehicle(_ref11) {
  var missingOrders = _ref11.missingOrders,
      vehicle = _ref11.vehicle,
      routeList = _ref11.routeList,
      depots = _ref11.depots,
      createShiftCodeAndTripCode = _ref11.createShiftCodeAndTripCode,
      dataOrg = _ref11.dataOrg,
      shiftStartTime = _ref11.shiftStartTime,
      isPNS = _ref11.isPNS,
      date = _ref11.date;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  var shiftCode = createShiftCodeAndTripCode.shiftCode,
      tripCode = createShiftCodeAndTripCode.tripCode;

  var missingStops = _.groupBy(missingOrders, 'customerInfo.customerCode');

  var depotCode = _.get(_.head(missingOrders), 'organizationCode') || _.get(_.head(missingOrders), 'organizationId');

  var depotInfo = _.find(depots, function (depot) {
    return depot.depotInfo.organizationCode === depotCode;
  }) || _.find(depots, function (depot) {
    return depot.depotInfo._id === depotCode;
  });

  var stops = Object.keys(missingStops).map(function (customerCode, index) {
    var customerOrders = _.get(missingStops, customerCode);

    var CustomerOrderWeightVolume = customerOrders.reduce(function (acc, item) {
      return {
        totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + (item === null || item === void 0 ? void 0 : item.totalVolume),
        totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + (item === null || item === void 0 ? void 0 : item.totalWeight)
      };
    });

    var customerInfo = _.get(_.head(customerOrders), 'customerInfo');

    var deliveryAndDoneTime = convertToNextStop(depotInfo, index + 1);
    return _objectSpread({
      depotInfo: null,
      fillRateVolume: 0,
      fillRateWeight: 0,
      totalPrice: 0,
      totalVolume: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalVolume,
      totalWeight: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalWeight,
      orderInfo: customerOrders,
      storeInfo: _objectSpread({}, customerInfo)
    }, deliveryAndDoneTime);
  });
  var encodedPath = getEncodedPathFromDeliverDetail([depotInfo].concat(_toConsumableArray(stops)));
  var newRoute = {
    routeIndex: 1,
    shiftStartTime: shiftStartTime,
    shiftCode: shiftCode,
    planningStatus: 'PLANNED',
    organizationId: {
      organizationName: _.get(dataOrg, 'organizationName'),
      _id: dataOrg === null || dataOrg === void 0 ? void 0 : dataOrg._id
    },
    lastUpdatedAt: date,
    lockedRoute: false,
    optimizedAt: date,
    deliveryDate: date,
    deliveryDateEnd: date,
    deliveryDateStart: date,
    distance: 0,
    createdAt: date,
    driverInfo: _objectSpread({}, vehicle),
    deliverDetail: isOpenRoute ? [_objectSpread(_objectSpread({}, depotInfo), {}, {
      tripCode: tripCode
    })].concat(_toConsumableArray(stops)) : [_objectSpread(_objectSpread({}, depotInfo), {}, {
      tripCode: tripCode
    })].concat(_toConsumableArray(stops), [_objectSpread(_objectSpread({}, depotInfo), {}, {
      fillRateVolume: 0,
      fillRateWeight: 0
    })]),
    deliverTotalInfo: {
      totalCost: 4130.61,
      totalDistance: 412.061,
      totalFamiliarStores: 0,
      totalFamiliarity: 0,
      totalOrder: 3,
      totalProfit: -4130.61,
      totalRevenue: 0,
      totalStores: 4,
      totalVolume: 5.407551,
      totalWeight: 504.712,
      unqualifiedMOQ: false,
      unqualifiedMOQValue: false
    },
    encodedPath: encodedPath
  };
  var newRouteShiftAfterUpdateFillRate = getFillRate(newRoute);
  var newDropShiftAfterUpdateLoadingTime = getLoadingTime(newRouteShiftAfterUpdateFillRate, shiftStartTime, isPNS, dataOrg);
  return [newDropShiftAfterUpdateLoadingTime].concat(_toConsumableArray(routeList));
};
export var moveMissingOrdersToVehicleOnRoute = function moveMissingOrdersToVehicleOnRoute(_ref12) {
  var missingOrders = _ref12.missingOrders,
      position = _ref12.position,
      routeList = _ref12.routeList,
      toShift = _ref12.toShift,
      algoConfig = _ref12.algoConfig,
      createShiftCodeAndTripCode = _ref12.createShiftCodeAndTripCode,
      depots = _ref12.depots,
      isPNS = _ref12.isPNS,
      dataOrg = _ref12.dataOrg;

  var isOpenRoute = _.get(algoConfig, 'openRoute');

  var newRoutes = routeList;

  var deliverDetail = _.get(toShift, 'deliverDetail', []);

  var isLastPos = position === deliverDetail.length - 1;
  var isToShiftLocked = _.get(toShift, 'planningStatus') === 'LOCKED';
  var isCreateNewShift = isToShiftLocked && !isOpenRoute;

  if (isCreateNewShift) {
    return moveMissingOrdersToVehicle({
      missingOrders: missingOrders,
      vehicle: _.get(toShift, 'driverInfo'),
      routeList: routeList,
      depots: depots,
      createShiftCodeAndTripCode: createShiftCodeAndTripCode,
      dataOrg: algoConfig
    });
  }

  if (!isOpenRoute) {
    var routeAfterUpdate = newRoutes.find(function (i) {
      return i.shiftCode === toShift.shiftCode;
    });

    var deliverDetailShift = _.get(routeAfterUpdate, 'deliverDetail');

    var depot = _.head(deliverDetailShift);

    var dataStopByTrip = getStopByTrip(deliverDetailShift);
    var firstTripCode = getFirstTripCode(deliverDetailShift);

    var dataMissingOrderGroupByCustomer = _.groupBy(missingOrders, 'customerInfo.customerCode');

    var customerCodeByMissingOrder = Object.keys(dataMissingOrderGroupByCustomer);
    var dataStopByTripOfToShiftAfter = dataStopByTrip === null || dataStopByTrip === void 0 ? void 0 : dataStopByTrip.map(function (stopByTrip) {
      var isTrip = position >= _.get(_.head(stopByTrip), 'indexStop') && position <= _.get(_.last(stopByTrip), 'indexStop') + 1;

      if (isTrip) {
        var newStopByTrip = stopByTrip;
        customerCodeByMissingOrder.forEach(function (customer) {
          var duplicate = newStopByTrip.find(function (stop) {
            return stop.storeInfo.customerCode === customer;
          });
          var dataMissingOrderByCustomer = dataMissingOrderGroupByCustomer[customer];
          var CustomerOrderWeightVolume = dataMissingOrderByCustomer.reduce(function (acc, order) {
            return {
              totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + (order === null || order === void 0 ? void 0 : order.totalVolume),
              totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + (order === null || order === void 0 ? void 0 : order.totalWeight)
            };
          });

          if (duplicate) {
            newStopByTrip = newStopByTrip.map(function (stop) {
              return stop.storeInfo.customerCode === customer ? _objectSpread(_objectSpread({}, stop), {}, {
                orderInfo: [].concat(_toConsumableArray(stop.orderInfo), _toConsumableArray(dataMissingOrderByCustomer)),
                totalVolume: (stop === null || stop === void 0 ? void 0 : stop.totalVolume) + (CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalVolume),
                totalWeight: (stop === null || stop === void 0 ? void 0 : stop.totalWeight) + (CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalWeight)
              }) : stop;
            });
          } else {
            var _dataMissingOrderByCu;

            var newStop = {
              depotInfo: null,
              fillRateVolume: 0,
              fillRateWeight: 0,
              totalPrice: 0,
              totalVolume: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalVolume,
              totalWeight: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalWeight,
              orderInfo: dataMissingOrderByCustomer,
              storeInfo: _objectSpread({}, (_dataMissingOrderByCu = dataMissingOrderByCustomer[0]) === null || _dataMissingOrderByCu === void 0 ? void 0 : _dataMissingOrderByCu.customerInfo)
            };
            var isPositionByTrip = position === _.get(_.last(newStopByTrip), 'indexStop') + 1 ? _.get(_.last(newStopByTrip), 'indexStop') + 1 : _.findIndex(newStopByTrip, function (i) {
              return i.indexStop === position;
            });

            if (isPositionByTrip >= 0) {
              newStopByTrip.splice(isPositionByTrip, 0, newStop);
            }
          }
        });
        return newStopByTrip;
      }

      return stopByTrip;
    });
    customerCodeByMissingOrder.forEach(function (customer) {
      var _dataMissingOrderByCu2;

      var dataMissingOrderByCustomer = dataMissingOrderGroupByCustomer[customer];
      var CustomerOrderWeightVolume = dataMissingOrderByCustomer.reduce(function (acc, order) {
        return {
          totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + (order === null || order === void 0 ? void 0 : order.totalVolume),
          totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + (order === null || order === void 0 ? void 0 : order.totalWeight)
        };
      });
      var newStop = {
        depotInfo: null,
        fillRateVolume: 0,
        fillRateWeight: 0,
        totalPrice: 0,
        totalVolume: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalVolume,
        totalWeight: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalWeight,
        orderInfo: dataMissingOrderByCustomer,
        storeInfo: _objectSpread({}, (_dataMissingOrderByCu2 = dataMissingOrderByCustomer[0]) === null || _dataMissingOrderByCu2 === void 0 ? void 0 : _dataMissingOrderByCu2.customerInfo)
      };

      if (position === 0) {
        dataStopByTripOfToShiftAfter = [[newStop]].concat(_toConsumableArray(dataStopByTripOfToShiftAfter));
      }

      if (position >= deliverDetailShift.length) {
        dataStopByTripOfToShiftAfter = [].concat(_toConsumableArray(dataStopByTripOfToShiftAfter), [[newStop]]);
      }
    });
    var dataDeliverDetailToShiftFinal = [depot];
    var numberTripToShift = 2;
    dataStopByTripOfToShiftAfter.forEach(function (stopByTrip, indexTrip) {
      if (stopByTrip.length > 0) {
        dataDeliverDetailToShiftFinal = [].concat(_toConsumableArray(dataDeliverDetailToShiftFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, depot), {}, {
          tripCode: indexTrip == dataStopByTripOfToShiftAfter.length - 1 ? null : "".concat(firstTripCode, "#").concat(numberTripToShift)
        })]);
        numberTripToShift++;
      }
    });

    var _newToShift = _objectSpread(_objectSpread({}, toShift), {}, {
      deliverDetail: dataDeliverDetailToShiftFinal
    });

    var toShiftAfterUpdateFillRate = getFillRate(_newToShift);
    var toShiftAfterUpdateLoadingTime = getLoadingTime(toShiftAfterUpdateFillRate, false, isPNS, dataOrg);
    return newRoutes.map(function (item) {
      return item.shiftCode === toShift.shiftCode ? toShiftAfterUpdateLoadingTime : item;
    });
  }

  var missingOrderOnRoutes = missingOrders.filter(function (missingOrder) {
    var missingCustomerCode = _.get(missingOrder, 'customerInfo.customerCode');

    return _.find(deliverDetail, function (deliver) {
      var _deliver$storeInfo2;

      return ((_deliver$storeInfo2 = deliver.storeInfo) === null || _deliver$storeInfo2 === void 0 ? void 0 : _deliver$storeInfo2.customerCode) === missingCustomerCode;
    });
  });
  var missingOrderNotOnRoutes = missingOrders.filter(function (missingOrder) {
    var missingCustomerCode = _.get(missingOrder, 'customerInfo.customerCode');

    return !_.find(deliverDetail, function (deliver) {
      var _deliver$storeInfo3;

      return ((_deliver$storeInfo3 = deliver.storeInfo) === null || _deliver$storeInfo3 === void 0 ? void 0 : _deliver$storeInfo3.customerCode) === missingCustomerCode;
    });
  });

  if (missingOrderOnRoutes.length > 0) {
    _.forEach(missingOrderOnRoutes, function (mOrder) {
      var newShift = _.find(newRoutes, function (r) {
        return r.shiftCode === toShift.shiftCode;
      });

      newRoutes = handleAddExistMissingOrderToShift({
        shift: newShift,
        missingOrder: mOrder,
        routeList: newRoutes
      });
    });
  }

  var newShift = _.find(newRoutes, function (r) {
    return r.shiftCode === toShift.shiftCode;
  });

  var newDDetail = _.get(newShift, 'deliverDetail');

  var currentDeliver = _.get(newDDetail, position);

  var missingStopsNotOnRoutes = _.groupBy(missingOrderNotOnRoutes, 'customerInfo.customerCode');

  var stops = Object.keys(missingStopsNotOnRoutes).map(function (customerCode, index) {
    var customerOrders = _.get(missingStopsNotOnRoutes, customerCode);

    var CustomerOrderWeightVolume = customerOrders.reduce(function (acc, item) {
      return {
        totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + (item === null || item === void 0 ? void 0 : item.totalVolume),
        totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + (item === null || item === void 0 ? void 0 : item.totalWeight)
      };
    });

    var customerInfo = _.get(_.head(customerOrders), 'customerInfo');

    var deliveryAndDoneTime = convertToNextStop(currentDeliver, index + 1);
    return _objectSpread({
      depotInfo: null,
      fillRateVolume: 0,
      fillRateWeight: 0,
      totalPrice: 0,
      totalVolume: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalVolume,
      totalWeight: CustomerOrderWeightVolume === null || CustomerOrderWeightVolume === void 0 ? void 0 : CustomerOrderWeightVolume.totalWeight,
      orderInfo: customerOrders,
      storeInfo: _objectSpread({}, customerInfo)
    }, deliveryAndDoneTime);
  });
  var insertedDeliverDetail = insert.apply(void 0, [newDDetail, position + 1].concat(_toConsumableArray(stops)));
  var isCreateNewTrip = !isOpenRoute && missingOrderNotOnRoutes.length > 0 && isLastPos;

  if (isCreateNewTrip) {
    var lastDeliver = _objectSpread(_objectSpread({}, _.head(insertedDeliverDetail)), {}, {
      fillRateVolume: 0,
      fillRateWeight: 0
    });

    insertedDeliverDetail = [].concat(_toConsumableArray(insertedDeliverDetail), [lastDeliver]);
  }

  var newDeliverDetail = updateTimeFromPosition({
    deliverDetail: insertedDeliverDetail,
    position: position + stops.length
  });

  var encodedPath = _.get(newShift, 'encodedPath');

  var newEncodedPath = getNewEncodedPath({
    encodedPath: encodedPath,
    deliverDetail: newDeliverDetail,
    stops: stops,
    position: position
  });

  var newToShift = _objectSpread(_objectSpread({}, newShift), {}, {
    deliverDetail: newDeliverDetail,
    encodedPath: newEncodedPath
  });

  var newRouteShiftAfterUpdateFillRate = getFillRate(newToShift);
  var newDropShiftAfterUpdateLoadingTime = getLoadingTime(newRouteShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  return newRoutes.map(function (item) {
    return item.shiftCode === newToShift.shiftCode ? newDropShiftAfterUpdateLoadingTime : item;
  });
};
export var dndMissingOrderToVehicleOnRoute = function dndMissingOrderToVehicleOnRoute(_ref13) {
  var missingOrder = _ref13.missingOrder,
      dropShift = _ref13.dropShift,
      position = _ref13.position,
      routeList = _ref13.routeList,
      algoConfig = _ref13.algoConfig,
      isPNS = _ref13.isPNS,
      dataOrg = _ref13.dataOrg;

  var isOpenRoute = _.get(algoConfig, 'openRoute');

  var deliverDetail = _.get(dropShift, 'deliverDetail', []);

  if (!isOpenRoute) {
    var routeAfterUpdate = routeList.find(function (i) {
      return i.shiftCode === dropShift.shiftCode;
    });

    var deliverDetailShift = _.get(routeAfterUpdate, 'deliverDetail');

    var depot = _.head(deliverDetailShift);

    var dataStopByTrip = getStopByTrip(deliverDetailShift);
    var firstTripCode = getFirstTripCode(deliverDetailShift);
    var _newStop = {
      depotInfo: null,
      fillRateVolume: 0,
      fillRateWeight: 0,
      totalPrice: 0,
      totalVolume: missingOrder === null || missingOrder === void 0 ? void 0 : missingOrder.totalVolume,
      totalWeight: missingOrder === null || missingOrder === void 0 ? void 0 : missingOrder.totalWeight,
      orderInfo: [missingOrder],
      storeInfo: _objectSpread({}, missingOrder === null || missingOrder === void 0 ? void 0 : missingOrder.customerInfo)
    };
    var dataStopByTripOfToShiftAfter = dataStopByTrip === null || dataStopByTrip === void 0 ? void 0 : dataStopByTrip.map(function (stopByTrip) {
      var isTrip = position >= _.get(_.head(stopByTrip), 'indexStop') && position <= _.get(_.last(stopByTrip), 'indexStop') + 1;
      var duplicate = stopByTrip.find(function (stop) {
        return stop.storeInfo.customerCode === _.get(missingOrder, 'customerInfo.customerCode');
      });

      if (isTrip) {
        if (duplicate) {
          return stopByTrip.map(function (stop) {
            return stop.storeInfo.customerCode === _.get(missingOrder, 'customerInfo.customerCode') ? _objectSpread(_objectSpread({}, stop), {}, {
              orderInfo: [].concat(_toConsumableArray(stop === null || stop === void 0 ? void 0 : stop.orderInfo), [missingOrder]),
              totalVolume: (stop === null || stop === void 0 ? void 0 : stop.totalVolume) + (missingOrder === null || missingOrder === void 0 ? void 0 : missingOrder.totalVolume),
              totalWeight: (stop === null || stop === void 0 ? void 0 : stop.totalWeight) + (missingOrder === null || missingOrder === void 0 ? void 0 : missingOrder.totalWeight)
            }) : stop;
          });
        } else {
          var isPositionByTrip = position === _.get(_.last(stopByTrip), 'indexStop') + 1 ? _.get(_.last(stopByTrip), 'indexStop') + 1 : _.findIndex(stopByTrip, function (i) {
            return i.indexStop === position;
          });

          if (isPositionByTrip >= 0) {
            stopByTrip.splice(isPositionByTrip, 0, _newStop);
          }

          return stopByTrip;
        }
      } else {
        return stopByTrip;
      }
    });

    if (position === 0) {
      dataStopByTripOfToShiftAfter = [[_newStop]].concat(_toConsumableArray(dataStopByTripOfToShiftAfter));
    }

    if (position >= deliverDetailShift.length) {
      dataStopByTripOfToShiftAfter = [].concat(_toConsumableArray(dataStopByTripOfToShiftAfter), [[_newStop]]);
    }

    var dataDeliverDetailToShiftFinal = [depot];
    var numberTripToShift = 2;
    dataStopByTripOfToShiftAfter.forEach(function (stopByTrip, indexTrip) {
      if (stopByTrip.length > 0) {
        dataDeliverDetailToShiftFinal = [].concat(_toConsumableArray(dataDeliverDetailToShiftFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, depot), {}, {
          tripCode: indexTrip == dataStopByTripOfToShiftAfter.length - 1 ? null : "".concat(firstTripCode, "#").concat(numberTripToShift)
        })]);
        numberTripToShift++;
      }
    });

    var newToShift = _objectSpread(_objectSpread({}, dropShift), {}, {
      deliverDetail: dataDeliverDetailToShiftFinal
    });

    var toShiftAfterUpdateFillRate = getFillRate(newToShift);
    var toShiftAfterUpdateLoadingTime = getLoadingTime(toShiftAfterUpdateFillRate, false, isPNS, dataOrg);
    return routeList.map(function (item) {
      return item.shiftCode === dropShift.shiftCode ? toShiftAfterUpdateLoadingTime : item;
    });
  }

  var isLastPos = position === deliverDetail.length;
  var isCustomerOfOrderOnRoute = !!_.find(deliverDetail, function (deliver) {
    var _deliver$storeInfo4;

    return ((_deliver$storeInfo4 = deliver.storeInfo) === null || _deliver$storeInfo4 === void 0 ? void 0 : _deliver$storeInfo4.customerCode) === _.get(missingOrder, 'customerInfo.customerCode');
  });
  var newStop = convertOrderToStop({
    order: missingOrder,
    shift: dropShift
  });

  if (isCustomerOfOrderOnRoute) {
    var newShift = _objectSpread(_objectSpread({}, dropShift), {}, {
      deliverDetail: deliverDetail.map(function (item) {
        var _item$storeInfo2;

        return ((_item$storeInfo2 = item.storeInfo) === null || _item$storeInfo2 === void 0 ? void 0 : _item$storeInfo2.customerCode) === newStop.storeInfo.customerCode ? newStop : item;
      })
    });

    var _dropShiftAfterUpdateFillRate = getFillRate(newShift);

    var _dropShiftAfterUpdateLoadingTime = getLoadingTime(_dropShiftAfterUpdateFillRate, false, isPNS, dataOrg);

    return routeList.map(function (item) {
      return item.driverInfo.vehicleCode === newShift.driverInfo.vehicleCode ? _dropShiftAfterUpdateLoadingTime : item;
    });
  }

  deliverDetail.splice(position, 0, newStop);
  var newDeliverDetail = updateTimeFromPosition({
    deliverDetail: deliverDetail,
    position: position
  });
  var isCreateNewTrip = !isOpenRoute && isLastPos && !isCustomerOfOrderOnRoute;

  if (isCreateNewTrip) {
    var lastDeliver = _objectSpread(_objectSpread({}, _.head(newDeliverDetail)), {}, {
      fillRateVolume: 0,
      fillRateWeight: 0
    });

    newDeliverDetail = [].concat(_toConsumableArray(newDeliverDetail), [lastDeliver]);
  }

  var encodedPath = getEncodedPathAfterDnDMissingOrder(newDeliverDetail, position, _.get(dropShift, 'encodedPath'));

  var newDropShift = _objectSpread(_objectSpread({}, dropShift), {}, {
    deliverDetail: newDeliverDetail,
    encodedPath: encodedPath
  });

  var dropShiftAfterUpdateFillRate = getFillRate(newDropShift);
  var dropShiftAfterUpdateLoadingTime = getLoadingTime(dropShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  return routeList.map(function (item) {
    return item.driverInfo.vehicleCode === newDropShift.driverInfo.vehicleCode ? dropShiftAfterUpdateLoadingTime : item;
  });
};
export var dndStopToShift = function dndStopToShift(_ref14) {
  var dataOrg = _ref14.dataOrg,
      stop = _ref14.stop,
      position = _ref14.position,
      routeList = _ref14.routeList,
      dragShift = _ref14.dragShift,
      dropShift = _ref14.dropShift,
      indexStoreMove = _ref14.indexStoreMove,
      isPNS = _ref14.isPNS;
  var positionStopFinal = position;
  var newRouteList = routeList;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  var deliverDetailDragShift = _.get(dragShift, 'deliverDetail');

  var deliverDetailDropShift = _.get(dropShift, 'deliverDetail');

  if (!isOpenRoute) {
    var firstTripCodeDragShift = getFirstTripCode(deliverDetailDragShift);
    var firstTripCodeDropShift = getFirstTripCode(deliverDetailDropShift);
    var dataStopByTripDragShift = getStopByTrip(deliverDetailDragShift);
    var dataStopByTripDropShift = getStopByTrip(deliverDetailDropShift);
    var dataStopByTripDropAfterdnd = dataStopByTripDropShift.map(function (stopByTrip) {
      var duplicateStoreByTrip = _.find(stopByTrip, function (i) {
        var _i$storeInfo2, _stop$storeInfo3;

        return ((_i$storeInfo2 = i.storeInfo) === null || _i$storeInfo2 === void 0 ? void 0 : _i$storeInfo2.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo3 = stop.storeInfo) === null || _stop$storeInfo3 === void 0 ? void 0 : _stop$storeInfo3.customerCode);
      });

      var isTrip = position >= _.get(_.head(stopByTrip), 'indexStop') && position <= _.get(_.last(stopByTrip), 'indexStop') + 1;

      if (isTrip) {
        if (duplicateStoreByTrip) {
          var dataFinalDuplicateStop = _objectSpread(_objectSpread({}, duplicateStoreByTrip), {}, {
            totalVolume: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalVolume) + (stop === null || stop === void 0 ? void 0 : stop.totalVolume),
            totalWeight: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalWeight) + (stop === null || stop === void 0 ? void 0 : stop.totalWeight)
          });

          return stopByTrip.map(function (stops) {
            var _stops$storeInfo2, _stop$storeInfo4;

            return (stops === null || stops === void 0 ? void 0 : (_stops$storeInfo2 = stops.storeInfo) === null || _stops$storeInfo2 === void 0 ? void 0 : _stops$storeInfo2.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo4 = stop.storeInfo) === null || _stop$storeInfo4 === void 0 ? void 0 : _stop$storeInfo4.customerCode) ? dataFinalDuplicateStop : stops;
          });
        } else {
          var isPositionByTrip = position === _.get(_.last(stopByTrip), 'indexStop') + 1 ? _.get(_.last(stopByTrip), 'indexStop') + 1 : _.findIndex(stopByTrip, function (i) {
            return i.indexStop === position;
          });

          if (isPositionByTrip >= 0) {
            stopByTrip.splice(isPositionByTrip, 0, stop);
            return stopByTrip;
          }
        }
      }

      return stopByTrip;
    });
    var dataStopByTripDragAfter = dataStopByTripDragShift.map(function (stopByTrip) {
      return stopByTrip.filter(function (stops) {
        return indexStoreMove !== (stops === null || stops === void 0 ? void 0 : stops.indexStop);
      });
    });

    if (position === 0) {
      dataStopByTripDropAfterdnd = [[stop]].concat(_toConsumableArray(dataStopByTripDropAfterdnd));
    }

    if (position >= deliverDetailDropShift.length) {
      dataStopByTripDropAfterdnd = [].concat(_toConsumableArray(dataStopByTripDropAfterdnd), [[stop]]);
    }

    var dataDeliverDetailDropFinal = [_.head(deliverDetailDropShift)];
    var numberDropTrip = 2;
    var dataDeliverDetailDragFinal = [_.head(deliverDetailDragShift)];
    var numberDragTrip = 2;
    dataStopByTripDropAfterdnd.forEach(function (stopByTrip, indexTrip) {
      if (stopByTrip.length > 0) {
        dataDeliverDetailDropFinal = [].concat(_toConsumableArray(dataDeliverDetailDropFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, _.head(deliverDetailDropShift)), {}, {
          tripCode: indexTrip == dataStopByTripDropAfterdnd.length - 1 ? null : "".concat(firstTripCodeDropShift, "#").concat(numberDropTrip)
        })]);
        numberDropTrip++;
      }
    });
    dataStopByTripDragAfter.forEach(function (stopByTrip, indexTrip) {
      if (stopByTrip.length > 0) {
        dataDeliverDetailDragFinal = [].concat(_toConsumableArray(dataDeliverDetailDragFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, _.head(deliverDetailDragShift)), {}, {
          tripCode: indexTrip == dataStopByTripDragAfter.length - 1 ? null : "".concat(firstTripCodeDragShift, "#").concat(numberDragTrip)
        })]);
        numberDragTrip++;
      }
    });

    var newDropShift = _objectSpread(_objectSpread({}, dropShift), {}, {
      deliverDetail: dataDeliverDetailDropFinal
    });

    var newDragShift = _objectSpread(_objectSpread({}, dragShift), {}, {
      deliverDetail: dataDeliverDetailDragFinal
    });

    var dropShiftAfterUpdateFillRate = getFillRate(newDropShift);
    var dropShiftAfterUpdateLoadingTime = getLoadingTime(dropShiftAfterUpdateFillRate, false, isPNS, dataOrg);
    var dragShiftAfterUpdateFillRate = getFillRate(newDragShift);
    var dragShiftAfterUpdateLoadingTime = getLoadingTime(dragShiftAfterUpdateFillRate, false, isPNS, dataOrg);
    var isRemoveDragShift = dataDeliverDetailDragFinal.filter(function (i) {
      return i.storeInfo;
    });

    if (isRemoveDragShift.length < 1) {
      newRouteList = routeList.filter(function (item) {
        return (item === null || item === void 0 ? void 0 : item.shiftCode) !== (dragShift === null || dragShift === void 0 ? void 0 : dragShift.shiftCode);
      });
    }

    return newRouteList.map(function (item) {
      return (item === null || item === void 0 ? void 0 : item.shiftCode) === (dropShift === null || dropShift === void 0 ? void 0 : dropShift.shiftCode) ? dropShiftAfterUpdateLoadingTime : (item === null || item === void 0 ? void 0 : item.shiftCode) === (dragShift === null || dragShift === void 0 ? void 0 : dragShift.shiftCode) ? dragShiftAfterUpdateLoadingTime : item;
    });
  }

  var deliverDragShiftAfter = deliverDetailDragShift.filter(function (item, index) {
    return index !== indexStoreMove;
  });
  var isRemoveRoute = deliverDragShiftAfter.filter(function (item) {
    return item.storeInfo;
  });

  var isStopCodeOnDropShift = _.find(deliverDetailDropShift, function (item) {
    return _.get(item, 'storeInfo.customerCode') === _.get(stop, 'storeInfo.customerCode');
  });

  var xxx = getEncodedPathDragShift({
    dragShift: dragShift,
    stop: stop
  });

  if (isStopCodeOnDropShift) {
    var mergeStop = _objectSpread(_objectSpread({}, isStopCodeOnDropShift), {}, {
      orderInfo: [].concat(_toConsumableArray(isStopCodeOnDropShift.orderInfo), _toConsumableArray(stop.orderInfo))
    });

    deliverDetailDropShift = deliverDetailDropShift.map(function (item) {
      return _.get(item, 'storeInfo.customerCode') === _.get(stop, 'storeInfo.customerCode') ? mergeStop : item;
    });
  } else {
    deliverDetailDropShift.splice(positionStopFinal, 0, stop);
  }

  var newRouteDrop = _objectSpread(_objectSpread({}, dropShift), {}, {
    deliverDetail: deliverDetailDropShift
  });

  var newDropShiftAfterUpdateFillRate = getFillRate(newRouteDrop);
  var newDropShiftAfterUpdateLoadingTime = getLoadingTime(newDropShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  var yyy = getEncodedPathDropShift({
    dropShift: _objectSpread(_objectSpread({}, dropShift), {}, {
      deliverDetail: _.get(newDropShiftAfterUpdateLoadingTime, 'deliverDetail')
    }),
    stop: stop,
    position: positionStopFinal,
    isStopCodeOnDropShift: isStopCodeOnDropShift
  });

  if (isRemoveRoute.length < 1) {
    newRouteList = routeList.filter(function (item) {
      return item.shiftCode !== dragShift.shiftCode;
    });
  } else {
    newRouteList = routeList.map(function (item) {
      if (item.shiftCode === dragShift.shiftCode) {
        var _newDragShift = _objectSpread(_objectSpread({}, item), {}, {
          deliverDetail: deliverDragShiftAfter,
          encodedPath: xxx
        });

        var newDragShiftAfterUpdateFillRate = getFillRate(_newDragShift);
        var newDragShiftAfterUpdateLoadingTime = getLoadingTime(newDragShiftAfterUpdateFillRate, false, isPNS, dataOrg);
        return newDragShiftAfterUpdateLoadingTime;
      }

      return item;
    });
  }

  return newRouteList.map(function (route) {
    return route.shiftCode === dropShift.shiftCode ? _objectSpread(_objectSpread({}, newDropShiftAfterUpdateLoadingTime), {}, {
      encodedPath: yyy
    }) : route;
  });
};
export var dndStopsOnshift = function dndStopsOnshift(_ref15) {
  var dataOrg = _ref15.dataOrg,
      stop = _ref15.stop,
      position = _ref15.position,
      routeList = _ref15.routeList,
      shift = _ref15.shift,
      indexStoreBeforeMove = _ref15.indexStoreBeforeMove,
      isPNS = _ref15.isPNS;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  var positionStopFinal = position;

  var deliverDetailDragShift = _.get(shift, 'deliverDetail');

  var newRouteList = routeList;

  if (!isOpenRoute) {
    var firstTripCode = getFirstTripCode(deliverDetailDragShift);

    var dataDepotStart = _.head(deliverDetailDragShift);

    var dataDeliverDetailFinal = [dataDepotStart];
    var dataStopByTrip = getStopByTrip(deliverDetailDragShift);
    var dataStopByTripAfterdnd = dataStopByTrip.map(function (stopByTrip) {
      var stopByTripAffterRemoveStop = stopByTrip.filter(function (stops) {
        return indexStoreBeforeMove !== (stops === null || stops === void 0 ? void 0 : stops.indexStop);
      });

      var duplicateStoreByTrip = _.find(stopByTripAffterRemoveStop, function (i) {
        var _i$storeInfo3, _stop$storeInfo5;

        return ((_i$storeInfo3 = i.storeInfo) === null || _i$storeInfo3 === void 0 ? void 0 : _i$storeInfo3.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo5 = stop.storeInfo) === null || _stop$storeInfo5 === void 0 ? void 0 : _stop$storeInfo5.customerCode);
      });

      var isTrip = position >= _.get(_.head(stopByTripAffterRemoveStop), 'indexStop') && position <= _.get(_.last(stopByTripAffterRemoveStop), 'indexStop') + 1;

      if (isTrip) {
        if (duplicateStoreByTrip) {
          var dataFinalDuplicateStop = _objectSpread(_objectSpread({}, duplicateStoreByTrip), {}, {
            totalVolume: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalVolume) + (stop === null || stop === void 0 ? void 0 : stop.totalVolume),
            totalWeight: (duplicateStoreByTrip === null || duplicateStoreByTrip === void 0 ? void 0 : duplicateStoreByTrip.totalWeight) + (stop === null || stop === void 0 ? void 0 : stop.totalWeight)
          });

          return stopByTripAffterRemoveStop.map(function (stops) {
            var _stops$storeInfo3, _stop$storeInfo6;

            return (stops === null || stops === void 0 ? void 0 : (_stops$storeInfo3 = stops.storeInfo) === null || _stops$storeInfo3 === void 0 ? void 0 : _stops$storeInfo3.customerCode) === (stop === null || stop === void 0 ? void 0 : (_stop$storeInfo6 = stop.storeInfo) === null || _stop$storeInfo6 === void 0 ? void 0 : _stop$storeInfo6.customerCode) ? dataFinalDuplicateStop : stops;
          });
        } else {
          var isPositionByTrip = position === _.get(_.last(stopByTripAffterRemoveStop), 'indexStop') + 1 ? _.get(_.last(stopByTripAffterRemoveStop), 'indexStop') + 1 : _.findIndex(stopByTripAffterRemoveStop, function (i) {
            return i.indexStop === position;
          });

          if (isPositionByTrip >= 0) {
            stopByTripAffterRemoveStop.splice(isPositionByTrip, 0, stop);
            return stopByTripAffterRemoveStop;
          }
        }
      }

      return stopByTripAffterRemoveStop;
    });

    if (position === 0) {
      dataStopByTripAfterdnd = [[stop]].concat(_toConsumableArray(dataStopByTripAfterdnd));
    }

    if (position >= deliverDetailDragShift.length) {
      dataStopByTripAfterdnd = [].concat(_toConsumableArray(dataStopByTripAfterdnd), [[stop]]);
    }

    var numberTrip = 2;
    dataStopByTripAfterdnd.forEach(function (stopByTrip, indexTrip) {
      if (stopByTrip.length > 0) {
        dataDeliverDetailFinal = [].concat(_toConsumableArray(dataDeliverDetailFinal), _toConsumableArray(stopByTrip), [_objectSpread(_objectSpread({}, dataDepotStart), {}, {
          tripCode: indexTrip == dataStopByTripAfterdnd.length - 1 ? null : "".concat(firstTripCode, "#").concat(numberTrip)
        })]);
        numberTrip++;
      }
    });

    var _newShift = _objectSpread(_objectSpread({}, shift), {}, {
      deliverDetail: dataDeliverDetailFinal
    });

    var _shiftAfterUpdateFillRate = getFillRate(_newShift);

    var _shiftAfterUpdateLoadingTime = getLoadingTime(_shiftAfterUpdateFillRate, false, isPNS, dataOrg);

    return routeList.map(function (item) {
      return (item === null || item === void 0 ? void 0 : item.shiftCode) === (shift === null || shift === void 0 ? void 0 : shift.shiftCode) ? _shiftAfterUpdateLoadingTime : item;
    });
  }

  var indexStopBeforDnd = _.findIndex(deliverDetailDragShift, function (item) {
    var _item$storeInfo3;

    return ((_item$storeInfo3 = item.storeInfo) === null || _item$storeInfo3 === void 0 ? void 0 : _item$storeInfo3.customerCode) === stop.storeInfo.customerCode;
  });

  var deliverDragShiftAfter = deliverDetailDragShift.filter(function (item) {
    var _item$storeInfo4;

    return ((_item$storeInfo4 = item.storeInfo) === null || _item$storeInfo4 === void 0 ? void 0 : _item$storeInfo4.customerCode) !== stop.storeInfo.customerCode;
  });

  if (positionStopFinal > indexStopBeforDnd) {
    deliverDragShiftAfter.splice(positionStopFinal - 1, 0, stop);
  } else {
    deliverDragShiftAfter.splice(positionStopFinal, 0, stop);
  }

  var enCodePath = getEnCodePathDndStopOnShift({
    shift: shift,
    indexStopAfter: positionStopFinal,
    indexStopBefor: indexStopBeforDnd,
    stop: stop
  });

  var newShift = _objectSpread(_objectSpread({}, shift), {}, {
    enCodePath: enCodePath,
    deliverDetail: deliverDragShiftAfter
  });

  var shiftAfterUpdateFillRate = getFillRate(newShift);
  var shiftAfterUpdateLoadingTime = getLoadingTime(shiftAfterUpdateFillRate, false, true, dataOrg);
  return routeList.map(function (item) {
    return (item === null || item === void 0 ? void 0 : item.shiftCode) === (shift === null || shift === void 0 ? void 0 : shift.shiftCode) ? shiftAfterUpdateLoadingTime : item;
  });
};
export var moveStopsToVehicleOnRoute = function moveStopsToVehicleOnRoute(_ref16) {
  var dataOrg = _ref16.dataOrg,
      dataStop = _ref16.dataStop,
      position = _ref16.position,
      toShift = _ref16.toShift,
      routeList = _ref16.routeList,
      isPNS = _ref16.isPNS;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  var positionStopFinal = position;
  var newRouteList = routeList;

  if (!isOpenRoute) {
    var moveToLastRoute = _.get(toShift, 'deliverDetail', []).length <= position;
    var groupByDataStop = Object.values(_.groupBy(dataStop, 'route.shiftCode'));
    groupByDataStop.forEach(function (item) {
      newRouteList = removeStopFromRoute({
        newRouteList: newRouteList,
        item: item,
        isPNS: isPNS,
        dataOrg: dataOrg
      });
    });
    var toShiftAfterUpdate = newRouteList.find(function (i) {
      return i.shiftCode === toShift.shiftCode;
    });

    var deliverDetailToShift = _.get(toShiftAfterUpdate, 'deliverDetail');

    dataStop.forEach(function (i, indexItem) {
      var stop = i.stop; // move stop to shift

      newRouteList = moveStopToRoute({
        newRouteList: newRouteList,
        toShift: toShift,
        position: moveToLastRoute ? deliverDetailToShift.length : position,
        isPNS: isPNS,
        stop: stop,
        dataOrg: dataOrg
      });
    });
    return newRouteList;
  }

  dataStop.forEach(function (item) {
    var _$get;

    var route = item.route,
        stop = item.stop;
    var routeAfterUpdate = newRouteList.find(function (i) {
      return i.shiftCode === route.shiftCode;
    });
    var deliverDetailFromShiftAfter = (_$get = _.get(routeAfterUpdate, 'deliverDetail')) === null || _$get === void 0 ? void 0 : _$get.filter(function (item) {
      var _item$storeInfo5;

      return ((_item$storeInfo5 = item.storeInfo) === null || _item$storeInfo5 === void 0 ? void 0 : _item$storeInfo5.customerCode) !== stop.storeInfo.customerCode;
    });
    var toShiftAfterUpdate = newRouteList.find(function (i) {
      return i.shiftCode === toShift.shiftCode;
    });
    var isRemoveRoute = deliverDetailFromShiftAfter === null || deliverDetailFromShiftAfter === void 0 ? void 0 : deliverDetailFromShiftAfter.filter(function (item) {
      return item.storeInfo;
    });

    var deliverDetailToShift = _.get(toShiftAfterUpdate, 'deliverDetail');

    var DuplicateStop = deliverDetailToShift.find(function (item) {
      return _.get(item, 'storeInfo._id') === _.get(stop, 'storeInfo._id');
    });

    if (DuplicateStop) {
      var mergeStop = _objectSpread(_objectSpread({}, DuplicateStop), {}, {
        orderInfo: [].concat(_toConsumableArray(DuplicateStop.orderInfo), _toConsumableArray(stop.orderInfo))
      });

      deliverDetailToShift = deliverDetailToShift.map(function (item) {
        return _.get(item, 'storeInfo._id') === _.get(stop, 'storeInfo._id') ? mergeStop : item;
      });
    } else {
      deliverDetailToShift.splice(positionStopFinal, 0, stop);
      deliverDetailToShift = updateTimeFromPosition({
        deliverDetail: deliverDetailToShift,
        position: positionStopFinal - 1
      });
    }

    var xxx = getEncodedPathDragShift({
      dragShift: routeAfterUpdate,
      stop: stop
    });
    var yyy = getEncodedPathDropShift({
      dropShift: _objectSpread(_objectSpread({}, toShift), {}, {
        deliverDetail: deliverDetailToShift
      }),
      stop: stop,
      position: positionStopFinal,
      isStopCodeOnDropShift: DuplicateStop
    });

    if (isRemoveRoute.length < 1) {
      newRouteList = newRouteList.filter(function (item) {
        return item.shiftCode !== route.shiftCode;
      });
    } else {
      newRouteList = newRouteList.map(function (item) {
        if (item.shiftCode === toShift.shiftCode) {
          var newToShift = _objectSpread(_objectSpread({}, item), {}, {
            deliverDetail: deliverDetailToShift,
            encodedPath: yyy
          });

          var newToShiftAfterUpdateFillRate = getFillRate(newToShift);
          var newToShiftAfterUpdateLoadingTime = getLoadingTime(newToShiftAfterUpdateFillRate, false, isPNS, dataOrg);
          return newToShiftAfterUpdateLoadingTime;
        }

        if (item.shiftCode === route.shiftCode) {
          var newFromShift = _objectSpread(_objectSpread({}, item), {}, {
            deliverDetail: deliverDetailFromShiftAfter,
            encodedPath: xxx
          });

          var newFromShiftAfterUpdateFillRate = getFillRate(newFromShift);
          var newFromShiftAfterUpdateLoadingTime = getLoadingTime(newFromShiftAfterUpdateFillRate, false, isPNS, dataOrg);
          return newFromShiftAfterUpdateLoadingTime;
        }

        return item;
      });
    }
  });
  return newRouteList;
};
export var moveStopToVehicle = function moveStopToVehicle(_ref17) {
  var dataOrg = _ref17.dataOrg,
      dataStop = _ref17.dataStop,
      routeList = _ref17.routeList,
      dataVehicle = _ref17.dataVehicle,
      createShiftCodeAndTripCode = _ref17.createShiftCodeAndTripCode,
      isPNS = _ref17.isPNS,
      shiftStartTime = _ref17.shiftStartTime,
      date = _ref17.date;
  var shiftCode = createShiftCodeAndTripCode.shiftCode,
      tripCode = createShiftCodeAndTripCode.tripCode;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  var depot = _.head(_.get(_.head(routeList), 'deliverDetail'));

  var groupByStop = Object.values(_.groupBy(dataStop, 'stop.storeInfo._id'));
  var deliverDetail = groupByStop.map(function (item) {
    if (item.length < 2) return _.get(_.head(item), 'stop');else {
      return item.reduce(function (acc, stop) {
        var _stop$stop;

        return _objectSpread(_objectSpread({}, acc), {}, {
          orderInfo: [].concat(_toConsumableArray(acc === null || acc === void 0 ? void 0 : acc.orderInfo), _toConsumableArray(stop === null || stop === void 0 ? void 0 : (_stop$stop = stop.stop) === null || _stop$stop === void 0 ? void 0 : _stop$stop.orderInfo)),
          totalPrice: (acc === null || acc === void 0 ? void 0 : acc.totalPrice) + (stop === null || stop === void 0 ? void 0 : stop.totalPrice),
          totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + (stop === null || stop === void 0 ? void 0 : stop.totalVolume),
          totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + (stop === null || stop === void 0 ? void 0 : stop.totalWeight)
        });
      }, {
        orderInfo: [],
        totalPrice: 0,
        totalVolume: 0,
        totalWeight: 0
      });
    }
  });
  var newRouteList = routeList;

  if (!isOpenRoute) {
    var groupByDataStop = Object.values(_.groupBy(dataStop, 'route.shiftCode'));
    groupByDataStop.forEach(function (item, indexDataStop) {
      newRouteList = removeStopFromRoute({
        newRouteList: newRouteList,
        item: item,
        isPNS: isPNS,
        dataOrg: dataOrg
      });
    });

    var depotCloseRoute = _objectSpread(_objectSpread({}, depot), {}, {
      tripCode: null,
      doneTime: _.get(_.last(deliverDetail), 'doneTime'),
      deliverTime: _.get(_.last(deliverDetail), 'doneTime')
    });

    var _finalDeliverDetail = [_objectSpread(_objectSpread({}, depot), {}, {
      tripCode: tripCode
    })].concat(_toConsumableArray(deliverDetail), [depotCloseRoute]);

    var _encodedPath = getEncodedPathFromDeliverDetail(_finalDeliverDetail);

    var _newRoute = {
      routeIndex: 1,
      shiftStartTime: shiftStartTime,
      shiftCode: shiftCode,
      planningStatus: 'PLANNED',
      organizationId: {
        organizationName: _.get(dataOrg, 'organizationName'),
        _id: dataOrg === null || dataOrg === void 0 ? void 0 : dataOrg._id
      },
      lastUpdatedAt: date,
      lockedRoute: false,
      optimizedAt: date,
      deliveryDate: date,
      deliveryDateEnd: date,
      deliveryDateStart: date,
      distance: 0,
      createdAt: date,
      driverInfo: _objectSpread({}, dataVehicle),
      deliverDetail: _finalDeliverDetail,
      deliverTotalInfo: {
        totalCost: 4130.61,
        totalDistance: 412.061,
        totalFamiliarStores: 0,
        totalFamiliarity: 0,
        totalOrder: 3,
        totalProfit: -4130.61,
        totalRevenue: 0,
        totalStores: 4,
        totalVolume: 5.407551,
        totalWeight: 504.712,
        unqualifiedMOQ: false,
        unqualifiedMOQValue: false
      },
      encodedPath: _encodedPath
    };

    var _newShiftAfterUpdateFillRate = getFillRate(_newRoute);

    var _newShiftAfterUpdateLoadingTime = getLoadingTime(_newShiftAfterUpdateFillRate, shiftStartTime, isPNS, dataOrg);

    return [].concat(_toConsumableArray(newRouteList), [_newShiftAfterUpdateLoadingTime]);
  }

  dataStop.forEach(function (item) {
    var _$get2;

    var route = item.route,
        stop = item.stop,
        indexStopPrev = item.indexStopPrev;
    var routeAfterUpdate = newRouteList.find(function (i) {
      return i.shiftCode === route.shiftCode;
    });
    var deliverDetailFromShiftAfter = (_$get2 = _.get(routeAfterUpdate, 'deliverDetail')) === null || _$get2 === void 0 ? void 0 : _$get2.filter(function (item) {
      var _item$storeInfo6;

      return ((_item$storeInfo6 = item.storeInfo) === null || _item$storeInfo6 === void 0 ? void 0 : _item$storeInfo6.customerCode) !== stop.storeInfo.customerCode;
    });
    var isRemoveRoute = deliverDetailFromShiftAfter.filter(function (item) {
      return item.storeInfo;
    });

    if (isRemoveRoute.length < 1) {
      newRouteList = newRouteList.filter(function (item) {
        return item.shiftCode !== route.shiftCode;
      });
    } else {
      var _encodedPath2 = getEncodedPathDragShift({
        dragShift: routeAfterUpdate,
        stop: stop
      });

      newRouteList = newRouteList.map(function (item) {
        if (item.shiftCode === route.shiftCode) return _objectSpread(_objectSpread({}, item), {}, {
          deliverDetail: deliverDetailFromShiftAfter,
          encodedPath: _encodedPath2
        });
        return item;
      });
    }
  });
  var finalDeliverDetail = [_objectSpread(_objectSpread({}, depot), {}, {
    tripCode: tripCode
  })].concat(_toConsumableArray(deliverDetail));
  var encodedPath = getEncodedPathFromDeliverDetail(finalDeliverDetail);
  var deliverDetailUpdateTime = updateTimeFromPosition({
    deliverDetail: finalDeliverDetail,
    position: 1
  });
  var newRoute = {
    routeIndex: 1,
    shiftStartTime: shiftStartTime,
    shiftCode: shiftCode,
    planningStatus: 'PLANNED',
    organizationId: {
      organizationName: _.get(dataOrg, 'organizationName'),
      _id: dataOrg === null || dataOrg === void 0 ? void 0 : dataOrg._id
    },
    lastUpdatedAt: date,
    lockedRoute: false,
    optimizedAt: date,
    deliveryDate: date,
    deliveryDateEnd: date,
    deliveryDateStart: date,
    distance: 0,
    createdAt: date,
    driverInfo: _objectSpread({}, dataVehicle),
    deliverDetail: deliverDetailUpdateTime,
    deliverTotalInfo: {
      totalCost: 4130.61,
      totalDistance: 412.061,
      totalFamiliarStores: 0,
      totalFamiliarity: 0,
      totalOrder: 3,
      totalProfit: -4130.61,
      totalRevenue: 0,
      totalStores: 4,
      totalVolume: 5.407551,
      totalWeight: 504.712,
      unqualifiedMOQ: false,
      unqualifiedMOQValue: false
    },
    encodedPath: encodedPath
  };
  var newShiftAfterUpdateFillRate = getFillRate(newRoute);
  var newShiftAfterUpdateLoadingTime = getLoadingTime(newShiftAfterUpdateFillRate, shiftStartTime, isPNS, dataOrg);
  return [].concat(_toConsumableArray(newRouteList), [newShiftAfterUpdateLoadingTime]);
};
export var changeDriver = function changeDriver(_ref18) {
  var routeList = _ref18.routeList,
      changedDriver = _ref18.changedDriver,
      changedShift = _ref18.changedShift,
      dataOrg = _ref18.dataOrg,
      isPNS = _ref18.isPNS;

  var newShift = _objectSpread(_objectSpread({}, changedShift), {}, {
    assignedDriver: changedDriver
  });

  var newShiftAfterUpdateFillRate = getFillRate(newShift);
  var newShiftAfterUpdateLoadingTime = getLoadingTime(newShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  return routeList.map(function (item) {
    return item.shiftCode === changedShift.shiftCode ? newShiftAfterUpdateLoadingTime : item;
  });
};
export var changeVehicle = function changeVehicle(_ref19) {
  var routeList = _ref19.routeList,
      changedVehicle = _ref19.changedVehicle,
      changedShift = _ref19.changedShift,
      dataOrg = _ref19.dataOrg,
      isPNS = _ref19.isPNS;

  var newShift = _objectSpread(_objectSpread({}, changedShift), {}, {
    driverInfo: changedVehicle
  });

  var newShiftAfterUpdateFillRate = getFillRate(newShift);
  var newShiftAfterUpdateLoadingTime = getLoadingTime(newShiftAfterUpdateFillRate, false, isPNS, dataOrg);
  return routeList.map(function (item) {
    return item.shiftCode === changedShift.shiftCode ? newShiftAfterUpdateLoadingTime : item;
  });
};
export var removeStop = function removeStop(_ref20) {
  var dataStop = _ref20.dataStop,
      routeList = _ref20.routeList,
      missingOrderList = _ref20.missingOrderList,
      dataOrg = _ref20.dataOrg,
      isPNS = _ref20.isPNS;
  var newRouteList = routeList;
  var newMissingOrderList = missingOrderList;

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  if (!isOpenRoute) {
    var groupByDataStop = Object.values(_.groupBy(dataStop, 'route.shiftCode'));
    groupByDataStop.forEach(function (item, indexDataStop) {
      newRouteList = removeStopFromRoute({
        newRouteList: newRouteList,
        item: item,
        isPNS: isPNS,
        dataOrg: dataOrg
      });
      item.forEach(function (stops) {
        var route = stops.route,
            stop = stops.stop;

        var convertReasonMissing = _.get(stop, 'orderInfo').map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            routeInfo: _objectSpread(_objectSpread({}, item.routeInfo), {}, {
              reason: [{
                code: 'AllVehicle',
                error: "REMOVE BY ".concat(window.user.username),
                type: 'All'
              }]
            })
          });
        });

        newMissingOrderList = [].concat(_toConsumableArray(newMissingOrderList), _toConsumableArray(convertReasonMissing));
      });
    });
    return {
      newRouteList: newRouteList,
      newMissingOrderList: newMissingOrderList
    };
  }

  dataStop.forEach(function (item) {
    var _$get3;

    var route = item.route,
        stop = item.stop;
    var routeAfterUpdate = newRouteList.find(function (i) {
      return i.shiftCode === route.shiftCode;
    });
    var deliverDetailFromShiftAfter = (_$get3 = _.get(routeAfterUpdate, 'deliverDetail')) === null || _$get3 === void 0 ? void 0 : _$get3.filter(function (item) {
      var _item$storeInfo7;

      return ((_item$storeInfo7 = item.storeInfo) === null || _item$storeInfo7 === void 0 ? void 0 : _item$storeInfo7.customerCode) !== stop.storeInfo.customerCode;
    });
    var isRemoveRoute = deliverDetailFromShiftAfter === null || deliverDetailFromShiftAfter === void 0 ? void 0 : deliverDetailFromShiftAfter.filter(function (item) {
      return item.storeInfo;
    });

    var convertReasonMissing = _.get(stop, 'orderInfo').map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        routeInfo: _objectSpread(_objectSpread({}, item.routeInfo), {}, {
          reason: [{
            code: 'AllVehicle',
            error: "REMOVE BY ".concat(window.user.username),
            type: 'All'
          }]
        })
      });
    });

    newMissingOrderList = [].concat(_toConsumableArray(newMissingOrderList), _toConsumableArray(convertReasonMissing));

    var indexStore = _.findIndex(_.get(routeAfterUpdate, 'deliverDetail'), function (item) {
      var _item$storeInfo8;

      return ((_item$storeInfo8 = item.storeInfo) === null || _item$storeInfo8 === void 0 ? void 0 : _item$storeInfo8.customerCode) === stop.storeInfo.customerCode;
    });

    var encodedPath = getEncodePathRemoveStop({
      route: route,
      indexStore: indexStore
    });

    if (isRemoveRoute.length > 0) {
      newRouteList = newRouteList.map(function (item) {
        return item.shiftCode === route.shiftCode ? _objectSpread(_objectSpread({}, route), {}, {
          deliverDetail: deliverDetailFromShiftAfter,
          encodedPath: encodedPath
        }) : item;
      });
    } else {
      newRouteList = newRouteList.filter(function (i) {
        return i.shiftCode !== route.shiftCode;
      });
    }
  });
  return {
    newRouteList: newRouteList,
    newMissingOrderList: newMissingOrderList
  };
};
export var removeOrder = function removeOrder(_ref21) {
  var route = _ref21.route,
      order = _ref21.order,
      store = _ref21.store,
      routeList = _ref21.routeList,
      missingOrderList = _ref21.missingOrderList,
      storeIndex = _ref21.storeIndex,
      dataOrg = _ref21.dataOrg,
      isPNS = _ref21.isPNS;
  var newRouteList = routeList;

  var deliverDetailBefor = _.get(route, 'deliverDetail');

  var storeInfoBefor = _.find(deliverDetailBefor, function (i, index) {
    return storeIndex === index;
  });

  var OrderListAfter = _.get(storeInfoBefor, 'orderInfo', []).filter(function (i) {
    return i._id !== order._id;
  });

  var isOpenRoute = _.get(dataOrg, 'algoConfig.openRoute');

  if (!isOpenRoute) {
    if (OrderListAfter.length > 0) {
      var deliverDetailAfter = deliverDetailBefor.map(function (item, index) {
        return index === storeIndex ? _objectSpread(_objectSpread({}, storeInfoBefor), {}, {
          totalVolume: storeInfoBefor.totalVolume - order.totalVolume,
          totalWeight: storeInfoBefor.totalWeight - order.totalWeight,
          orderInfo: OrderListAfter
        }) : item;
      });

      var newShift = _objectSpread(_objectSpread({}, route), {}, {
        deliverDetail: deliverDetailAfter
      });

      var newShiftAfterUpdateFillRate = getFillRate(newShift);
      var newShiftAfterUpdateLoadingTime = getLoadingTime(newShiftAfterUpdateFillRate, false, isPNS, dataOrg);
      newRouteList = routeList.map(function (i) {
        return i.shiftCode === route.shiftCode ? newShiftAfterUpdateLoadingTime : i;
      });
    } else {
      var item = [{
        route: route,
        indexStopPrev: storeIndex
      }];
      newRouteList = removeStopFromRoute({
        newRouteList: newRouteList,
        item: item,
        isPNS: isPNS,
        dataOrg: dataOrg
      });
    }

    return {
      routeList: newRouteList,
      missingOrderList: [].concat(_toConsumableArray(missingOrderList), [_objectSpread(_objectSpread({}, order), {}, {
        routeInfo: _objectSpread(_objectSpread({}, order.routeInfo), {}, {
          reason: [{
            code: 'AllVehicle',
            error: "REMOVE BY ".concat(window.user.username),
            type: 'All'
          }]
        })
      })])
    };
  }

  if (OrderListAfter.length > 0) {
    var _deliverDetailAfter = deliverDetailBefor.map(function (item) {
      var _item$storeInfo9, _store$storeInfo;

      return ((_item$storeInfo9 = item.storeInfo) === null || _item$storeInfo9 === void 0 ? void 0 : _item$storeInfo9._id) === ((_store$storeInfo = store.storeInfo) === null || _store$storeInfo === void 0 ? void 0 : _store$storeInfo._id) ? _objectSpread(_objectSpread({}, storeInfoBefor), {}, {
        orderInfo: OrderListAfter
      }) : item;
    });

    newRouteList = routeList.map(function (i) {
      return i.shiftCode === route.shiftCode ? _objectSpread(_objectSpread({}, i), {}, {
        deliverDetail: _deliverDetailAfter
      }) : i;
    });
  } else {
    var _deliverDetailAfter2 = deliverDetailBefor.filter(function (i) {
      var _i$storeInfo4, _store$storeInfo2;

      return ((_i$storeInfo4 = i.storeInfo) === null || _i$storeInfo4 === void 0 ? void 0 : _i$storeInfo4._id) !== ((_store$storeInfo2 = store.storeInfo) === null || _store$storeInfo2 === void 0 ? void 0 : _store$storeInfo2._id);
    });

    if (_.find(_deliverDetailAfter2, function (i) {
      return i.storeInfo;
    })) {
      var index = _.findIndex(deliverDetailBefor, function (item) {
        var _item$storeInfo10, _store$storeInfo3;

        return ((_item$storeInfo10 = item.storeInfo) === null || _item$storeInfo10 === void 0 ? void 0 : _item$storeInfo10._id) === ((_store$storeInfo3 = store.storeInfo) === null || _store$storeInfo3 === void 0 ? void 0 : _store$storeInfo3._id);
      });

      var encodedPath = getEncodePathRemoveStop({
        route: route,
        indexStore: index
      });
      newRouteList = routeList.map(function (i) {
        return i.shiftCode === route.shiftCode ? _objectSpread(_objectSpread({}, i), {}, {
          encodedPath: encodedPath,
          deliverDetail: _deliverDetailAfter2
        }) : i;
      });
    } else {
      newRouteList = routeList.filter(function (i) {
        return i.shiftCode !== route.shiftCode;
      });
    }
  }

  return {
    routeList: newRouteList,
    missingOrderList: [].concat(_toConsumableArray(missingOrderList), [_objectSpread(_objectSpread({}, order), {}, {
      routeInfo: _objectSpread(_objectSpread({}, order.routeInfo), {}, {
        reason: [{
          code: 'AllVehicle',
          error: "REMOVE BY ".concat(window.user.username),
          type: 'All'
        }]
      })
    })])
  };
};