export var weekDays = [{
  label: 'Monday',
  value: 'MON'
}, {
  label: 'Tuesday',
  value: 'TUE'
}, {
  label: 'Wednesday',
  value: 'WED'
}, {
  label: 'Thursday',
  value: 'THU'
}, {
  label: 'Friday',
  value: 'FRI'
}, {
  label: 'Saturday',
  value: 'SAT'
}, {
  label: 'Sunday',
  value: 'SUN'
}];