export var driverPosition = [{
  value: 'Driver Leader',
  label: 'Driver Leader'
}, {
  value: 'Delivery Man',
  label: 'Delivery Man'
}, {
  value: 'LDD',
  label: 'LDD'
}, {
  value: 'HDD',
  label: 'HDD'
}];