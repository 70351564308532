import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { HEIGHT_BTN } from './mapViewFooterConstant';
import IconButton from '@material-ui/core/IconButton';
import { isVnLanguage } from './mapViewFooterConstant';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
export var useStyleBtn = makeStyles(function (theme) {
  return {
    hBtn: {
      height: isVnLanguage ? '38px !important' : "".concat(HEIGHT_BTN, " !important")
    },
    wBtn: {
      width: '79px'
    },
    r2Footer: {
      position: 'fixed',
      right: '0px',
      minWidth: '550px !important',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    displayBtn: {
      display: 'flex',
      alignItems: 'center'
    },
    btnSaveEditMode: {
      '& .MuiButton-label': {
        padding: '2px 0',
        minWidth: 80
      }
    }
  };
});
export var MapViewFooterWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: 999;\n  display: flex;\n  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.12);\n  background-color: #ffffff;\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n\n  .group-btn {\n    display: flex;\n  }\n"])));
export var ParameterFooter = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  font-weight: 400;\n  color: #5e6366;\n  overflow: auto;\n  width: calc(100vw - 550px - 180px) !important;\n  white-space: nowrap;\n  \n  & > ul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0 15px;\n    text-align: start;\n\n    & > li {\n      padding: 0;\n    }\n\n    .footer-timeline-unit {\n      color: #2190e0;\n    }\n  }\n"])));
export var LockRouteContentWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var UnlockRouteContentWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var ReoptimizeContentWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var IconButtonCus = styled(IconButton)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: #ffffff;\n  background-color: ", ";\n  width: 32px;\n  height: 32px;\n  border-radius: 0;\n  margin-right: 16px;\n"])), function (props) {
  return props.isActive ? '#1A89FD' : '#C6CACC';
});
export var CustomTextField1 = withStyles({
  root: {
    minWidth: 320,
    textAlign: 'left'
  }
})(Select);