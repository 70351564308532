import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import _ from 'lodash';
export var convertOrderToStop = function convertOrderToStop(_ref) {
  var order = _ref.order,
      shift = _ref.shift;

  var deliverDetail = _.get(shift, 'deliverDetail', []);

  var stopOnShift = _.find(deliverDetail, function (deliver) {
    var _deliver$storeInfo;

    return ((_deliver$storeInfo = deliver.storeInfo) === null || _deliver$storeInfo === void 0 ? void 0 : _deliver$storeInfo.customerCode) === _.get(order, 'customerInfo.customerCode');
  });

  if (stopOnShift) {
    return _objectSpread(_objectSpread({}, stopOnShift), {}, {
      totalVolume: stopOnShift.totalVolume + order.totalVolume,
      totalWeight: stopOnShift.totalWeight + order.totalWeight,
      totalPrice: stopOnShift.totalPrice + order.totalPrice,
      orderInfo: [].concat(_toConsumableArray(stopOnShift.orderInfo), [order]),
      fillRateVolume: 0,
      fillRateWeight: 0,
      deliverTime: '00:00',
      doneTime: '00:00'
    });
  }

  return {
    depotInfo: null,
    fillRateVolume: 0,
    fillRateWeight: 0,
    orderInfo: [order],
    storeInfo: _.get(order, 'customerInfo'),
    totalPrice: order.totalPrice,
    totalVolume: order.totalVolume,
    totalWeight: order.totalWeight,
    deliverTime: '00:00',
    doneTime: '00:00'
  };
};