var theme = {
  // Colors
  blue1: '#1B64B0',
  blue2: '#004A92',
  blue3: '#2196F3',
  blue4: '#4881D0',
  blue5: '#6C9FF1',
  blue6: '#8EBEFF',
  blue7: '#B1DEFF',
  blue8: '#DCEFFD',
  blue9: '#1A89FD',
  blue10: '#004F98',
  blue11: '#537EC6',
  blue12: '#4083ca',
  blue13: '#2F80ED',
  blue14: '#F7F9FA',
  white: '#FFFFFF',
  black1: '#414142',
  black2: '#3e4345',
  gray1: '#919699',
  gray2: '#F1F3F4',
  gray3: '#E3E5E5',
  gray4: '#c6cacc',
  red1: '#FF4444',
  red2: '#FFF5F5',
  red3: '#FF6D6D',
  red4: '#FF847C',
  red5: '#E84A5F',
  red6: '#E53935',
  orange1: '#F98313',
  orange2: '#FFEACB',
  orange3: '#CE6000',
  orange4: '#FFB236',
  orange5: '#FF7043',
  yellow1: '#F9DC5C',
  yellow2: '#E7C734',
  yellow3: '#F5B281',
  yellow4: '#F8CD35',
  yellow5: '#ECD619',
  green1: '#0B834A',
  green2: '#A2F5CB',
  green3: '#00733C',
  green4: '#2AAEA0',
  green5: '#7CB342',
  purple1: '#8F6DBB',
  purple2: '#A4748B',
  // Borders
  borderRadiusBase: '2px',
  borderRadiusMedium: '6px',
  borderRadiusLargeLower: '12px',
  borderRadiusLarge: '20px',
  borderRadiusLargeUpper: '24px',
  // Fonts
  fontSizeSmallest: '10px',
  fontSizeSmall: '12px',
  fontSizeBase: '14px',
  fontSizeMedium: '16px',
  fontSizeBig: '20px',
  fontSizeLarge: '24px',
  fontSizeXlarge: '32px',
  fontWeightBase: '300',
  fontWeightMedium: '400',
  fontWeightNormal: '500',
  fontWeightBold: '600',
  // Typography
  lineHeightBase: 1,
  // Z-index
  zIndexes: {
    default: 0,
    base: 1,
    under: -1,
    modal: 1001,
    sidebar: 1000,
    statusDropdown: 999,
    registerModal: 1002,
    alert: 1010,
    menuCollapse: 10,
    warningBox: 10,
    detailInfoView: 5,
    overlayBlock: 9
  },
  // header, footer height
  heightHeader: '80px',
  heightHeaderSp: '60px',
  heightFooter: '69px',
  heightFooterSp: '48px',
  heightMatchingTable: '560px',
  // Box shadow
  shadowS: '0px 3px 12px -1px rgba(145, 150, 153, 0.26), 0px 2px 4px -1px rgba(145, 150, 153, 0.16)',
  shadowM: '0px 8px 24px -4px rgba(145, 150, 153, 0.38), 0px 2px 6px -1px rgba(145, 150, 153, 0.16)',
  shadowL: '0px 12px 48px -6px rgba(145, 150, 153, 0.38), 0px 3px 18px -2px rgba(145, 150, 153, 0.16)',
  shadowXl: '0px 18px 64px -8px rgba(145, 150, 153, 0.38), 0px 4px 24px -3px rgba(145, 150, 153, 0.16)',
  shadowModal: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
  // Grid breakpoints
  gridBreakpoints: {
    xs: 0,
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
  }
};
export default theme;