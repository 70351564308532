function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

import _ from 'lodash';

function type_of_depot(depot) {
  return depot && depot.orgCategory && depot.orgCategory[0] && depot.orgCategory[0].organizationCategoryName || 'Depot';
}

function isXdoc(depot) {
  return type_of_depot(depot) === 'xDock';
}

function getNumberOfStores(route) {
  return _.reduce(route.deliverDetail, function (sum, store) {
    if (store.storeInfo && !isXdoc(store.storeInfo)) return sum + 1;
    return sum;
  }, 0);
}

function numberWithCommas(x, decimal) {
  decimal = decimal || 0;
  var result = x && x.toFixed && x.toFixed(decimal) || 0;
  return result && result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export var getRoutePlanFooterInfo = function getRoutePlanFooterInfo(_ref) {
  var routes = _ref.routes,
      isLongHaul = _ref.isLongHaul,
      isPNS = _ref.isPNS;
  var used_vehicles = {},
      used_routes = {};

  var result = _.reduce(routes, function (stats, route) {
    var temp = route !== null && route !== void 0 && route.associateTo ? route === null || route === void 0 ? void 0 : route.associateTo : route;
    if (used_routes[route === null || route === void 0 ? void 0 : route._id] || !route) return stats;
    route.deliverTotalInfo.totalStores = getNumberOfStores(temp);

    for (var key in stats) {
      var capacities = ['capacityVolume', 'capacityWeight'],
          weight_volume = ['totalVolume', 'totalWeight', 'totalRevenue'];

      if (_.includes(capacities, key)) {
        stats[key] += parseFloat(route.driverInfo[key]);
      } else if (_.includes(weight_volume, key)) {
        var _iterator = _createForOfIteratorHelper(route.deliverDetail),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var store = _step.value;

            if (store.orderInfo) {
              var _iterator2 = _createForOfIteratorHelper(store.orderInfo),
                  _step2;

              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var order = _step2.value;
                  if (key === 'totalRevenue') stats[key] += parseFloat(order['totalPrice']);else stats[key] += parseFloat(order[key]);
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else if (isPNS && key !== 'totalCost') {
        stats[key] += parseFloat(route === null || route === void 0 ? void 0 : route.deliverTotalInfo[key]);
      } else if (!isPNS) {
        stats[key] += parseFloat(route === null || route === void 0 ? void 0 : route.deliverTotalInfo[key]);
      }

      if (isPNS && key === 'totalCost') {
        var feeList = _.get(route, 'vehicleShipmentFee', []);

        var transportFee = _.find(feeList, function (fee) {
          return _.get(fee, 'vehicleId.vehicleCode') === _.get(route, 'driverInfo.vehicleCode');
        });

        stats['totalCost'] += _.get(transportFee, 'totalPrice');
      }
    }

    used_routes[temp._id] = true;
    return stats;
  }, {
    totalCost: 0,
    totalDistance: 0,
    totalFamiliarStores: 0,
    totalFamiliarity: 0,
    totalOrder: 0,
    totalProfit: 0,
    totalRevenue: 0,
    totalVolume: 0,
    totalWeight: 0,
    totalStores: 0,
    capacityVolume: 0,
    capacityWeight: 0
  });

  result['numberOfTrucks'] = 0;
  result['numberOfBikes'] = 0;

  _.reduce(routes, function (stats, route) {
    var _route$driverInfo, _route$driverInfo2, _route$driverInfo2$ty;

    if (used_vehicles[route === null || route === void 0 ? void 0 : (_route$driverInfo = route.driverInfo) === null || _route$driverInfo === void 0 ? void 0 : _route$driverInfo.vehicleCode] || !route) return stats;

    if (((_route$driverInfo2 = route.driverInfo) === null || _route$driverInfo2 === void 0 ? void 0 : (_route$driverInfo2$ty = _route$driverInfo2.typeOfVehicle) === null || _route$driverInfo2$ty === void 0 ? void 0 : _route$driverInfo2$ty.toLowerCase()) !== 'bike') {
      stats['numberOfTrucks']++;
    } else {
      stats['numberOfBikes']++;
    }

    used_vehicles[route.driverInfo.vehicleCode] = true;
    return stats;
  }, result);

  return {
    totalCost: numberWithCommas(result.totalCost),
    totalDistance: numberWithCommas(result.totalDistance, 2),
    totalFamiliarStores: numberWithCommas(result.totalFamiliarStores),
    totalFamiliarity: numberWithCommas(result.totalFamiliarity),
    percentFamiliarity: numberWithCommas(100 * result.totalFamiliarStores / result.totalStores),
    totalOrder: numberWithCommas(result.totalOrder),
    totalProfit: numberWithCommas(result.totalProfit),
    totalRevenue: numberWithCommas(result.totalRevenue),
    totalVolume: numberWithCommas(result.totalVolume, 2),
    totalWeight: numberWithCommas(result.totalWeight, 2),
    totalStores: numberWithCommas(result.totalStores),
    capacityVolume: numberWithCommas(result.capacityVolume, 2),
    capacityWeight: numberWithCommas(result.capacityWeight, 2),
    numberOfTrucks: numberWithCommas(result.numberOfTrucks),
    numberOfBikes: numberWithCommas(result.numberOfBikes),
    productivity: numberWithCommas(result.totalRevenue - result.totalCost),
    revenuePerDistance: numberWithCommas(result.totalRevenue / result.totalDistance),
    numberOfVehicles: numberWithCommas(result.numberOfTrucks + result.numberOfBikes)
  };
};