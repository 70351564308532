import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
export var BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);
export var VehicleOpenButton = withStyles(function (theme) {
  return {
    root: {
      color: '#0B834A',
      backgroundColor: '#A2F5CB',
      borderRadius: 100,
      textTransform: 'capitalize',
      maxHeight: 24
    }
  };
})(Button);
export var VehiclePlannedButton = withStyles(function (theme) {
  return {
    root: {
      color: '#F98313',
      backgroundColor: '#FFEACB',
      borderRadius: 100,
      textTransform: 'capitalize',
      maxHeight: 24
    }
  };
})(Button);
export var VehicleLockedButton = withStyles(function (theme) {
  return {
    root: {
      color: '#666',
      backgroundColor: '#ccc',
      borderRadius: 100,
      textTransform: 'capitalize',
      maxHeight: 24
    }
  };
})(Button);