import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { backgroundError, backgroundInfo, backgroundSuccess, backgroundWarning, statusError, statusSuccess, textSuccess, textWarning, textInfo } from "../../constants/appColors";
export var SuccessChip = withStyles({
  root: {
    height: 24,
    color: statusSuccess,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #6AEC8E",
    backgroundColor: backgroundSuccess,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);
export var FailChip = withStyles({
  root: {
    height: 24,
    color: statusError,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #FDD2D2",
    backgroundColor: backgroundError,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);
export var ProgressingChip = withStyles({
  root: {
    height: 24,
    color: textWarning,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #FFE2B4",
    backgroundColor: backgroundWarning,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);
export var PlannedChip = withStyles({
  root: {
    height: 24,
    color: textWarning,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #FFE2B4",
    backgroundColor: backgroundWarning,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);
export var LockedChip = withStyles({
  root: {
    height: 24,
    color: textInfo,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #B9E6FF",
    backgroundColor: backgroundInfo,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);
export var FinalizedChip = withStyles({
  root: {
    height: 24,
    color: textSuccess,
    borderRadius: 99,
    mixBlendMode: "normal",
    border: "1px solid #6AEC8E",
    backgroundColor: backgroundSuccess,
    fontWeight: 500,
    fontSize: 12
  }
})(Chip);