export default function buildCSVContent(fileContent, selectedRowIndex) {
  var csvHeader = fileContent[selectedRowIndex];
  var csvContent = {};

  for (var alphabet in csvHeader) {
    csvContent[alphabet] = {
      header: csvHeader[alphabet] || "",
      content: []
    };
  }

  var l = fileContent.length;

  for (var i = 0, count = 0; i < l && count < 1; i++) {
    if (i === selectedRowIndex) continue;

    for (var _alphabet in fileContent[i]) {
      csvContent[_alphabet].content.push(fileContent[i][_alphabet]);
    }

    count++;
  }

  var result = {};

  for (var _alphabet2 in csvContent) {
    result[csvContent[_alphabet2].header] = {
      alphabet: _alphabet2,
      content: csvContent[_alphabet2].content
    };
  }

  return result;
}