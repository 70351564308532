import { makeStyles } from "@material-ui/core/styles";
import { textError } from "../../constants/appColors";
export var useStyles = makeStyles(function (theme) {
  return {
    unplannedCard: {
      width: "100%"
    },
    parentContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    content: {
      paddingLeft: theme.spacing(5.25),
      marginRight: 0,
      display: "flex",
      alignItems: "center"
    },
    moveIcon: {
      paddingLeft: "16px !important"
    },
    dialogActions: {
      width: "calc(100% + 10px)",
      padding: "8px 0",
      position: "sticky",
      bottom: 0,
      background: "white",
      paddingRight: "8px",
      justifyContent: 'space-between'
    },
    weightVolume: {
      display: "flex",
      alignItems: "center"
    },
    parentWeightVolume: {
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    bulkAction: {
      margin: theme.spacing(0, 1, 0, 0.5),
      "& .moveStop": {
        margin: theme.spacing(2),
        "& .errorText": {
          fontSize: "0.825rem",
          color: textError
        },
        "& .moveStopOrTrip": {
          fontWeight: 500,
          fontSize: "1.15rem"
        }
      },
      "& .bulkAction__action": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: theme.spacing(2),
        "& .MuiButton-root": {
          height: theme.spacing(4.5),
          minWidth: "unset",
          padding: theme.spacing(1, 2.25)
        }
      },
      "& .MuiTextField-root": {
        marginTop: theme.spacing(0.75)
      },
      "& .selected": {
        margin: theme.spacing(0, 2),
        "& p:first-child": {
          fontWeight: 500,
          fontSize: "1.15rem"
        },
        "& .MuiCard-root": {
          display: "flex",
          margin: theme.spacing(2, 0),
          "& .MuiCardContent-root": {
            padding: theme.spacing(1)
          }
        },
        "& .selectedItem": {
          "& p": {
            margin: theme.spacing(0.5, 0)
          },
          "& .gridFlex": {
            padding: 0,
            "& button": {
              height: 50,
              width: 40
            }
          },
          "& .cardActions": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        }
      }
    },
    selectVehicleCode: {},
    selectVehicle2ndLine: {
      fontSize: 12,
      color: "#919699"
    },
    selectVehicleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center'
    },
    confirmContainer: {
      marginBottom: 20
    },
    confirmList: {
      marginTop: 5,
      paddingLeft: 15,
      lineHeight: "28px"
    },
    confirmItem: {},
    confirmQuestion: {
      marginTop: 20
    },
    box: {
      marginTop: 24
    }
  };
});