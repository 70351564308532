export function getPageSize(pageOptions) {
  if (pageOptions > 5 && pageOptions <= 10) {
    return [5, pageOptions];
  }

  if (pageOptions > 10 && pageOptions <= 30) {
    return [10, pageOptions];
  }

  if (pageOptions > 30 && pageOptions <= 50) {
    return [10, 30, pageOptions];
  }

  if (pageOptions > 50 && pageOptions <= 100) {
    return [10, 30, 50, pageOptions];
  }

  if (pageOptions > 100) {
    return [10, 30, 50, 100];
  }

  return [];
}