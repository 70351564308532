import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';
import {
  fetchPackageUpgrade,
  fetchSubcription,
  deletePackage,
  fetchSaveCard,
  addCard,
  upgradePackage,
  deleteCard,
  signOut,
  paymentMethodsDefault,
  fetchSeeBilling,
  getStripeKey,
} from '@api/pricing';
import { clearCookiesAndRedirectToLogin } from '@utils/clearCookies';
export default class PricingStore {
  @observable listPackageUpgrade = [];
  @observable modalPaymentMethodsState = {
    tab: 0,
    package: '',
    cost: '150',
  };
  @observable dataSubCription = {};
  @observable dataSaveCard = {};
  @observable paymentMethodsData = {
    checked: 'save',
    date: '',
    cardNumber: '',
    securityCode: '',
    cardName: '',
  };
  @observable errorFormDataCard = {
    cardName: null,
    cardNumber: null,
    securityCode: null,
    date: null,
  };
  @observable dataSeeBilling = [];
  @observable stripeKey;

  constructor(root) {
    makeObservable(this);
    this.root = root;
  }

  @action.bound
  dispatchSetPaymentMethodsData(payload) {
    this.paymentMethodsData = payload;
  }

  @action.bound
  async dispatchFetchListPackage() {
    const res = await fetchPackageUpgrade();
    this.listPackageUpgrade = res.data?.data;
  }

  @action.bound
  async dispatchFetchSeeBillingList() {
    const res = await fetchSeeBilling(window?.user?.stripeId);
    this.dataSeeBilling = res.data?.data;
  }

  @action.bound
  async dispatchFetchSubScription(id) {
    if (!id) return this.dataSubCription;
    const res = await fetchSubcription(id);
    this.dataSubCription = res.data.data;
  }

  @action.bound
  async dispatchFetchSaveCard() {
    const res = await fetchSaveCard(window.user?.stripeId);
    this.dataSaveCard = res.data?.data;
  }

  @action.bound
  async dispatchCancelPackage() {
    try {
      let url = null;
      const res = await deletePackage(window.user?.subscriptionId);
      const resSignOut = await signOut({});
      if (window.domainMapping?.loginDomain) {
        if (window.domainMapping.loginDomain.includes('local')) {
          url = `http://local.vacc.abivin.vn/sign-in`;
        } else if (!window.location.origin.includes('local')) {
          url = `https://${window.domainMapping.loginDomain}/sign-in`;
        }
        return clearCookiesAndRedirectToLogin(url);
      }
      url = `${window.baseURL?.url}/sign-in`;
      return clearCookiesAndRedirectToLogin(url);
    } catch (err) {
      toastr.error(err?.message);
    }
  }

  @action.bound
  async dispatchAddCard(paymentMethodId) {
    try {
      const params = {
        customerId: window.user?.stripeId,
        paymentMethodId, // Pass the payment method ID
      };
      const res = await addCard(params);
      // Clear the form data upon successful card addition
      this.dispatchSetPaymentMethodsData({
        date: '',
        cardNumber: '',
        securityCode: '',
        cardName: '',
        checked: '',
      });

      // Fetch the updated list of saved cards
      await this.dispatchFetchSaveCard();
      toastr.success(res?.data?.message || 'Card added successfully');
    } catch (err) {
      toastr.error(err?.response?.data?.message || err?.message || 'Failed to add card.');
    }
  }

  @action.bound
  dispatchSetModalPaymentMethodsState(payload) {
    this.modalPaymentMethodsState = payload;
  }

  @action.bound
  async dispatchRemoveCard(id) {
    try {
      const res = await deleteCard(id);
      this.dispatchFetchSaveCard();
      toastr.success(res?.data?.message);
    } catch (err) {
      toastr.error(err?.message);
    }
  }

  @action.bound
  dispatchSetErrorFormDataCard(payload) {
    this.errorFormDataCard = payload;
  }

  @action.bound
  async dispatchSetPaymentMethodsDefault(idCard) {
    try {
      const params = {
        invoice_settings: {
          default_payment_method: idCard,
        },
      };
      const res = await paymentMethodsDefault({ id: window.user?.stripeId, params });
      this.dispatchFetchSaveCard();
      toastr.success(res?.data?.message);
    } catch (err) {
      toastr.error(err?.message);
    }
  }

  @action.bound
  async dispatchUpgrade() {
    try {
      // const paramsAddCard =
      //   this.dataSaveCard.length === 0 ||
      //     this.paymentMethodsData?.checked === 'credit' ||
      //     this.paymentMethodsData?.checked === 'master' ||
      //     this.paymentMethodsData?.checked === 'visa'
      //     ? {
      //       card: {
      //         exp_month: parseInt(this.paymentMethodsData?.date.split('-')[1], 10),
      //         exp_year: parseInt(this.paymentMethodsData?.date.split('-')[0], 10),
      //         number: this.paymentMethodsData?.cardNumber,
      //         cvc: this.paymentMethodsData?.securityCode,
      //         nameOnCard: this.paymentMethodsData?.cardName,
      //       },
      //     }
      //     : {};
      const params = {
        priceId: this.listPackageUpgrade.find(i => i.name === this.modalPaymentMethodsState.package)?.default_price,
        subscriptionId: window.user.subscriptionId,
        customerId: window.user.stripeId,
      };
      const rel = await upgradePackage(params);
      // window.location = `${window.location?.origin}/#!/organizations/organizationList`;
      toastr.success(rel?.data?.message);
      // window.location.reload();
    } catch (err) {
      toastr.error(err.message);
    }
  }

  @action.bound
  async dispatchGetStripeKey() {
    const res = await getStripeKey();
    this.stripeKey = res.data?.data;
  }
}
