import Request from './Request'
import API_URL from './URLS'


export const fetchPackageUpgrade = params => {
    return Request.get(API_URL.PACKAGE_UPGRADE, params)
}

export const fetchSubcription = params => {
    return Request.get(`${API_URL.SUBSCRIPTION}/${params}`)
}

export const deletePackage = params => {
    return Request.delete(`${API_URL.SUBSCRIPTION}/${params}`)
}

export const upgradePackage = params => {
    return Request.post(API_URL.UPGRADE, params)
}

export const fetchSaveCard = params => {
    return Request.get(`${API_URL.PAYMENT_METHODS}/${params}`)
}

export const addCard = params => {
    return Request.post(API_URL.PAYMENT_METHODS, params)
}

export const deleteCard = params => {
    return Request.delete(`${API_URL.PAYMENT_METHODS}/${params}`)
}

export const getStripeKey = params => {
    return Request.get(`${API_URL.STRIPE_PUBLISHABLE_KEY}`, params)
}

export const signOut = params => {
    return Request.post(API_URL.SIGN_OUT, params)
}

export const paymentMethodsDefault = ({ params, id }) => {
    return Request.put(`${API_URL.CUSTOMER_PRICING}/${id}`, params)
}

export const fetchSeeBilling = (params) => {
    return Request.get(`${API_URL.SEE_BILLING}/${params}`)
}