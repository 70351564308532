import makeStyles from '@material-ui/core/styles/makeStyles'; //styles

export var dUseStyles = makeStyles(function (theme) {
  return {
    root: {
      display: 'inline-block'
    },
    datePickerRoot: {
      minWidth: 500,
      // padding: theme.spacing(2),
      backgroundColor: 'rgb(247, 249, 250)'
    },
    dateInfo: {
      height: 40,
      border: '1px solid #ddd',
      padding: theme.spacing(2)
    },
    day: {
      display: 'inline-block',
      padding: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#1B64B0',
        color: '#FFFFFF'
      },
      width: 35,
      textAlign: 'center'
    },
    dayToday: {
      color: '#FFB236 !important'
    },
    dayDisabled: {
      opacity: 0.4,
      pointerEvents: 'none'
    },
    daySelected: {
      backgroundColor: '#1B64B0',
      color: '#FFFFFF'
    },
    bottom: {
      textAlign: 'right',
      backgroundColor: 'rgb(247, 249, 250)',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& button': {
        marginLeft: theme.spacing(1)
      }
    },
    content: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      backgroundColor: '#FFFFFF'
    },
    popover: {
      '& .MuiPopover-paper': {
        overflowY: 'hidden'
      },
      userSelect: 'none'
    }
  };
});