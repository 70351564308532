import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import _ from 'lodash';
export var getFillRate = function getFillRate(route) {
  var deliverDetail = _.get(route, 'deliverDetail');

  var driverInfo = _.get(route, 'driverInfo');

  var setIndexDepot = deliverDetail.map(function (item, index) {
    return _objectSpread(_objectSpread({}, item), {}, {
      index: index
    });
  }).filter(function (item) {
    return item.depotInfo;
  });
  var totalFillRateRoute = [];

  if (setIndexDepot.length === 1) {
    var dataWeightVolumeRoute = deliverDetail.slice(1).reduce(function (acc, item) {
      return {
        totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + item.totalVolume,
        totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + item.totalWeight
      };
    }, {
      totalVolume: 0,
      totalWeight: 0
    });
    totalFillRateRoute = [_objectSpread(_objectSpread({}, dataWeightVolumeRoute), {}, {
      fillRateVolume: Number(_.get(dataWeightVolumeRoute, 'totalVolume')) / Number(_.get(driverInfo, 'capacityVolume')),
      fillRateWeight: Number(_.get(dataWeightVolumeRoute, 'totalWeight')) / Number(_.get(driverInfo, 'capacityWeight')),
      index: 0
    })];
  } else {
    totalFillRateRoute = setIndexDepot.map(function (item, index) {
      if (index === setIndexDepot.length - 1) {
        return {
          totalVolume: 0,
          totalWeight: 0,
          fillRateVolume: 0,
          fillRateWeight: 0,
          index: item === null || item === void 0 ? void 0 : item.index
        };
      } else {
        var indexStart = _.get(setIndexDepot[index], 'index');

        var indexEnd = _.get(setIndexDepot[index + 1], 'index');

        var totalDataStoreByTrip = deliverDetail.slice(indexStart + 1, indexEnd);

        var _dataWeightVolumeRoute = totalDataStoreByTrip.reduce(function (acc, item) {
          return {
            totalVolume: (acc === null || acc === void 0 ? void 0 : acc.totalVolume) + item.totalVolume,
            totalWeight: (acc === null || acc === void 0 ? void 0 : acc.totalWeight) + item.totalWeight
          };
        }, {
          totalVolume: 0,
          totalWeight: 0
        });

        return _objectSpread(_objectSpread({}, _dataWeightVolumeRoute), {}, {
          fillRateVolume: Number(_.get(_dataWeightVolumeRoute, 'totalVolume')) / Number(_.get(driverInfo, 'capacityVolume')),
          fillRateWeight: Number(_.get(_dataWeightVolumeRoute, 'totalWeight')) / Number(_.get(driverInfo, 'capacityWeight')),
          index: item === null || item === void 0 ? void 0 : item.index
        });
      }
    });
  }

  var fillRateDepot = {};
  var fillRateStoreBefore = {};
  var deliverDetailAfterGetFillrate = deliverDetail.map(function (item, index) {
    if (item !== null && item !== void 0 && item.depotInfo) {
      fillRateDepot = _.find(totalFillRateRoute, function (i) {
        return (i === null || i === void 0 ? void 0 : i.index) === index;
      });
      fillRateStoreBefore = _objectSpread({}, fillRateDepot);
      return _objectSpread(_objectSpread({}, item), {}, {
        totalVolume: Number(_.get(fillRateDepot, 'totalVolume')),
        totalWeight: Number(_.get(fillRateDepot, 'totalWeight')),
        fillRateVolume: Number(_.get(fillRateDepot, 'fillRateVolume')),
        fillRateWeight: Number(_.get(fillRateDepot, 'fillRateWeight'))
      });
    }

    fillRateStoreBefore = {
      fillRateVolume: Number(_.get(fillRateStoreBefore, 'fillRateVolume')) - Number(_.get(item, 'totalVolume')) / Number(_.get(driverInfo, 'capacityVolume')),
      fillRateWeight: Number(_.get(fillRateStoreBefore, 'fillRateWeight')) - Number(_.get(item, 'totalWeight')) / Number(_.get(driverInfo, 'capacityWeight'))
    };
    return _objectSpread(_objectSpread({}, item), fillRateStoreBefore);
  });
  return _objectSpread(_objectSpread({}, route), {}, {
    deliverDetail: deliverDetailAfterGetFillrate
  });
};

var distanceBetween2Coordinates = function distanceBetween2Coordinates(long1, lat1, long2, lat2) {
  var R = 6378; // km (change this constant to get miles)

  var d = 0;
  var dLat = (lat2 - lat1) * Math.PI / 180;
  var dLon = (long2 - long1) * Math.PI / 180;
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  d = R * c * 1000;
  return d;
};

export var getLoadingTime = function getLoadingTime(route, shiftStartTime, isPNS, dataOrg) {
  var isUseLoadingTimeAtDepot = _.get(dataOrg, 'algoConfig.enableLoadingTimeAtDepot');

  var isUseLoadingTimeAtCustomer = _.get(dataOrg, 'algoConfig.enableUnloadingTimeAtCustomer');

  var speedVehicle = _.get(route, 'driverInfo.speed') * 1000;

  var deliverDetail = _.get(route, 'deliverDetail');

  var timeBefor = 0;
  var useVolume = false;
  var unloadMinTime = 0;
  var unloadMinVolume = 0;
  var unloadMinWeight = 0;
  var deliverDetailAfterUpdateTime = deliverDetail.map(function (item, index) {
    if (item.depotInfo) {
      useVolume = _.get(item, 'depotInfo.algoConfig.useVolume');
      unloadMinTime = _.get(item, 'depotInfo.algoConfig.unloadMinTime');
      unloadMinVolume = _.get(item, 'depotInfo.algoConfig.unloadMinVolume');
      unloadMinWeight = _.get(item, 'depotInfo.algoConfig.unloadMinWeight');
    }

    if (index === 0) {
      if (shiftStartTime) {
        var shiftStartTimeSplit = shiftStartTime.split(':').map(function (i) {
          return Number(i);
        });
        timeBefor = shiftStartTimeSplit[0] * 60 + shiftStartTimeSplit[1];
      } else {
        var deliverTimeSplit = _.get(item, 'deliverTime').split(':').map(function (i) {
          return Number(i);
        });

        timeBefor = deliverTimeSplit[0] * 60 + deliverTimeSplit[1];
      }

      var maxTime = _.get(item, 'depotInfo.algoConfig.maxTime');

      var minTime = _.get(item, 'depotInfo.algoConfig.minTime');

      var loadingTime = useVolume ? unloadMinTime / unloadMinVolume * _.get(item, 'totalVolume') : unloadMinTime / unloadMinWeight * _.get(item, 'totalWeight');
      var loadingTimeByConfig = !isUseLoadingTimeAtDepot ? loadingTime : _.get(item, 'depotInfo.algoConfig.docProcessingTime', 0) + loadingTime;
      var loadingTimeFinal = loadingTimeByConfig > maxTime ? maxTime : loadingTimeByConfig < minTime ? minTime : loadingTimeByConfig;
      timeBefor = timeBefor + Number(loadingTimeFinal);
      return _objectSpread(_objectSpread({}, item), {}, {
        deliverTime: "".concat(Math.floor((timeBefor - loadingTimeFinal) / 60), ":").concat(Math.ceil(timeBefor - loadingTimeFinal) % 60),
        doneTime: "".concat(Math.floor(timeBefor / 60), ":").concat(Math.ceil(timeBefor) % 60)
      });
    } else {
      var coordinateBefor = _.get(deliverDetail[index - 1], 'depotInfo.coordinate') || _.get(deliverDetail[index - 1], 'storeInfo.coordinate');

      var coordinatePrev = _.get(deliverDetail[index], 'depotInfo.coordinate') || _.get(deliverDetail[index], 'storeInfo.coordinate');

      var distance = distanceBetween2Coordinates(coordinateBefor.longitude, coordinateBefor.latitude, coordinatePrev.longitude, coordinatePrev.latitude);
      var timeVehicleRunBetweenStop = distance * (isPNS ? 1 : 1.3) / speedVehicle;
      timeBefor = timeBefor + Number(timeVehicleRunBetweenStop) * 60;

      if (item.depotInfo) {
        var _maxTime = _.get(item, 'depotInfo.algoConfig.maxTime');

        var _minTime = _.get(item, 'depotInfo.algoConfig.minTime');

        var _loadingTime = useVolume ? unloadMinTime / unloadMinVolume * _.get(item, 'totalVolume') : unloadMinTime / unloadMinWeight * _.get(item, 'totalWeight');

        var _loadingTimeByConfig = !isUseLoadingTimeAtDepot ? _loadingTime : _.get(item, 'depotInfo.algoConfig.docProcessingTime', 0) + _loadingTime;

        var _loadingTimeFinal = _loadingTimeByConfig > _maxTime ? _maxTime : _loadingTimeByConfig < _minTime ? _minTime : _loadingTimeByConfig;

        timeBefor = timeBefor + Number(_loadingTimeFinal);
        return _objectSpread(_objectSpread({}, item), {}, {
          deliverTime: "".concat(Math.floor((timeBefor - _loadingTimeFinal) / 60), ":").concat(Math.ceil(timeBefor - _loadingTimeFinal) % 60),
          doneTime: "".concat(Math.floor(timeBefor / 60), ":").concat(Math.ceil(timeBefor) % 60)
        });
      } else {
        var _maxTime2 = _.get(item, 'storeInfo.algoConfig.maxTime');

        var _minTime2 = _.get(item, 'storeInfo.algoConfig.minTime');

        var _loadingTime2 = useVolume ? unloadMinTime / unloadMinVolume * _.get(item, 'totalVolume') : unloadMinTime / unloadMinWeight * _.get(item, 'totalWeight');

        var _loadingTimeByConfig2 = !isUseLoadingTimeAtCustomer ? _loadingTime2 : _.get(item, 'storeInfo.algoConfig.docProcessingTime', 0) + _loadingTime2;

        var _loadingTimeFinal2 = isUseLoadingTimeAtCustomer ? _loadingTimeByConfig2 : _loadingTimeByConfig2 > _maxTime2 ? _maxTime2 : _loadingTimeByConfig2 < _minTime2 ? _minTime2 : _loadingTimeByConfig2;

        timeBefor = timeBefor + Number(_loadingTimeFinal2);
        return _objectSpread(_objectSpread({}, item), {}, {
          deliverTime: "".concat(Math.floor((timeBefor - _loadingTimeFinal2) / 60), ":").concat(Math.ceil(timeBefor - _loadingTimeFinal2) % 60),
          doneTime: "".concat(Math.floor(timeBefor / 60), ":").concat(Math.ceil(timeBefor) % 60)
        });
      }
    }
  });
  return _objectSpread(_objectSpread({}, route), {}, {
    deliverDetail: deliverDetailAfterUpdateTime
  });
};