import Request from './Request';
import API_URL from './URLS';
export var fetchRoutes = function fetchRoutes(params, options) {
  return Request.post(API_URL.GET_ROUTE_LIST, params, options);
};
export var fetchMissingOrders = function fetchMissingOrders(params, options) {
  return Request.post(API_URL.GET_MISSING_ORDERS, params, options);
};
export var fetchPlanHistoryList = function fetchPlanHistoryList(params, options) {
  return Request.post(API_URL.GET_PLAN_HISTORY_LIST, params, options);
};
export var optimizeRoutes = function optimizeRoutes(params, options) {
  return Request.post(API_URL.OPTIMIZE_ROUTE, params, options);
};
export var getListVehicleOnRoutePlan = function getListVehicleOnRoutePlan(params, options) {
  return Request.post(API_URL.GET_LIST_VEHICLE_ON_ROUTE_PLAN, params, options);
};
export var exportRoutePlanHistory = function exportRoutePlanHistory(params, options) {
  return Request.post(API_URL.EXPORT_ROUTE_PLAN_HISTORY, params, options);
};