var ROLE_BY_ACCOUNT = window.roleByAccount;
var USER = window.user;
export var formatNumberWithCommas = function formatNumberWithCommas(x) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var result = x && x.toFixed && x.toFixed(decimal) || 0;
  return result && result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export var getText = function getText(reason) {
  var msg;

  switch (reason) {
    case 'OVER_WEIGHT':
      msg = "Exceeds Vehicle's Weight capacity";
      break;

    case 'OVER_VOLUME':
      msg = "Exceeds Vehicle's Volume capacity";
      break;

    case 'OVER_ADJACENT':
      msg = "Exceeds Vehicle's Maximum of Cluster";
      break;

    case 'VEHICLE_TYPE_MISMATCHED':
      msg = 'This Vehicle does not match with allowed vehicle type of stop';
      break;

    case 'BEYOND_ADJACENT':
      msg = 'Is not in associated customer group with existing stops in current route of this vehicle';
      break;
  }

  return msg;
};
export var isAdminAccount = function isAdminAccount() {
  return ROLE_BY_ACCOUNT && ROLE_BY_ACCOUNT.NameTable === 'ADMIN';
};
export var havePermissions = function havePermissions(permissionType, moduleName) {
  var _USER$organizationIds, _USER$roleIds;

  if (isAdminAccount() && moduleName === 'ORGANIZATIONS') {
    return true;
  }

  if (ROLE_BY_ACCOUNT === '' && (USER === null || USER === void 0 ? void 0 : (_USER$organizationIds = USER.organizationIds) === null || _USER$organizationIds === void 0 ? void 0 : _USER$organizationIds.length) === 0 && (USER === null || USER === void 0 ? void 0 : (_USER$roleIds = USER.roleIds) === null || _USER$roleIds === void 0 ? void 0 : _USER$roleIds.length) === 0 && moduleName === 'ORGANIZATIONS' && permissionType === 'insert') {
    // allow newly registered user to create org
    return true;
  }

  return ROLE_BY_ACCOUNT && ROLE_BY_ACCOUNT[permissionType] && ROLE_BY_ACCOUNT[permissionType].includes(moduleName);
};
export var havePermissionsV2 = function havePermissionsV2(moduleName, permissionName) {
  var foundModule = ROLE_BY_ACCOUNT.permissionsV2.find(function (module) {
    return module.module === moduleName;
  });
  var havePermission;
  var mainPermissions = ['create', 'read', 'update', 'delete', 'import', 'export'];

  if (mainPermissions.includes(permissionName)) {
    var _foundModule$mainPerm;

    havePermission = (foundModule === null || foundModule === void 0 ? void 0 : foundModule.mainPermissions) && foundModule.mainPermissions[permissionName] && ((_foundModule$mainPerm = foundModule.mainPermissions[permissionName]) === null || _foundModule$mainPerm === void 0 ? void 0 : _foundModule$mainPerm.isAllowed) || false;
  } else {
    havePermission = (foundModule === null || foundModule === void 0 ? void 0 : foundModule.otherPermissions) && foundModule.otherPermissions[permissionName] || false;
  }

  return havePermission;
};
export var getColorIconByRoutePlanningStatus = function getColorIconByRoutePlanningStatus(planningStatus, mainColor) {
  var colorIcon;

  switch (planningStatus) {
    case 'PLANNED':
    case 'UNLOCK':
    case 'LOCKED':
      colorIcon = mainColor;
      break;

    case 'FINALIZED':
      colorIcon = '#ffffff';
      break;

    default:
      colorIcon = '#ffffff';
      break;
  }

  return colorIcon;
};
export var getBackgroundColorByRoutePlanningStatus = function getBackgroundColorByRoutePlanningStatus(planningStatus, mainColor) {
  var backgroundColor;

  switch (planningStatus) {
    case 'PLANNED':
    case 'UNLOCK':
    case 'LOCKED':
      backgroundColor = '#ffffff';
      break;

    case 'FINALIZED':
      backgroundColor = mainColor;
      break;

    default:
      backgroundColor = '#ffffff';
      break;
  }

  return backgroundColor;
};