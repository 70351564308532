import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { react2angular } from 'react2angular';
import CustomButton from './common/buttons/CustomButton';
import TextInput from './common/textInputField/TextInput'; // import OrganizationItem from "./pages/Organizations/Organization.Item";

import UserItem from './pages/Organizations/User.Item'; // import GroupItem from "./pages/Organizations/Group.Item";

import SplitDelivery from './pages/Orders/SplitDelivery/SplitDelivery';
import CreateInventory from './pages/product/inventory/create/createInventory';
import updateProductJs from './pages/product/product/update/updateProduct';
import CustomFieldsPage from './pages/Settings/CustomFields/listCustomFields';
import includes from './providers/includes';
import ImportProfile from './pages/Settings/CustomImport/ImportProfile';
import SpeedDialNew from './components/SpeedDial/SpeedDialNew';
import UpgradePlan from '@components/UpgradePlan';
import { configure } from 'mobx';
configure({
  enforceActions: 'never'
});
angular.module(ApplicationConfiguration.applicationModuleName).component('customButton', react2angular(CustomButton, ['type', 'text', 'handleClick', 'disabled', 'showIcon', 'itemId'])).component('speedDialNew', react2angular(SpeedDialNew, ['actions', 'handleCreate', 'tooltipTitle', 'snackBarOpen', 'handleClose'])) // .component(
//   "organizationItem",
//   react2angular(OrganizationItem, [
//     "open",
//     "handleClose",
//     "itemId",
//     "parentId",
//     "dialogTitle",
//     "cookieAbi",
//     "authentication",
//   ])
// )
// .component(
//   "groupItem",
//   react2angular(GroupItem, ["open", "handleClose", "itemId", "parentId", "dialogTitle", "toastr"])
// )
.component('userItem', react2angular(UserItem, ['open', 'handleClose', 'itemId', 'parentId', 'tab', 'dialogTitle'])).component('splitDelivery', react2angular(SplitDelivery, ['open', 'handleClose', 'dialogTitle', 'dialogHelperText', 'orderId'])).component('customFieldsPage', react2angular(CustomFieldsPage, ['handleClose', 'open', 'toastr', 'handleUpdate', 'isUpdating'])).component('importProfile', react2angular(ImportProfile, [])).component('upgradePlan', react2angular(UpgradePlan, [])).component('textInput', react2angular(TextInput, ['name', 'type', 'label', 'value', 'onChange', 'disabled', 'required', 'options', 'defaultValue', 'multiple'])).component('createInventory', react2angular(CreateInventory, ['open', 'handleClose', 'rootOrg'])).component('updateProductJs', react2angular(updateProductJs, ['open', 'productUpdateId', 'handleClose', 'rootOrg', 'orgCategories'])).component('includeReact', react2angular(includes, ['component', 'reactProps']));