import Cookies from 'universal-cookie';
import { havePermissions, havePermissionsV2 } from './helpers';
var cookies = new Cookies();
var rootOrgCookies = cookies.get('rootOrg');
export var MOVE_ORDER_TYPES = Object.freeze({
  FROM_MISSING: 3,
  ON_ONE_ROUTE: 1,
  BETWEEN_TWO_ROUTE: 2,
  UPDATE_STORE_LOCATION: 4,
  REMOVE_STORE_FROM_ROUTE: 5
});
export var dateFormat = 'DD-MM-YYYY';
export var LARGE_SCREEN_WIDTH = 1920;
export var TEMP_MAPPING = Object.freeze({
  A: 'Ambient',
  F: 'Frozen',
  C: 'Chilled'
});
export var DEFAULT_UI_TIMELINE_HEIGHT = Object.freeze({
  plan: 75,
  execution: 75
});
export var DEFAULT_UI_HEIGHT_PER_ROUTE = Object.freeze({
  plan: 75,
  execution: 75
});
export var ROUTE_COLORS = ['#F64090', '#4B5AD8', '#F9A825', '#3ABE2F', '#1565C0', '#FD6969', '#43A047', '#8E1EFF', '#558B2F', '#D61FDA', '#19D0AF', '#FFCC80', '#6A1B9A', '#E11D6F', '#0277BD', '#80CBC4', '#AD1457', '#26C6DA', '#00897B', '#B39DDB', '#EC407A', '#8E24AA', '#FFB300', '#1E88E5', '#E6EE9C', '#3949AB', '#00ACC1', '#D81B60', '#42A5F5', '#E53935', '#EF9A9A', '#5E35B1', '#81D4FA', '#9CCC65', '#19D0AF', '#C0CA33', '#FB8C00', '#7CB342', '#AB47BC', '#A5D6A7', '#F4511E', '#FFCA28', '#26A69A', '#FFEE58', '#FF7043', '#1565C0', '#FFF59D', '#FFAB91', '#9E9D24', '#C62828', '#9FA8DA', '#C5E1A5', '#80DEEA', '#4527A0', '#90CAF9', '#00838F', '#D84315', '#00695C', '#F48FB1', '#CE93D8'];
export var PRODUCT_HEADER = Object.freeze({
  en: {
    PRODUCT_CODE: 'PRODUCT_CODE',
    PRODUCT_NAME: 'PRODUCT_NAME',
    WEIGHT: 'WEIGHT',
    CASES: 'CASES',
    ITEMS: 'ITEMS'
  },
  vi: {
    PRODUCT_CODE: 'MÃ SẢN PHẨM',
    PRODUCT_NAME: 'TÊN_SẢN_PHẨM',
    WEIGHT: 'TRỌNG_LƯỢNG',
    CASES: 'THÙNG',
    ITEMS: 'LẺ'
  }
});
export var FILTER_SELECT_OPTIONS = [{
  name: 'Planning Status',
  value: 'PLANNING_STATUS'
}, {
  name: 'Vehicle Code',
  value: 'VEHICLE_CODE'
}, {
  name: 'Sync Status',
  value: 'SYNC_STATUS'
}];
export var times = ['1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00'];
export var MARKER_TYPES = {
  STORE: 'store',
  DEPOT: 'depot',
  FACTORY: 'factory'
};
export var initialUpdateCustomerLocationState = Object.freeze({
  open: false,
  coordinate: {},
  targetStoreIndex: '',
  target_route_id: '',
  store_id: ''
});
export var initialReportModalState = Object.freeze({
  open: false,
  type: '',
  currency: '',
  isLongHaul: '',
  content: null
});
export var initialSideBarState = Object.freeze({
  open: false,
  content: null,
  title: '',
  type: '',
  allRoutesInfo: null
});
export var initialConfirmModalState = Object.freeze({
  open: false,
  type: '',
  content: '',
  routeIds: [],
  from: []
});
export var initialNotificationModalState = Object.freeze({
  open: false,
  title: '',
  type: '',
  text: '',
  cancelType: ''
});
export var initialMissingOrdersModalState = Object.freeze({
  open: false
});
export var initialFilterManagerModalState = Object.freeze({
  open: false
});
export var initialRemoveShiftModalState = Object.freeze({
  open: false,
  removeShifts: [],
  noticeShift: []
});
export var initialSearchState = Object.freeze({
  textInput: '',
  currentRoutes: [],
  worker: null,
  showSearchInput: true,
  searchBoxModalState: {
    open: true,
    showSearchInput: true,
    showList: false
  }
});
export var initialRefreshBarState = Object.freeze({
  open: false,
  messageInfo: null
});
export var matchingVehicleDrivers = Object.freeze({
  truck: ['truck', 'semi-truck'],
  bike: ['bike'],
  'semi-truck': ['semi-truck']
});
export var initialUpdateMessageModalState = Object.freeze({
  open: false,
  message: '',
  variant: '',
  key: null
});
export var initialErpModalState = Object.freeze({
  formData: {
    address: '',
    port: '22',
    username: '',
    pwd: ''
  },
  errors: null,
  pullDisabled: true
});
export var initialOptimizeProgressModalState = Object.freeze({
  open: false,
  text: '',
  progress: 0
});
export var initialRectangleConstraint = Object.freeze({
  latLngMarker: null,
  marker: null,
  rectangle: null,
  bounds: null
});
export var initialOptimizeRouteModalState = Object.freeze({
  open: false,
  cutoff: 1,
  enabledCutoff: false,
  disabled: false
});
export var initialReplayRouteState = Object.freeze({
  REPLAY: false,
  PAUSE: false,
  PLAY: false,
  STOP: true
});
export var downloadTypeMapping = Object.freeze({
  PACKING: 'packing',
  PICKING: 'picking',
  PRODUCTS: 'product',
  ORDERS: 'order',
  ALL_PRODUCTS: 'allProducts',
  ALL_ORDERS: 'allOrders'
});
export var exportURLsMapping = Object.freeze({
  packing: '/router/packing-list/export',
  picking: '/router/picking-list/export',
  product: '/products/packing-list/export',
  order: '/products/packing-list/export',
  allProducts: '/products/packing-list/export',
  allOrders: '/products/packing-list/export'
});
export var TIMELINE_MODE = {
  PLAN: 'plan',
  ACTUAL: 'execution'
};
export var ROW_LABELS = {
  order: 'Order',
  vehicle: 'Vehicle',
  vehicleCode: 'Vehicle',
  customerCode: 'Customer',
  orderCode: 'Order',
  customer: 'Customer',
  licensePlate: 'License plate'
};
export var ORDER_STATUS_MAPPING = {
  '-3': 'LOCKED',
  '-2': 'PENDING',
  '-1': 'REJECTED',
  '1': 'OPEN',
  '2': 'PICKING_AND_PACKING',
  '3': 'PICKED_AND_PACKED',
  '4': 'SHIPPING',
  '5': 'SHIPPED',
  '6': 'CANCELLED'
};
export var FULFILMENT_STATUS_MAPPING = {
  '1': 'NOT_FULFILLED',
  '2': 'FULFILLED',
  '3': 'PARTIALLY_FULFILLED',
  '4': 'UNFULFILLED'
};
export var INITIAL_MAP_COORDINATE = {
  lat: 21.04002195878051,
  lng: 105.81293843696434
};
export var ZOOM_LEVEL_OPTIONS = [{
  name: '1X - 100px/30min',
  value: 1
}, {
  name: '1.25X - 125px/30min',
  value: 1.25
}, {
  name: '1.5X - 75px/15min',
  value: 1.5
}, {
  name: '1.75X - 87.5px/15min',
  value: 1.75
}, {
  name: '2X - 100px/15min',
  value: 2
}];
export var ROUTE_STATUS = {
  LOCKED: 'LOCKED',
  FINALIZED: 'FINALIZED',
  COMPLETED: 'COMPLETED',
  PLANNED: 'PLANNED'
};