const API_URL = {
  //SAMPLEDATA
  CREATE_SAMPLE_DATA: '/sample/create',
  DELETE_SAMPLE_DATA: '/sample/delete',
  // ORGANIZATION
  GET_ORGANIZATION_LIST: '/organizations/list',
  GET_BRANCH_LIST: '/router/organization/list',
  READ_ORG: '/organizations/read',
  CREATE_ORG: '/organizations/create',
  UPDATE_ORG: '/organizations/update',
  REMOVE_ORG: '/organizations/delete',
  GET_ROOT_ORG: '/organizations/root-org',
  GET_ORG_CATEGORY: '/organizations/categories',
  GET_PARENT_BRANCH: '/organizations/parentBranch',
  READ_ORG_CATEGORIES: '/organizations/read-categories',
  GET_BRANCH_CONFIG: '/organizations/branchConfig',
  GET_ORGANIZATION_TREE: '/core/organization-tree',
  GET_API_KEY: '/organizations/render-apikey',
  GET_SHIPPER_LIST_BY_DEPOT: '/organizations/listShippersOfDepot',
  CHECK_OPTIMIZE_DONE: '/organizations/route/checkAlgoProgressFinished',
  EXPORT_ORGANIZATIONS: '/organizations/exports',

  GET_ORG_ROLE_LIST: '/roles/list',
  READ_ORG_ROLE: '/roles/read',
  CREATE_ORG_ROLE: '/roles/create',
  UPDATE_ORG_ROLE: '/roles/update',
  DELETE_ORG_ROLE: '/roles/delete',

  // COMMON
  UPDATE_IMG_URL: 'uploads/img',

  // USER
  GET_USER_LIST: '/users/list',
  GET_ROLE_NAME_LIST: '/roles/listRolesName',
  CREATE_USER: '/users/create',
  READ_USER: '/users/read',
  UPDATE_USER: '/users/update',
  DELETE_USER: '/users/delete',
  RESET_PASSWORD_USER: '/users/auth/reset',

  // PRODUCT
  GET_SKU_LIST: '/products/list',
  CREATE_PRODUCT: '/products/inventory/create',
  CREATE_PRODUCT_REACT: '/products/inventory/created',
  GET_PRODUCT_BY_ID: 'products',
  UPDATE_PRODUCT: '/products/update',
  DELETE_PRODUCT: '/products/delete',
  GET_PRODUCT_INVENTORY_BY_DATE: '/products/inventory/list/is-month',
  UPDATE_INVENTORY_PRODUCT: '/products/inventory/update',
  DELETE_ALL_PRODUCTS: '/products/delete-all',

  // PRODUCT CATEGORY
  GET_PRODUCT_CATEGORY_LIST: '/products/categories/list',
  CREATE_PRODUCT_CATEGORY: '/products/categories/create',
  READ_PRODUCT_CATEGORY: '/products/categories/read',
  UPDATE_PRODUCT_CATEGORY: '/products/categories/update',
  DELETE_PRODUCT_CATEGORY: '/products/categories/delete',
  GET_PRODUCTS_BY_CATEGORIES: '/products/categories/list-product',

  // CUSTOMER
  CUSTOMER: '/customers',
  GET_CUSTOMER_CONTACT_LIST: '/customers/list',
  GET_CITY_LIST: '/customers/listCity',
  DELETE_CUSTOMER: '/customers/delete',
  GET_UNLOADING_TYPE_LIST: 'customers/unloadingType/list',
  PULL_CUSTOMER_THIRD_PARTY: '/data/pullData',
  CANCEL_PULLING_STORE: '/data/cancel-pulling-store',
  GET_PULLING_RESULT: 'data/get_pulling_result',

  // CUSTOMER GROUP
  GET_CUSTOMER_GROUP_LIST: '/customers/group/list',
  DELETE_CUSTOMER_GROUP: '/customers/group/delete',

  // ORDER
  ORDER: '/orders',
  GET_ORDER_LIST: '/orders/list',
  DELETE_ORDER: '/orders/delete',
  GET_ORDER_FILTER_VALUE: '/orders/filter-values',
  GET_LIST_ORDER_MAPVIEW: '/orders/list-order-mapview',
  GET_UNPLANNED_ORDERS: '/orders/unassigned',
  GET_LTL_ORDERS: '/router/ltl-orders',
  GET_NUMBER_OF_ORDERS_TO_OPTIMIZE: '/orders/list-optimize-input',
  GET_CHARGE_FEE_DEPOT: '/charge-request/list',

  // ORDER BULK ACTION
  UPDATE_MULTIPLE_ORDER: '/orders/update-multiple',

  // VEHICLE
  GET_VEHICLE_LIST: '/vehicle/list',
  UPDATE_VEHICLE: '/vehicle/create',
  DELETE_VEHICLE: '/vehicle/delete',

  // VEHICLE TYPE
  GET_VEHICLE_TYPE_LIST: '/vehicle/type/list',
  DELETE_VEHICLE_TYPE: '/vehicle/type/delete',

  // DRIVER
  GET_DRIVER_LIST: '/users/driver-list',
  UPDATE_DRIVER: '/users/update',

  // TASKS
  GET_TASK_LIST: '/tasks/list',
  DELETE_TASK: '/tasks/delete',
  GET_ACTION_LIST: '/tasks/actions/list',
  GET_TASK_CUSTOMER_LIST: '/tasks/filter',
  DELETE_TASK_ACTION: '/tasks/actions/delete',

  // TRANSPORT
  GET_TRANSPORT_SERVICE_LIST: '/transport-service/list',
  TOGGLE_ACTIVE: '/transport-service/toggle-active',
  DELETE_TRANSPORT_SERVICE: '/transport-service/remove',

  // CODE
  AUTO_CREATE_CODE: '/core/creat/code',

  // CUSTOM FIELD
  GET_CUSTOM_FIELD_LIST: '/custom-fields/list',
  CREATE_CUSTOM_FIELD: '/custom-fields/create',
  READ_CUSTOM_FIELD_LIST: '/custom-fields/read',
  UPDATE_CUSTOM_FIELD_LIST: '/custom-fields/update',
  REMOVE_CUSTOM_FIELD_LIST: '/custom-fields/remove',

  GET_USERS_BY_GROUP: '/users/list-by-group',

  // MAPPING PROFILE
  CREATE_MAPPING_PROFILE: '/mapping-profile/create',
  GET_MAPPING_PROFILES: '/mapping-profile/list',
  UPDATE_MAPPING_PROFILE: '/mapping-profile/update',
  DELETE_MAPPING_PROFILES: '/mapping-profile/delete',

  // WEB CONFIG
  UPDATE_WEB_CONFIG: '/webconfig/update',
  GET_V2_CONFIGS: '/roles/v2/config',
  GET_APPROVAL_LIST: '/roles/listApproval',

  // IMPORT HISTORY
  IMPORT_HISTORY: '/import/list',
  GET_SIGNED_URL: '/file/get-signed-url',

  // INTEGRATION
  GET_INTEGRATION_LIST: '/integration/list',
  PULL_INTEGRATION_DATA: '/data/pullData',
  GET_PULLING_SYNC_DATA: '/data/get_pulling_result',
  CHECK_PULLING_SYNC_DATA: '/data/check-pulling-data',

  // LOCATION
  LOCATION_LIST: '/locations/list',
  UPDATE_RESTRICT_AREA: '/organizations/update-restricted-area',

  // ROUTE - ORDER
  UPDATE_SHIP_TO_ADDRESS: 'router/update_shipto',
  GET_ROUTE_LIST: '/router/list',
  CHANGE_DRIVER: '/router/assign-driver',
  GET_MISSING_ORDERS: '/router/checkMissingOrders',
  MOVE_ORDER: '/router/move_order',
  OPTIMIZE_ROUTE: '/router/optimize',
  GET_OPTIMIZE_INPUT: '/router/get-optimize-input',
  LOCK_ROUTE: '/router/lockRoute',
  UNLOCK_ROUTE: '/router/unlockRoute',
  FINALIZE_ROUTE: '/router/finalize',
  CANCEL_OPTIMIZATION: '/router/cancel-optimization',
  MOVE_STOP_AND_TRIP: '/router/move_stop_and_trip/',
  REMOVE_STOP_AND_TRIP: '/router/remove_stop/',
  GET_LIST_BY_ROUTES: '/tasks/list-by-route',
  GET_PLAN_HISTORY_LIST: 'router/plan-history/list',
  GET_LIST_VEHICLE_ON_ROUTE_PLAN: '/router/plan/list-vehicle',
  SPLIT_ORDER: '/router/splitOrder',
  MOVE_LTL_TO_MISSING: '/orders/ltl-orders/move-missing',
  MOVE_MISSING_TO_LTL: '/orders/missing-orders/move-ltl',
  EXPORT_MISSING_ORDER: '/orders/missing-export',
  CHECK_THREE_LOADING: 'router/getRouteById',

  //VEHICLE ROUTE PLAN LIST
  VEHICLE_HISTORY_LIST: '/vehicle/history/list',

  // EXPORT PICKING PACKING
  EXPORT_PACKING_SINGLE_ROUTE: '/router/packing-list/export',
  EXPORT_PACKING_ALL_ROUTE: '/router/packing-list-all/export',
  EXPORT_PICKING_SINGLE_ROUTE: '/router/picking-list/export',
  EXPORT_PICKING_ALL_ROUTE: '/router/picking-list-all/export',
  EXPORT_DELIVERY_NOTE: '/orders/delivery-note/export',

  // UPLOAD IMG
  UPLOAD_IMG: '/uploads/img',

  EXPORT_ROUTE_PLAN_HISTORY: 'router/plan-history/export',

  GET_ROUTE_DETAIL: '/router/plan/read',
  SAVE_EDITTED_ROUTES: '/router/save-edit-mode',

  // PRICING

  PACKAGE_UPGRADE: `${APIHOST}/api/v1/products`,
  SUBSCRIPTION: `${APIHOST}/api/v1/subscription`,
  PAYMENT_METHODS: `${APIHOST}/api/v1/payment-method`,
  STRIPE_PUBLISHABLE_KEY: `${APIHOST}/api/v1/stripe-key`,
  SIGN_OUT: '/auth/signout/',
  CUSTOMER_PRICING: `/api/v1/customer`,
  SEE_BILLING: '/api/v1/invoice',
  UPGRADE: '/api/v1/upgradePlan',
  CREATE_SAMPLE_DATA: '/sample/direction',
  DELETE_SAMPLE_DATA: '/sample/delete',
};

export default API_URL;
