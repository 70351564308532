import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
export var useStyles = makeStyles(function (theme) {
  return {
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120
    },
    formControlLabel: {
      marginTop: theme.spacing(1)
    },
    latLngContainer: {
      marginTop: theme.spacing(1)
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2)
    },
    label: {
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    labelIt: {
      paddingTop: "2px",
      marginBottom: "4px",
      "font-size": "11px",
      border: 0
    },
    labelIt2: {
      "font-size": "11px"
    },
    baseInput: {
      width: "52px",
      "& input": {
        textAlign: "right"
      }
    },
    error: {
      color: "#f44336"
    },
    inputRoot: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    inputRoot21: {
      // marginBottom: theme.spacing(2),
      marginTop: "11px",
      height: "48px",
      width: "100%"
    },
    inputRoot22: {
      // marginBottom: theme.spacing(2),
      marginTop: "5px",
      height: "48px",
      width: "100%"
    },
    inputRoot2Radio: {
      width: "100%",
      marginTop: "8px",
      marginBottom: theme.spacing(1),
      height: "44px",
      whiteSpace: "nowrap"
    },
    property: {
      width: "90%",
      marginTop: "0px"
    },
    propertyTime: {
      marginTop: "0px"
    },
    iconAdd: {
      marginLeft: "630px",
      marginTop: "20px",
      marginBottom: theme.spacing(2),
      cursor: "pointer",
      color: theme.palette.text.secondary
    },
    propertyTitle: {
      borderBottom: "ridge",
      display: "flex",
      marginBottom: "25px"
    },
    iconDelete: {
      height: "60px",
      cursor: "pointer",
      color: theme.palette.text.secondary
    },
    banner: {
      backgroundColor: "#FFFFFF",
      color: "#414142"
    },
    textLotNumber: {
      fontSize: "15px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    totalQuantityLotNumber: {
      marginLeft: "630px",
      marginTop: "20px",
      fontSize: "15px",
      marginBottom: theme.spacing(2),
      color: theme.palette.text.secondary
    },
    radio: {
      display: "-webkit-box",
      "& label": {
        width: "141px",
        marginLeft: "0px",
        marginBottom: "0px",
        marginRight: "5px",
        "& span": {
          fontSize: "inherit",
          paddingLeft: "0px"
        }
      }
    },
    dialogPaper: {
      minHeight: "85vh",
      maxHeight: "85vh"
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700]
      }
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  };
});