var rootOrganizationOnLoad = window.rootOrganizationOnLoad;
var APIHOST = window.APIHOST; // this function is copied from stack over flow

function getCookie(name) {
  var value = "; ".concat(document.cookie);
  var parts = value.split("; ".concat(name, "="));
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export var addRootOrg = function addRootOrg(config) {
  config.headers['Root-Org-ID'] = rootOrganizationOnLoad._id;

  if (!/^http/.test(config.url)) {
    if (!/^\//.test(config.url)) {
      config.url = '/' + config.url;
    }

    config.url = APIHOST + config.url;
  }

  return config;
};
export var addToken = function addToken(config) {
  var jwtToken = getCookie('jwtToken');
  config.headers['X-Access-Token'] = jwtToken;
  return config;
};