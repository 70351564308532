import Request from './Request';
import API_URL from './URLS';
export var fetchCustomerList = function fetchCustomerList(params, options) {
  return Request.post(API_URL.GET_CUSTOMER_CONTACT_LIST, params, options);
};
export var deleteCustomers = function deleteCustomers(params, options) {
  return Request.post(API_URL.DELETE_CUSTOMER, params, options);
};
export var getCityList = function getCityList(params, options) {
  return Request.post(API_URL.GET_CITY_LIST, params, options);
};
export var getUnloadingTypeList = function getUnloadingTypeList() {
  return Request.get(API_URL.GET_UNLOADING_TYPE_LIST);
};
export var getCustomerGroupList = function getCustomerGroupList(params, options) {
  return Request.post(API_URL.GET_CUSTOMER_GROUP_LIST, params, options);
};
export var pullCustomerFromErpOrFTP = function pullCustomerFromErpOrFTP(params, options) {
  return Request.post(API_URL.PULL_CUSTOMER_THIRD_PARTY, params, options);
};
export var cancelPullCustomerFromErpOrFTP = function cancelPullCustomerFromErpOrFTP(params, options) {
  return Request.post(API_URL.CANCEL_PULLING_STORE, params, options);
};
export var getCustomer = function getCustomer(params) {
  return Request.get("".concat(API_URL.CUSTOMER, "/").concat(params));
};