import _ from 'lodash';
export var isOneStopRoute = function isOneStopRoute(_ref) {
  var source = _ref.source,
      shifts = _ref.shifts;
  if (source.length > 1) return false;

  var sourceShift = _.find(shifts, {
    _id: _.get(_.head(source), 'routeId')
  });

  return _.get(sourceShift, 'deliverDetail', []).filter(function (i) {
    return i === null || i === void 0 ? void 0 : i.storeInfo;
  }).length == 1;
};