/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/core/styles';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import { PRICING_PLANS, PLANS, LOCAL_STORAGES } from '@constants/AppConfig';
import moment from 'moment';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PackageItem from './PackageItem';
import ContentUpgradeItem from './ContentUpgradeItem';
import { PACKAGE_ITEM_LIST, CONTENT_UPGRADE_ITEM_LIST, UPGRADE_BUTTON } from './constants';
import { useStyles } from './style';
import { useLocalStorage } from 'react-use';
// import PaymentMethods from '../PaymentMethods'

const TRIAL_DAYS = 7;

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: '#2F80ED',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // '&:hover': {
    //   backgroundColor: '#0069d9',
    //   borderColor: '#0062cc',
    //   boxShadow: 'none',
    // },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const UpgradePlan = ({ UserStore, PricingStore, OrganizationStore, $state }) => {
  const [showDeleteSampleDataButton, setShowDeleteSampleDataButton, deleteShowDeleteSampleDataButton] = useLocalStorage(
    LOCAL_STORAGES.SHOW_DELETE_SAMPLE_DATA,
    false,
  );
  const { dispatchOpenModalUpgrade, openModalUpgrade } = UserStore;
  const {
    dispatchFetchListPackage,
    dispatchSetModalPaymentMethodsState,
    modalPaymentMethodsState,
    dataSubCription,
    dispatchFetchSubScription,
    listPackageUpgrade,
  } = PricingStore;
  const { isShowDeleteSampleButton, dispatchDeleteSampleData } = OrganizationStore;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { userInfo } = UserStore;
  const userPackage = _.get(userInfo, 'package', null);
  const userPackageDetail = _.find(PRICING_PLANS, { key: userPackage });
  const status = _.get(dataSubCription, 'status');

  const endDate =
    status === 'active'
      ? _.get(dataSubCription, 'currentPeriodEnd', moment().toISOString())
      : _.get(dataSubCription, 'trialEnd', moment().toISOString());
  const startDate = new Date();

  const dayLefts = Math.max(0, moment(endDate).diff(startDate, 'days'));

  const handleClickOpen = () => {
    dispatchOpenModalUpgrade(true);
  };

  const handleClose = () => {
    dispatchOpenModalUpgrade(false);
  };

  useEffect(() => {
    if (openModalUpgrade) {
      dispatchFetchListPackage();
    }
  }, [openModalUpgrade]);

  useEffect(() => {
    dispatchFetchSubScription(window.user.subscriptionId);
  }, [window.user.subscriptionId]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {dayLefts >= 0 && userPackageDetail !== PLANS.ENTERPRISE && userPackage && (
        <>
          {userInfo.hasLoginBefore === false && (isShowDeleteSampleButton || showDeleteSampleDataButton) && (
            <Button
              id="delete-sample-button"
              variant="contained"
              startIcon={<DeleteIcon />}
              style={{ color: '#ffffff', backgroundColor: '#DD4B39' }}
              onClick={() => {
                $state.go('organizations-index', { tab: 'organizationList' });
                dispatchDeleteSampleData();
                deleteShowDeleteSampleDataButton();
              }}>
              Delete Sample Data
            </Button>
          )}
          <p
            style={{
              marginTop: 12,
              padding: '4px 12px',
              color: '#F98313',
              fontWeight: 500,
              height: 28,
            }}>
            {status === 'trialing' ? `${dayLefts} Day${dayLefts > 1 ? 's' : ''} Left` : ''}
          </p>
          <BootstrapButton
            id="user-package-button"
            variant="contained"
            color="primary"
            startIcon={<StarsOutlinedIcon />}
            onClick={handleClickOpen}>
            {userPackageDetail?.value}
          </BootstrapButton>
        </>
      )}
      <Dialog
        className={classes.dialog}
        open={openModalUpgrade && window?.user?.stripeId}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          <Grid container xs={12} spacing={2}>
            <Grid className={classes.upgrade} item xs={4}>
              <Typography variant="h4" component={'div'} gutterBottom>
                Upgrade Plans
              </Typography>
            </Grid>
            {PACKAGE_ITEM_LIST.map((item, index) => {
              return (
                <Grid item xs={2} key={index}>
                  <Typography align="center" gutterBottom>
                    <PackageItem {...item} />
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content} dividers>
          {CONTENT_UPGRADE_ITEM_LIST.map((item, index) => {
            return <ContentUpgradeItem {...item} key={index} />;
          })}
        </DialogContent>
        <DialogActions className={classes.padding}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}></Grid>
            {UPGRADE_BUTTON.map((item, index) => {
              return (
                <Grid className={classes.buttonUpgrade} item xs={2} key={index}>
                  <Button
                    disabled={item.disable}
                    onClick={e => {
                      handleClose();
                      dispatchSetModalPaymentMethodsState({
                        package: item.key,
                        cost: item.cost,
                      });
                      window.location = `${window.location?.origin}/#!/upgrade-plan`;
                    }}
                    variant="contained"
                    color="primary">
                    {item.content}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogActions>
      </Dialog>
      {/* <PaymentMethods/> */}
    </div>
  );
};

export default inject('UserStore', 'PricingStore', 'OrganizationStore')(observer(UpgradePlan));
