// Main
export var brandColor = '#1B64B0';
export var brandHoverColor = '#004F98';
export var accentColor = '#FFB236'; // Background

export var webBackground = '#F4F7FA';
export var activeBackground = '#175391';
export var hoverBackground = '#C7E6FF';
export var refreshBackground = '#5c7fe0';
export var refreshBackgroundHover = '#5C91E2';
export var backgroundSuccess = '#A2F5CB';
export var backgroundError = '#FFF5F5';
export var backgroundWarning = '#FFEACB';
export var backgroundInfo = '#E0F2FF';
export var toolbarBackground = '#DCEFFD'; // Text Color

export var hyperlinkText = '#2196F3';
export var whiteText = '#FFFFFF';
export var darkText = '#414142';
export var lightText = '#919699';
export var disabledText = '#7b7d7e';
export var textError = '#FF6D6D';
export var textWarning = '#F29C11';
export var textInfo = '#1A89FD';
export var textSuccess = '#0B834A'; // Route Colors

export var Route1 = '#F64090';
export var Route2 = '#FD6969';
export var Route3 = '#F9A825';
export var Route4 = '#3ABE2F';
export var Route5 = '#19D0AF';
export var Route6 = '#1565C0';
export var Route7 = '#4B5AD8';
export var Route8 = '#8E1EFF';
export var Route9 = '#D61FDA';
export var Route10 = '#E11D6F';
export var Route11 = '#AD1457';
export var Route12 = '#80CBC4';
export var Route13 = '#FFCC80';
export var Route14 = '#6A1B9A';
export var Route15 = '#0277BD';
export var Route16 = '#8E24AA';
export var Route17 = '#26C6DA';
export var Route18 = '#00897B';
export var Route19 = '#B39DDB';
export var Route20 = '#EC407A';
export var Route21 = '#558B2F';
export var Route22 = '#1E88E5';
export var Route23 = '#AB47BC';
export var Route24 = '#3949AB';
export var Route25 = '#00ACC1';
export var Route26 = '#5E35B1';
export var Route27 = '#43A047';
export var Route28 = '#D81B60';
export var Route29 = '#42A5F5';
export var Route30 = '#E53935';
export var Route31 = '#EF9A9A';
export var Route32 = '#FFB300';
export var Route33 = '#81D4FA';
export var Route34 = '#9CCC65';
export var Route35 = '#19D0AF';
export var Route36 = '#C0CA33';
export var Route37 = '#FB8C00';
export var Route38 = '#7CB342';
export var Route39 = '#E6EE9C';
export var Route40 = '#A5D6A7';
export var Route41 = '#F4511E';
export var Route42 = '#FFCA28';
export var Route43 = '#26A69A';
export var Route44 = '#FFEE58';
export var Route45 = '#FF7043';
export var Route46 = '#C62828';
export var Route47 = '#FFAB91';
export var Route48 = '#9E9D24';
export var Route49 = '#FFF59D';
export var Route50 = '#1565C0';
export var Route51 = '#9FA8DA';
export var Route52 = '#C5E1A5';
export var Route53 = '#80DEEA';
export var Route54 = '#4527A0';
export var Route55 = '#90CAF9';
export var Route56 = '#00838F';
export var Route57 = '#D84315';
export var Route58 = '#00695C';
export var Route59 = '#F48FB1';
export var Route60 = '#CE93D8';
export var RouteColors = {
  Route1: '#F64090',
  Route2: '#4B5AD8',
  Route3: '#F9A825',
  Route4: '#3ABE2F',
  Route5: '#1565C0',
  Route6: '#FD6969',
  Route7: '#43A047',
  Route8: '#8E1EFF',
  Route9: '#558B2F',
  Route10: '#D61FDA',
  Route11: '#19D0AF',
  Route12: '#FFCC80',
  Route13: '#6A1B9A',
  Route14: '#E11D6F',
  Route15: '#0277BD',
  Route16: '#80CBC4',
  Route17: '#AD1457',
  Route18: '#26C6DA',
  Route19: '#00897B',
  Route20: '#B39DDB',
  Route21: '#EC407A',
  Route22: '#8E24AA',
  Route23: '#FFB300',
  Route24: '#1E88E5',
  Route25: '#E6EE9C',
  Route26: '#3949AB',
  Route27: '#00ACC1',
  Route28: '#D81B60',
  Route29: '#42A5F5',
  Route30: '#E53935',
  Route31: '#EF9A9A',
  Route32: '#5E35B1',
  Route33: '#81D4FA',
  Route34: '#9CCC65',
  Route35: '#19D0AF',
  Route36: '#C0CA33',
  Route37: '#FB8C00',
  Route38: '#7CB342',
  Route39: '#AB47BC',
  Route40: '#A5D6A7',
  Route41: '#F4511E',
  Route42: '#FFCA28',
  Route43: '#26A69A',
  Route44: '#FFEE58',
  Route45: '#FF7043',
  Route46: '#1565C0',
  Route47: '#FFF59D',
  Route48: '#FFAB91',
  Route49: '#9E9D24',
  Route50: '#C62828',
  Route51: '#9FA8DA',
  Route52: '#C5E1A5',
  Route53: '#80DEEA',
  Route54: '#4527A0',
  Route55: '#90CAF9',
  Route56: '#00838F',
  Route57: '#D84315',
  Route58: '#00695C',
  Route59: '#F48FB1',
  Route60: '#CE93D8'
}; //Status Color

export var statusSuccess = '#28A745';
export var statusWarning = '#F98313';
export var statusError = '#FF4444';
export var statusInfo = '#2190E0';
export var statusDisabled = '#404042';
export var statusDisabled2 = '#e0e0e0'; // Gray Colors

export var gray10 = '#F7F9FA';
export var gray20 = '#F1F3F4';
export var gray30 = '#E3E5E5';
export var gray40 = '#C6CACC';
export var gray60 = '#919699';
export var gray80 = '#5E6366';
export var gray90 = '#3E4345'; //Error Pallete

export var ErrorLight = '#e57373';
export var ErrorMain = '#f44336';
export var ErrorDark = '#d32f2f';
export var ErrorContrast = '#ffff'; //Warning Pallete

export var WarningLight = '#ffb74d';
export var WarningMain = '#ff9800';
export var WarningDark = '#f57c00';
export var WarningContrast = 'rgba(0, 0, 0, 0.87)'; // Buttons

export var planningButton = '#D4F0FF';