/**
 * Helpers Functions
 */
export var getCookie = function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
};
export function setCookie(key, value, expireDays, addDomain, option) {
  var myDate = new Date();
  myDate.setDate(myDate.getDate() + expireDays); // let domain = JWTTool.getDomain();

  var domain = window.location.hostname.split('.').slice(-2).join('.');
  var isHttps = window.location.protocol === 'https:';
  var strCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + myDate.toUTCString();

  if (addDomain) {
    strCookie = strCookie + ';domain=' + domain;
  }

  if (option && option['secure'] && isHttps) {
    strCookie = strCookie + ';secure';
  }

  if (option && option['samesite'] && isHttps) {
    strCookie = strCookie + ';samesite=' + option['samesite'];
  }

  document.cookie = strCookie;
}
export function delete_cookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}
export function setCookieSubDomain(key, value, expireDays) {
  var myDate = new Date();
  myDate.setDate(myDate.getDate() + expireDays);
  var domain = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + myDate.toUTCString() + ';domain=' + domain;
}
export var rgba = function rgba(hex) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');

    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = '0x' + c.join('');
    return "rgba(".concat([c >> 16 & 255, c >> 8 & 255, c & 255].join(','), ",").concat(alpha, ")");
  }

  return '';
};
export var formatNumberWithCommas = function formatNumberWithCommas(x) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var result = x && x.toFixed && x.toFixed(decimal) || 0;
  return result && result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};