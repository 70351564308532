export var licenseClasses = [{
  value: 'A',
  label: 'Class A'
}, {
  value: 'B',
  label: 'Class B'
}, {
  value: 'C',
  label: 'Class C'
}, {
  value: 'D',
  label: 'Class D'
}, {
  value: 'E',
  label: 'Class E'
}, {
  value: 'F',
  label: 'Class F'
}];