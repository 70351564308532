/**
 * App Config File
 */
var AppConfig = {
  themeColors: {
    primary: '#5D92F4',
    secondary: '#677080',
    success: '#00D014',
    danger: '#FF3739',
    warning: '#FFB70F',
    info: '#00D0BD',
    dark: '#464D69',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    yellow: '#D46B08'
  }
};
export default AppConfig;
export var ORG_TYPES = {
  MANUFACTURE: 'Manufacture',
  DISTRIBUTOR: 'Distributor',
  BRANCH: 'Branch',
  SHIPPER: 'Shipper',
  DEPOT: 'Depot',
  SUN: 'Sun',
  XDOCK: 'xDock',
  TRANSPORTER: 'Transporter'
};
export var MOVE_ORDER_TYPES = {
  FROM_MISSING: 3,
  NEW_ORDER: 8,
  ON_ONE_ROUTE: 1,
  BETWEEN_TWO_ROUTE: 2,
  UPDATE_STORE_LOCATION: 4,
  REMOVE_STORE_FROM_ROUTE: 5,
  MOVE_MISSED_ORDER_TO_FREE_VEHICLE: 6,
  MOVE_FROM_VEHICLE_TO_FREE_VEHICLE: 7
};
export var OBJECTS_OF_ROUTES = {
  VEHICLE: 'VEHICLE',
  TRIP: 'TRIP',
  STORE: 'STORE'
};
export var ROUTE_PLANNING_STATUS = {
  PLANNED: 'PLANNED',
  LOCKED: 'LOCKED'
};
export var LOCAL_STORAGES = {
  FILTER_ROUTES: 'FILTER_ROUTES',
  HAS_LOGIN_BEFORE: 'hasLoginBefore',
  SHOW_DELETE_SAMPLE_DATA: 'showDeleteSampleDataButton'
};
export var DATE_RANGE_FORMAT = 'dd/MM/yyyy';
export var DATE_FORMAT = 'DD/MM/YYYY';
export var FULL_DATE_TIME_FORMAT = 'hh:mm:ss DD/MM/YYYY';
export var ACCEPT_IMPORT = ['application/vnd.ms-excel', 'text/plain', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export var PRICING_PLANS = [{
  key: 'basicPlan',
  value: 'Basic'
}, {
  key: 'premiumPlan',
  value: 'Premium'
}, {
  key: 'businessPlan',
  value: 'Business'
}, {
  key: 'enterprisePlan',
  value: 'Enterprise'
}];
export var PLANS = {
  BASIC: 'basicPlan',
  PREMIUM: 'premiumPlan',
  BUSINESS: 'businessPlan',
  ENTERPRISE: 'enterprisePlan'
};