export var sortVehicle = function sortVehicle(_ref) {
  var vehicles = _ref.vehicles,
      sortFields = _ref.sortFields,
      vehiclesOnRoutes = _ref.vehiclesOnRoutes;

  if (vehiclesOnRoutes) {
    vehicles.sort(function (vehicleA, vehicleB) {
      if (vehiclesOnRoutes.find(function (v) {
        return v.vehicleCode === (vehicleA === null || vehicleA === void 0 ? void 0 : vehicleA.vehicleCode);
      })) return 1;
      if (vehiclesOnRoutes.find(function (v) {
        return v.vehicleCode === (vehicleB === null || vehicleB === void 0 ? void 0 : vehicleB.vehicleCode);
      })) return -1;
    });
  }

  if (sortFields) {
    return _.sortBy(vehicles, sortFields);
  }

  return vehicles;
};