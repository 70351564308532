import Request from './Request';
import API_URL from './URLS';
export var fetchRoutes = function fetchRoutes(params, options) {
  return Request.post(API_URL.GET_ROUTE_LIST, params, options);
};
export var fetchPlanHistoryList = function fetchPlanHistoryList(params, options) {
  return Request.post(API_URL.GET_PLAN_HISTORY_LIST, params, options);
};
export var optimizeRoutes = function optimizeRoutes(params, options) {
  return Request.post(API_URL.OPTIMIZE_ROUTE, params, options);
};
export var exportRoutePlanHistory = function exportRoutePlanHistory(params, options) {
  return Request.post(API_URL.EXPORT_ROUTE_PLAN_HISTORY, params, options);
};
export var getRoutePlanDetail = function getRoutePlanDetail(params, options) {
  return Request.post(API_URL.GET_ROUTE_DETAIL, params, options);
};
export var saveEdittedRoutePlan = function saveEdittedRoutePlan(params, options) {
  return Request.post(API_URL.SAVE_EDITTED_ROUTES, params, options);
};
export var getOptimizeInput = function getOptimizeInput(params, options) {
  return Request.post(API_URL.GET_OPTIMIZE_INPUT, params, options);
};
export var getNumberOfOrdersToOptimize = function getNumberOfOrdersToOptimize(params, options) {
  return Request.post(API_URL.GET_NUMBER_OF_ORDERS_TO_OPTIMIZE, params, options);
};
export var getChargeFeeDepot = function getChargeFeeDepot(params, options) {
  return Request.post(API_URL.GET_CHARGE_FEE_DEPOT, params, options);
};