import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { DELIVERY_ORDER_STATUS, ORDER_TEXT } from '@constants/mapView';
export var getRouteIdByShiftCode = function getRouteIdByShiftCode(_ref) {
  var shiftCode = _ref.shiftCode,
      routes = _ref.routes;
  var selectedRoute = find(routes, {
    shiftCode: shiftCode
  });
  return selectedRoute === null || selectedRoute === void 0 ? void 0 : selectedRoute._id;
};
export var getClassNameByOrderStatus = function getClassNameByOrderStatus(orderListStatus) {
  if (orderListStatus.includes(DELIVERY_ORDER_STATUS.PARTIALLY_FULFILLED)) {
    return 'actual-mode-store partially-color';
  }

  if (orderListStatus.includes(DELIVERY_ORDER_STATUS.UNFULFILLED)) {
    return 'actual-mode-store unfulfilled-color';
  }

  if (uniq(orderListStatus).length === 1 && uniq(orderListStatus)[0] === DELIVERY_ORDER_STATUS.FULFILLED) {
    return 'actual-mode-store fulfilled-color';
  }

  return '';
};
export var getTextByOrderStatus = function getTextByOrderStatus(orderStatus) {
  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.PARTIALLY_FULFILLED)) {
    return ORDER_TEXT.PARTIALLY_FULFILLED;
  }

  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.UNFULFILLED)) {
    return ORDER_TEXT.UNFULFILLED;
  }

  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.FULFILLED)) {
    return ORDER_TEXT.FULFILLED;
  }

  return '';
};
export var getBorderColorOrderId = function getBorderColorOrderId(orderStatus) {
  if (String(orderStatus) === DELIVERY_ORDER_STATUS.FULFILLED) return '#0B834A';
  if (String(orderStatus) === DELIVERY_ORDER_STATUS.PARTIALLY_FULFILLED) return '#F98313';
  if (String(orderStatus) === DELIVERY_ORDER_STATUS.UNFULFILLED) return '#FF6D6D';
  return null;
};
export var getStoreStatus = function getStoreStatus(orderStatus) {
  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.PARTIALLY_FULFILLED)) {
    return '#F98313';
  }

  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.UNFULFILLED)) {
    return '#FF6D6D';
  }

  if (orderStatus !== null && orderStatus !== void 0 && orderStatus.includes(DELIVERY_ORDER_STATUS.FULFILLED)) {
    return '#0B834A';
  }

  return null;
};