import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _ from 'lodash';
import { ROUTE_COLORS } from '@pages/MapView/store/constants';
export var addColorToRoutes = function addColorToRoutes(vehicleRoutes, dataChangeColor, selectedDateAndBranch) {
  var branchId = _.get(selectedDateAndBranch, 'branch._id');

  var date = _.get(selectedDateAndBranch, 'date');

  var routes = Object.values(vehicleRoutes).map(function (shifts, index) {
    var constIndex = index % 60;
    return shifts.map(function (shift) {
      var _JSON$parse, _JSON$parse$find;

      var colorStorage = (_JSON$parse = JSON.parse(localStorage.getItem("ROUTE_COLOR_".concat(branchId, "_").concat(date)))) === null || _JSON$parse === void 0 ? void 0 : (_JSON$parse$find = _JSON$parse.find(function (i) {
        return (i === null || i === void 0 ? void 0 : i.shiftCode) === (shift === null || shift === void 0 ? void 0 : shift.shiftCode);
      })) === null || _JSON$parse$find === void 0 ? void 0 : _JSON$parse$find.color;
      var isChangeRouteColor = dataChangeColor === null || dataChangeColor === void 0 ? void 0 : dataChangeColor.find(function (i) {
        return (i === null || i === void 0 ? void 0 : i.routeId) === (shift === null || shift === void 0 ? void 0 : shift._id);
      });
      return {
        route: shift,
        // color: isChangeRouteColor ? (isChangeRouteColor?.color || '#F4511E') : _.get(RouteColor, colorIndex, '#F4511E'),
        color: isChangeRouteColor ? isChangeRouteColor === null || isChangeRouteColor === void 0 ? void 0 : isChangeRouteColor.color : colorStorage ? colorStorage : ROUTE_COLORS[constIndex]
      };
    });
  }).reduce(function (acc, curr) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(curr));
  }, []); //   console.log('route s.......', routes, Object.values(vehicleRoutes))

  var routeColorSaveLocal = routes === null || routes === void 0 ? void 0 : routes.map(function (i) {
    var _i$route;

    return {
      color: i === null || i === void 0 ? void 0 : i.color,
      shiftCode: i === null || i === void 0 ? void 0 : (_i$route = i.route) === null || _i$route === void 0 ? void 0 : _i$route.shiftCode
    };
  });
  localStorage.setItem("ROUTE_COLOR_".concat(branchId, "_").concat(date), JSON.stringify(routeColorSaveLocal));
  return routes;
};