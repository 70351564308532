import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import styled from 'styled-components';
import { rgba } from '@utils/Helper';
export var PageBodyWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 22px;\n  height: calc(100vh - 150px);\n"])));
export var CellContentWrapper = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  width: fit-content;\n\n  &.content-color {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  &.content-margin {\n    margin: 4px 4px 4px 0;\n  }\n"])), function (props) {
  return props.color;
}, function (props) {
  return props.color ? "".concat(rgba(props.color, 0.3)) : 'unset';
});