import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import indexOf from 'lodash/indexOf';
import { MappingStatus } from "../constants";

function getBodyData(sortFormattedData, contentHeaders, contentBodyList) {
  return sortFormattedData.map(function (_ref) {
    var to = _ref.to;
    var origin = typeof to === "string" ? to : to === null || to === void 0 ? void 0 : to.origin;
    var itemIndex = indexOf(contentHeaders, origin);
    return contentBodyList[itemIndex];
  });
}

export default function getTableData(_ref2) {
  var mappingColumns = _ref2.mappingColumns,
      csvContent = _ref2.csvContent,
      fileContent = _ref2.fileContent;
  var formattedData = [];

  for (var key in mappingColumns) {
    var mappingItem = mappingColumns[key];

    if (mappingItem.status === MappingStatus.CONFIRMED) {
      var mappedName = typeof mappingItem.to === "string" ? mappingItem.to : mappingItem.to.origin;
      formattedData.push(_objectSpread(_objectSpread({}, mappingColumns[key]), {}, {
        csvContent: csvContent[mappedName]
      }));
    }
  }

  var sortFormattedData = sortBy(formattedData, [function (o) {
    var _o$csvContent;

    return o === null || o === void 0 ? void 0 : (_o$csvContent = o.csvContent) === null || _o$csvContent === void 0 ? void 0 : _o$csvContent.alphabet;
  }]);
  var headerData = sortFormattedData.map(function (_ref3) {
    var from = _ref3.from;
    return from;
  });
  var fileContentHeader = head(fileContent);
  var contentHeaders = Object.values(fileContentHeader);
  var fileContentBody = fileContent.length > 0 && fileContent.slice(1);
  var contentBodyList = fileContentBody.map(function (content) {
    var contentBody = Object.values(content);
    return getBodyData(sortFormattedData, contentHeaders, contentBody);
  });
  return {
    header: headerData,
    content: contentBodyList
  };
}