import { APIAXIOS } from '@utils/apis';
import RowType from '@constants/RowType';
export default function exportFile(data, name, type) {
  var file = new Blob([data], {
    type: !type ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : type
  });
  var fileUrl = URL.createObjectURL(file);
  setTimeout(function () {
    var link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, 100);
}
export var exportSinglePackingList = function exportSinglePackingList(params, name) {
  APIAXIOS.exportPackingSingleRoute(params).then(function (res) {
    exportFile(res.data, name, res.headers['content-type']);
  }).catch(function (e) {
    console.error(e);
  });
};
export var exportSinglePickingList = function exportSinglePickingList(params, name) {
  APIAXIOS.exportPickingSingleRoute(params).then(function (res) {
    exportFile(res.data, name, res.headers['content-type']);
  }).catch(function (e) {
    console.error(e);
  });
};
export var downloadAllPackingPicking = function downloadAllPackingPicking(_ref) {
  var downloadType = _ref.downloadType,
      data = _ref.data,
      routePlan = _ref.routePlan,
      setAnchorEl = _ref.setAnchorEl;
  var params = {};
  var lang = window.Localize.getLanguage();
  var routeIdArr = [];

  if (data.some(function (i) {
    return i.selected;
  })) {
    routeIdArr = data.filter(function (i) {
      return i.type === RowType.TRIP && i.selected;
    }).map(function (i) {
      return i.id;
    });
  } else {
    routeIdArr = data.filter(function (i) {
      return i.type === RowType.TRIP;
    }).map(function (i) {
      return i.id;
    });
  }

  var name = '';
  var date = moment().format('DDMMYYYY');

  if (routeIdArr.length > 0 && !data.some(function (i) {
    return i.selected;
  })) {
    if (downloadType === 'packing') {
      params = {
        lang: lang,
        routers: routeIdArr
      };

      if (lang === 'en') {
        name = "Packing List (all)".concat(routePlan.organizationId.organizationName, "_").concat(date);
      } else if (lang === 'vi') {
        name = "Phi\u1EBFu \u0111\xF3ng g\xF3i (t\u1EA5t c\u1EA3)".concat(routePlan.organizationId.organizationName, "_").concat(date);
      }

      APIAXIOS.exportPackingAllRoute(params).then(function (res) {
        exportFile(res.data, name, res.headers['content-type']);
      }).catch(function (e) {
        console.error(e);
      });
    } else if (downloadType === 'picking') {
      params = {
        lang: lang,
        routeIds: routeIdArr
      };

      if (lang === 'en') {
        name = "Picking List (all)".concat(routePlan.organizationId.organizationName, "_").concat(date);
      } else if (lang === 'vi') {
        name = "B\u1EA3ng k\xEA h\xE0ng xu\u1EA5t kho (t\u1EA5t c\u1EA3)".concat(routePlan.organizationId.organizationName, "_").concat(date);
      }

      APIAXIOS.exportPickingAllRoute(params).then(function (res) {
        exportFile(res.data, name, res.headers['content-type']);
      }).catch(function (e) {
        console.error(e);
      });
    }
  } else if (routeIdArr.length > 0 && data.some(function (i) {
    return i.selected;
  })) {
    var _loop = function _loop(i) {
      var tripArr = data.find(function (dat) {
        return (dat === null || dat === void 0 ? void 0 : dat.id) === routeIdArr[i] || (dat === null || dat === void 0 ? void 0 : dat._id) === routeIdArr[i];
      });
      var tripCode = '';

      if (tripArr instanceof Object) {
        var bomb = tripArr.stores.filter(function (i) {
          return i.tripCode;
        });

        if (bomb.length > 0) {
          for (var bo = 0; bo < bomb.length; bo++) {
            tripCode = bomb[bo].tripCode;

            if (downloadType === 'packing') {
              params = {
                lang: lang,
                routeId: routeIdArr[i],
                tripCode: tripCode
              };

              if (lang === 'en') {
                name = "Packing List (by trip [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
              } else if (lang === 'vi') {
                name = "Phi\u1EBFu \u0111\xF3ng g\xF3i (theo chuy\u1EBFn [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
              }

              exportSinglePackingList(params, name);
            } else if (downloadType === 'picking') {
              params = {
                routeId: routeIdArr[i],
                lang: lang,
                tripCode: tripCode
              };

              if (lang === 'en') {
                name = "Picking List (by trip [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
              } else if (lang === 'vi') {
                name = "B\u1EA3ng k\xEA h\xE0ng xu\u1EA5t kho (theo chuy\u1EBFn [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
              }

              exportSinglePickingList(params, name);
            }
          }
        }
      } else if (tripArr.length > 1) {
        tripArr.forEach(function (a) {
          var bomb = a.stores.filter(function (i) {
            return i.tripCode;
          });

          if (bomb.length > 0) {
            for (var _bo = 0; _bo < bomb.length; _bo++) {
              tripCode = bomb[_bo].tripCode;

              if (downloadType === 'packing') {
                params = {
                  lang: lang,
                  routeId: routeIdArr[i],
                  tripCode: tripCode
                };

                if (lang === 'en') {
                  name = "Packing List (by trip [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
                } else if (lang === 'vi') {
                  name = "Phi\u1EBFu \u0111\xF3ng g\xF3i (theo chuy\u1EBFn [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
                }

                exportSinglePackingList(params, name);
              } else if (downloadType === 'picking') {
                params = {
                  routeId: routeIdArr[i],
                  lang: lang,
                  tripCode: tripCode
                };

                if (lang === 'en') {
                  name = "Picking List (by trip [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
                } else if (lang === 'vi') {
                  name = "B\u1EA3ng k\xEA h\xE0ng xu\u1EA5t kho (theo chuy\u1EBFn [".concat(tripCode, "])_").concat(routePlan.organizationId.organizationName, "_").concat(date);
                }

                exportSinglePickingList(params, name);
              }
            }
          }
        });
      }
    };

    for (var i = 0; i < routeIdArr.length; i++) {
      _loop(i);
    }
  }

  setAnchorEl(null);
};