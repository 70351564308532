import React from 'react';
import { makeStyles } from '@material-ui/styles';
export var useStyles = makeStyles(function (them) {
  return {
    upgrade: {
      '&.MuiGrid-item': {
        alignSelf: 'center'
      }
    },
    content: {
      '& .MuiSvgIcon-root': {
        color: '#1B64B0'
      }
    },
    buttonUpgrade: {
      '&.MuiGrid-item': {
        textAlign: 'center'
      }
    },
    padding: {
      '&.MuiDialogActions-root': {
        margin: '16px 24px 16px 0'
      }
    },
    package: {
      '&.MuiTypography-body1': {
        marginBottom: '16px',
        fontSize: 18
      }
    },
    costMonth: {
      '&.MuiTypography-body1': {
        marginTop: 8
      }
    },
    dialog: {
      '& .MuiPaper-root': {
        width: '75%',
        height: '80%',
        maxWidth: '100%'
      }
    }
  };
});