export var setCookie = function setCookie(key, value, expireDays, addDomain, option) {
  var myDate = new Date();
  myDate.setDate(myDate.getDate() + expireDays); // var domain = JWTTool.getDomain();

  var domain = window.location.hostname.split('.').slice(-2).join('.');
  var isHttps = window.location.protocol == 'https:' ? true : false;
  var strCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + myDate.toUTCString();

  if (addDomain) {
    strCookie = strCookie + ';domain=' + domain;
  }

  if (option && option['secure'] && isHttps) {
    strCookie = strCookie + ';secure';
  }

  if (option && option['samesite'] && isHttps) {
    strCookie = strCookie + ';samesite=' + option['samesite'];
  }

  document.cookie = strCookie;
};
export var removeJWTCookie = function removeJWTCookie() {
  setCookie('jwtToken', '', -1, true);
  setCookie('user', '', -1, false);
  setCookie('accessToken', '', -1, false);
  setCookie('rootOrg', '', -1, false);
};
export var clearCookiesAndRedirectToLogin = function clearCookiesAndRedirectToLogin(url) {
  console.log(url);
  removeJWTCookie();
  var listCookies = window.Cookies.get();
  var keyCookies = Object.keys(listCookies);
  keyCookies === null || keyCookies === void 0 ? void 0 : keyCookies.forEach(function (key) {
    window.Cookies.remove(key);
  });
  var noDeleteKeys = ['customer-column', 'mapTheme'];
  var tempData = {};

  for (var _i = 0, _noDeleteKeys = noDeleteKeys; _i < _noDeleteKeys.length; _i++) {
    var k = _noDeleteKeys[_i];
    tempData[k] = localStorage.getItem(k);
  }

  localStorage.clear();

  for (var _i2 = 0, _noDeleteKeys2 = noDeleteKeys; _i2 < _noDeleteKeys2.length; _i2++) {
    var _k = _noDeleteKeys2[_i2];
    localStorage.setItem(_k, tempData[_k]);
  }

  window.location = url;
};