import makeStyles from "@material-ui/core/styles/makeStyles";
export var dUseStyles = makeStyles(function (theme) {
  return {
    inputSpacing: {
      marginTop: theme.spacing(1),
      "& .MuiToolbar-regular": {
        display: "none"
      }
    }
  };
});