import Request from './Request';
import API_URL from './URLS';
export var fetchOrganization = function fetchOrganization(params, options) {
  return Request.post(API_URL.GET_ORGANIZATION_LIST, params, options);
};
export var fetchOrganizationCategories = function fetchOrganizationCategories(params, options) {
  return Request.post(API_URL.GET_ORG_CATEGORY, params, options);
};
export var getOrganizationDetail = function getOrganizationDetail(params, options) {
  return Request.post(API_URL.READ_ORG, params, options);
};
export var deleteOrganization = function deleteOrganization(params, options) {
  return Request.post(API_URL.REMOVE_ORG, params, options);
};
export var fetchOrganizationBranch = function fetchOrganizationBranch(params, options) {
  return Request.post(API_URL.GET_BRANCH_LIST, params, options);
};
export var exportOrganizations = function exportOrganizations(params, options) {
  return Request.post(API_URL.EXPORT_ORGANIZATIONS, params, options);
};
export var createSampleData = function createSampleData(params, options) {
  return Request.post(API_URL.CREATE_SAMPLE_DATA, params, options);
};
export var deleteSampleData = function deleteSampleData(params, options) {
  return Request.post(API_URL.DELETE_SAMPLE_DATA, params, options);
};