import Request from './Request';
import API_URL from './URLS';
export var fetchVehicleList = function fetchVehicleList(params, options) {
  return Request.post(API_URL.GET_VEHICLE_LIST, params, options);
};
export var fetchVehicleTypeList = function fetchVehicleTypeList(params) {
  return Request.post(API_URL.GET_VEHICLE_TYPE_LIST, params);
};
export var changeVehicleInline = function changeVehicleInline(params, options) {
  return Request.post(API_URL.CHANGE_DRIVER, params, options);
};