import _ from 'lodash';
export var checkCrossTime = function checkCrossTime(item) {
  var vehicleDeliverDetail = _.get(item, 'deliverDetail', []);

  var totalStop = (vehicleDeliverDetail === null || vehicleDeliverDetail === void 0 ? void 0 : vehicleDeliverDetail.length) - 1;

  if (totalStop > 1) {
    var timeFirstStop = _.get(vehicleDeliverDetail[1], 'doneTime');

    var timeLastStop = _.get(_.last(vehicleDeliverDetail), 'doneTime');

    var hourTimeFirstStop = _.head(timeFirstStop.split(':'));

    var hourTimeLastStop = _.head(timeLastStop.split(':'));

    return Math.floor(hourTimeLastStop / 24) === Math.floor(hourTimeFirstStop / 24) ? false : true;
  }

  return false;
};