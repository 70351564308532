import axios from '../providers/axios';

var axiosPostArrayBuffer = function axiosPostArrayBuffer(path) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axios.post(path, params, {
    responseType: 'arraybuffer'
  });
};

export function exportFileType(data, name, type) {
  var file = new Blob([data], {
    type: !type ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : type
  });
  var fileUrl = URL.createObjectURL(file);
  setTimeout(function () {
    triggerDownload(fileUrl, name);
  }, 100);
}

var exportFile = function exportFile(url, params, name) {
  toastr.info('Start exporting data...');

  try {
    axiosPostArrayBuffer(url, params).then(function (res) {
      exportFileType(res.data, name, res.headers['content-type']);
      toastr.success('Exporting data success..');
    });
  } catch (error) {
    console.error(error);
  }
};

export default exportFile;
export var triggerDownload = function triggerDownload(href, fileName) {
  var link = document.createElement('a');
  link.href = href;
  link.target = '_blank';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};