import axios from 'axios';
import { addRootOrg, addToken } from './intercepters';
var instance = axios.create({
  withCredentials: true
});
instance.interceptors.request.use(function (config) {
  return addRootOrg(config);
}, function (error) {
  return Promise.reject(error);
});
instance.interceptors.request.use(function (config) {
  return addToken(config);
}, function (error) {
  return Promise.reject(error);
});
export default instance;