import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var getUniqueValues = function getUniqueValues(array) {
  var returnValues = [];
  if (array.length === 0) return returnValues;
  var jsonObject = array.map(function (i) {
    return JSON.stringify(i);
  });
  var uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(function (i) {
    return JSON.parse(i);
  });
};

var isDuplicate = function isDuplicate(array) {
  return array.some(function (item, idx) {
    return array.indexOf(item) !== idx;
  });
};

var findDuplicateIndexes = function findDuplicateIndexes(array) {
  var _iterator = _createForOfIteratorHelper(array.entries()),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
          idx = _step$value[0],
          item = _step$value[1];

      var index = array.indexOf(item);

      if (index !== idx) {
        return {
          firstIndex: index,
          secondIndex: idx
        };
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};

export var removeDuplicateArray = function removeDuplicateArray(array) {
  var valueArr = array.map(function (item) {
    return item._id;
  });

  if (isDuplicate(valueArr)) {
    var _findDuplicateIndexes = findDuplicateIndexes(valueArr),
        firstIndex = _findDuplicateIndexes.firstIndex,
        secondIndex = _findDuplicateIndexes.secondIndex;

    return array.filter(function (item, idx) {
      return idx !== firstIndex && idx !== secondIndex;
    });
  }

  return array;
};
export default getUniqueValues;