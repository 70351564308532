export var PHASES = {
  PICK_UP: 'PICKUP',
  SHUTTLE: 'SHUTTLE',
  LAST_MILES: 'LASTMILES',
  DELIVERY: 'DELIVERY'
};
export var REASON_TEXT = {
  OVERDUE_OF_OTHER_DRIVER: 'This Order belongs to another Driver.',
  WRONG_SOURCE: 'This Order and assigned Vehicle currently belong to different Depots. Please check Organization of Vehicle and Order.',
  OVER_CAPACITY: 'Over capacity',
  TEMPERATURE_LEVEL_NOT_FIT: "This Vehicle's temperature does not match with Product's temperature. Please check Vehicle's temperature.",
  OVER_AMBIENT_CAPACITY: 'This Ambient order volume exceeds Vehicle volume.',
  OVER_FROZEN_CAPACITY: 'This Frozen order volume exceeds Vehicle volume.',
  OVER_CHILLED_CAPACITY: 'This Chilled order volume exceeds Vehicle volume.',
  NO_OF_TEMP_ZONE_EXCEEDED: "This number of Vehicle zone is inappropriate with the Product's temperature. Please check the number of Vehicle zone.",
  NOT_ENOUGH_SPACE_FOR_AIR_FLOW: 'This Vehicle capacity does not have enough space to deliver this Order. Please check the Vehicle volume and weight.',
  STORE_CLOSED: 'Open/Close time of Customer does not match with Vehicle visit time.',
  OFF_WORKING_HOURS: 'This Vehicle is out of working hours. Please check the working hour of Vehicle.',
  TRUCK_IS_BOUND_FOR_XDOCK: 'This Vehicle is ready to deliver orders belong to xDock, therefore not able to deliver orders belong to Depot.',
  ALREADY_VISIT_THE_SAME_STORE: 'This Order and another Order have the same Customer. Please choose "Split Order" or assign another Vehicle.',
  NOT_THE_RIGHT_GROUP: 'This Vehicle cannot deliver Products of this Customer Group.',
  XDOCK_NOT_READY: 'Please check the Open/Close time of xDock.',
  TRUCK_ONLY: 'This Customer is configured as "Truck only". Please check the Vehicle type for this Customer.',
  BIKE_ONLY: 'This Customer is configured as "Bike only". Please check the Vehicle type for this Customer.',
  SOURCEID_NOT_MATCH: "This vehicle is operating other depot's orders, therefore not able to deliver",
  ALLOWED_VEHICLE_TYPE_NOT_MATCHED: 'This vehicle type is not allowed to deliver to this customer',
  ONE_STOP_ONLY: 'This vehicle can only deliver one order',
  OVER_VOLUME: "This Order exceeds Vehicle's volume. Please check Vehicle's volume.",
  OVER_WEIGHT: "This Order exceeds Vehicle's weight. Please check Vehicle's weight.",
  XDOCK_OF_DIFFERENT_WAREHOUSE: 'This vehicle belongs to a different Depot, therefore can not deliver this order',
  ALLOWED_VEHICLE_NOT_MATCHED: 'Vehicle license plates do not match the date of route optimization (Odd-Even traffic policy)',
  IN_PROGRESS_VEHICLE: 'Vehicle has not finished the previous trip(s)',
  SHIFT_LIMIT_EXCEEDED: 'Over the maximum number of shift'
};