import packingListIcon from '../../public/images/mapview/packingList.svg';
import pickingListIcon from '../../public/images/mapview/pickingList.svg';
import orderListIcon from '../../public/images/mapview/orderList.svg';
import loading3DPlanIcon from '../../public/images/mapview/loading3DPlan.svg';
import updateLocationIcon from '../../public/images/mapview/updateLocation.svg';
import removeFrRouteIcon from '../../public/images/mapview/removeFrRoute.svg';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import OpenWithSharpIcon from '@material-ui/icons/OpenWithSharp';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
export var MAPVIEW_MODE = {
  PLAN: 'plan',
  ACTUAL: 'execution'
};
export var TIMELINE_ROUTE_HEIGHT = {
  PLAN: 70,
  ACTUAL: 90
};
export var MODAL_TYPE = {
  VEHICLE_DETAIL: 'vehicle',
  SHIFT_DETAIL: 'shift',
  DEPOT_DETAIL: 'depot',
  STOP_DETAIL: 'stop',
  ORDER_DETAIL: 'order',
  MISSING_ORDER_DETAIL: 'missing order',
  MISSING_STOP_DETAIL: 'missing stop'
};
export var STOP_TYPE = {
  DEPOT: 'depot',
  STORE: 'store'
};
export var commonItems = [{
  icon: packingListIcon,
  text: 'Packing List',
  key: 'TOTAL_PACKING'
}, {
  icon: pickingListIcon,
  text: 'Picking List',
  key: 'TOTAL_PICKING'
}, {
  icon: orderListIcon,
  text: 'Order List',
  key: 'VEHICLE_ORDERS'
}];
export var vehicleItems = [{
  icon: packingListIcon,
  text: 'Packing List',
  key: 'TOTAL_PACKING'
}, {
  icon: pickingListIcon,
  text: 'Picking List',
  key: 'TOTAL_PICKING'
}, {
  icon: orderListIcon,
  text: 'Order List',
  key: 'VEHICLE_ORDERS'
}];
export var stopItems = [// {
//   icon: updateLocationIcon,
//   text: 'Update Location',
//   type: 'UPDATE_LOCATION',
// },
{
  icon: updateLocationIcon,
  text: 'Update Ship-to Address',
  type: 'UPDATE_ADDRESS'
}, {
  icon: removeFrRouteIcon,
  text: 'Remove fr Route',
  value: 'removeFrRoute'
}, {
  icon: packingListIcon,
  text: 'Packing List',
  key: 'TOTAL_PACKING'
}, {
  icon: pickingListIcon,
  text: 'Picking List',
  key: 'TOTAL_PICKING'
}];
export var depotItems = [{
  icon: loading3DPlanIcon,
  text: '3D Loading Plan',
  name: '3dLoadingPlan'
}].concat(commonItems);
export var orderItems = [{
  icon: removeFrRouteIcon,
  text: 'Remove fr Route',
  name: 'removeFrRoute'
}, {
  icon: CallSplitIcon,
  text: 'Split Order',
  name: 'splitOrder'
}, {
  icon: pickingListIcon,
  text: 'Picking List',
  key: 'TOTAL_PICKING'
}];
export var missingOrderItems = [{
  icon: OpenWithSharpIcon,
  text: 'Move Missing Order',
  name: 'moveMissingOrder'
}, {
  icon: CallSplitIcon,
  text: 'Split Order',
  name: 'splitMissingOrder'
}, {
  icon: ListAltSharpIcon,
  text: 'Move To LTL Order',
  name: 'moveToLTLOrder'
}];
export var newOrderItems = [{
  icon: OpenWithSharpIcon,
  text: 'Move New Order',
  name: 'moveNewOrder'
}];
export var MAPVIEW_MODAL_KEYS = {
  VEHICLE_TYPE: 'Vehicle Type',
  LICENSE_PLATE: 'License Plate',
  ASSIGNED: 'Assigned',
  TEMP_CONFIG: 'Temp. Config',
  TEMP_ZONE: 'Temp. Zone',
  POSITION: 'Position',
  SPEED: 'Speed',
  START_TIME: 'Start Time',
  STOP_TIME: 'Stop Time',
  SHIFTS: 'Shifts',
  CLUSTER: 'Cluster',
  FAMILIARITY: 'Familiarity',
  ORDER_ID: 'orderIds',
  DEFAULT: 'default',
  TEMPERATURE: 'Temperature',
  VEHICLE_LICENSE: 'Vehicle license',
  STORE_ACTUAL_DATA: 'Store actual data',
  DEPOT_ACTUAL_DATA: 'Depot actual data',
  COLD_CHAIN_CONFIG_DEPOT: 'Cold chain config depot',
  ORDER_FULFILLED_STATUS: 'Order fullfilled status',
  REACHING_TIME: 'Reaching Time'
};
export var TEMP_CONFIG = {
  FROZEN: {
    abbr: 'F',
    fullName: 'Frozen',
    color: '#1A89FD',
    backgroundColor: '#DCEFFD'
  },
  CHILLED: {
    abbr: 'C',
    fullName: 'Chilled',
    color: '#0B834A',
    backgroundColor: '#A2F5CB'
  },
  AMBIENT: {
    abbr: 'A',
    fullName: 'Ambient',
    color: '#F98313',
    backgroundColor: '#FFEACB'
  }
};
export var TASK_STATUS = {
  OPEN: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  REJECTED: 3,
  DELETED: 4,
  UNASSIGNED: 5
};
export var VEHICLE_STATUS = {
  OPEN: 'OPEN',
  PLANNED: 'PLANNED',
  LOCKED: 'LOCKED'
};
export var DELIVERY_ORDER_STATUS = {
  NOT_FULFILLED: '1',
  FULFILLED: '2',
  PARTIALLY_FULFILLED: '3',
  UNFULFILLED: '4'
};
export var ORDER_TEXT = {
  FULFILLED: 'Fulfilled',
  UNFULFILLED: 'Unfulfilled',
  PARTIALLY_FULFILLED: 'Partially Fulfilled'
};
export var TIMELINE_DATE_FORMAT = 'MM/DD/YYYY';
export var GENERATE_ROUTES_STATUS = {
  START: 'Optimization process start',
  RUNNING: 'Branch is running optimization'
};
export var WIDTH_UNIT = 4; //4px per minute at focus 1x

export var MINUTES_STEP = 30;
export var DEFAULT_ZOOM_VALUE = 0.5;
export var FULL_WIDTH_DATE = WIDTH_UNIT * 60 * 24;
export var INITIAL_MAP_COORDINATE = {
  lat: 21.04002195878051,
  lng: 105.81293843696434
};
export var ROUTE_STATUS = {
  LOCKED: 'LOCKED',
  FINALIZED: 'FINALIZED',
  COMPLETED: 'COMPLETED',
  PLANNED: 'PLANNED'
};
export var GROUP_NAME = {
  DELIVERER: 'DELIVERER'
};
export var SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none'
};
export var SORT_TYPE_NEXT = {
  asc: SORT_TYPE.DESC,
  desc: SORT_TYPE.NONE,
  none: SORT_TYPE.ASC
};
export var SORT_LIST = {
  licensePlate: {
    id: 'licensePlate',
    label: 'License Plate',
    sortType: SORT_TYPE.NONE
  },
  typeOfVehicle: {
    id: 'typeOfVehicle',
    label: 'Vehicle Type',
    sortType: SORT_TYPE.NONE
  },
  capacityWeight: {
    id: 'capacityWeight',
    label: 'Vehicle Weight',
    sortType: SORT_TYPE.NONE
  },
  capacityVolume: {
    id: 'capacityVolume',
    label: 'Vehicle Volume',
    sortType: SORT_TYPE.NONE
  },
  fillRateVolume: {
    id: 'fillRateVolume',
    label: 'Vehicle Fill Rate',
    sortType: SORT_TYPE.NONE
  },
  timeSlot: {
    id: 'timeSlot',
    label: 'Time Slot',
    sortType: SORT_TYPE.NONE
  },
  shiftCode: {
    id: 'shiftCode',
    label: 'Shift Code',
    sortType: SORT_TYPE.NONE
  }
};
export var DOWNLOAD_LABELS = {
  allPickingList: {
    label: 'All Picking List',
    key: 'TOTAL_PICKING'
  },
  allPackingList: {
    label: 'All Packing List',
    key: 'TOTAL_PACKING'
  },
  exportRoutePlan: {
    label: 'Export Route Plan',
    key: 'ROUTE_PLAN'
  }
};
export var DOWNLOAD_TYPE = {
  ALL_PICKING_LIST: 'TOTAL_PICKING',
  ALL_PACKING_LIST: 'TOTAL_PACKING',
  EXPORT_ROUTE_PLAN: 'ROUTE_PLAN',
  VEHICLE_ORDERS: 'VEHICLE_ORDERS',
  ALL: 'ALL',
  VEHICLE: 'VEHICLE',
  SHIFT: 'SHIFT',
  TRIP: 'TRIP',
  STOP: 'STOP',
  ORDER: 'ORDER'
};
export var DRAG_AND_DROP_TYPES = {
  FROM_MISSING_ORDERS: 'missing_orders',
  FROM_NEW_ORDERS: 'new_orders',
  FROM_ROUTE: 'route'
};
export var FAILED_OPTIMIZATION_MESSAGES = {
  INPUT: 'Could not find the solution, please check the input data',
  NO_SOLUTION: 'Could not find the solution'
};
export var LEFT_TIME_LINE_WIDTH = 251;
export var OPTIMIZE_TYPES = {
  OPTIMIZE: 'optimize',
  REOPTIMIZE: 'reoptimize'
};
export var TASK_ACTIONS_VRP = {
  SOAN_HANG: 'SOAN_HANG',
  GIAO_HANG: 'GIAO_HANG',
  HET_NGAY: 'HET_NGAY',
  VE_KHO: 'VE_KHO'
};
export var CUT_OFF_TIME_OPTIONS = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
export var initialReportModalState = {
  open: false,
  type: '',
  currency: '',
  isLongHaul: '',
  content: null
};
export var downloadTypeMapping = Object.freeze({
  PACKING: 'packing',
  PICKING: 'picking',
  PRODUCTS: 'product',
  ORDERS: 'order',
  ALL_PRODUCTS: 'allProducts',
  ALL_ORDERS: 'allOrders'
});
export var OBJECTS_OF_ROUTE = {
  VEHICLE: 'vehicle',
  SHIFT: 'shift',
  CUSTOMER: 'customer',
  ORDER: 'order'
};
export var CONSTRAINT_MODES = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create'
};
export var VEHICLE_TYPES = {
  BIKE: 'bike',
  TRUCK: 'truck',
  SEMI_TRUCK: 'semi-truck'
};
export var EDIT_ACTIONS = {
  DnD_MISSING_ORDER: {
    value: 'DnD_MISSING_ORDER',
    text: 'Drag and drop missing order'
  },
  DnD_NEW_ORDER: {
    value: 'DnD_NEW_ORDER',
    text: 'Drag and drop new order'
  },
  MOVE_MISSING_ORDER_TO_NEW_VEHICLE: {
    value: 'MOVE_MISSING_ORDER_TO_NEW_VEHICLE',
    text: 'Move missing order to new vehicle'
  },
  MOVE_MISSING_ORDER_TO_VEHICLE_ON_ROUTE: {
    value: 'MOVE_MISSING_ORDER_TO_VEHICLE_ON_ROUTE',
    text: 'Move missing order to vehicle on routes'
  },
  MOVE_NEW_ORDER_TO_NEW_VEHICLE: {
    value: 'MOVE_NEW_ORDER_TO_NEW_VEHICLE',
    text: 'Move new order to new vehicle'
  },
  MOVE_NEW_ORDER_TO_VEHICLE_ON_ROUTE: {
    value: 'MOVE_NEW_ORDER_TO_VEHICLE_ON_ROUTE',
    text: 'Move new order to vehicle on routes'
  },
  DnD_STOPS_ON_ONE_ROUTE: {
    value: 'DnD_STOPS_ON_ONE_ROUTE',
    text: 'Drag and drop stop on one route'
  },
  DnD_STOP_ON_MULTI_ROUTES: {
    value: 'DnD_STOP_ON_MULTI_ROUTES',
    text: 'Drag and drop stop on multi routes'
  },
  MOVE_STOPS_TO_NEW_VEHICLE: {
    value: 'MOVE_STOPS_TO_NEW_VEHICLE',
    text: 'Move stops to new vehicle'
  },
  MOVE_STOPS_TO_VEHICLE_ON_ROUTES: {
    value: 'MOVE_STOPS_TO_VEHICLE_ON_ROUTES',
    text: 'Move stops to vehicle on routes'
  },
  CHANGE_VEHICLE: {
    value: 'CHANGE_VEHICLE',
    text: 'Change vehicle'
  },
  CHANGE_DRIVER: {
    value: 'CHANGE_DRIVER',
    text: 'Change driver'
  },
  MOVE_MULTI_STOP_TO_VEHICLE: {
    value: 'MOVE_MULTI_STOP_TO_VEHICLE',
    text: 'Move multiple stops to vehicle '
  },
  DnD_STOP_ON_ROUTE: {
    value: 'DnD_STOP_ON_ROUTE',
    text: 'Drag and Drop stop on route'
  },
  REMOVE_MULTIPLE_STOPS: {
    value: 'REMOVE_STOPS',
    text: 'Remove multiple stop'
  },
  REMOVE_ORDER: {
    value: 'REMOVE_ORDER',
    text: 'Remove Order'
  }
};