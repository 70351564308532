import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import { DOCK_TEMPERATURE } from './constants';
export var convertToDocksTableData = function convertToDocksTableData(docks) {
  return docks.map(function (dock) {
    var _dock$organizationId, _dock$dockTemperature;

    return _objectSpread(_objectSpread({}, dock), {}, {
      organizationId: dock === null || dock === void 0 ? void 0 : (_dock$organizationId = dock.organizationId) === null || _dock$organizationId === void 0 ? void 0 : _dock$organizationId.organizationName,
      dockTemperature: dock === null || dock === void 0 ? void 0 : (_dock$dockTemperature = dock.dockTemperature) === null || _dock$dockTemperature === void 0 ? void 0 : _dock$dockTemperature.map(function (item) {
        return DOCK_TEMPERATURE[item];
      })
    });
  });
};
export var convertToDepotsTableData = function convertToDepotsTableData(depots) {
  return depots.map(function (d) {
    var _d$parentId;

    return _objectSpread(_objectSpread({}, d), {}, {
      parentName: d === null || d === void 0 ? void 0 : (_d$parentId = d.parentId) === null || _d$parentId === void 0 ? void 0 : _d$parentId.organizationName
    });
  });
};
export var formattedDockPayloadData = function formattedDockPayloadData(dock) {
  var _ref = dock || {},
      timeSlot = _ref.timeSlot;

  if (Array.isArray(timeSlot)) return dock;
  return _objectSpread(_objectSpread({}, dock), {}, {
    timeSlot: dock !== null && dock !== void 0 && dock.timeSlot ? dock === null || dock === void 0 ? void 0 : dock.timeSlot.split(';') : []
  });
};