import React from 'react';
import { MARKER_VRP_CODE_LOCKED_OR_PLANNED, MARKER_VRP_CODE_FINALIZE, PIN_STORE_FINALIZE_DROP, PIN_STORE_FINALIZE_PICK, PIN_STORE_PLAN_DROP, PIN_STORE_PLAN_PICK, EXECUTION_PIN, PIN_STORE_PLAN_PICK_EXECUTION, PIN_STORE_PLAN_DROP_EXECUTION, PIN_STORE_FINALIZE_DROP_EXECUTION, PIN_STORE_FINALIZE_PICK_EXECUTION, EXECUTION_PICK_PIN, EXECUTION_DROP_PIN, MARKER_STOP, MARKER_BIKE, MARKER_TRUCK } from '@pages/MapView/store/svgImageCode';
import { MARKER_TYPES } from '../pages/MapView/store/constants';
import get from 'lodash/get';
export var defaultCenter = {
  lat: 21.04002195878051,
  lng: 105.81293843696434
};
export var mapStyles = {
  width: '100%',
  height: '100%'
};
export var buildMarkerIcon = function buildMarkerIcon(_ref) {
  var color = _ref.color,
      storeNumber = _ref.storeNumber,
      isPlannedOrLocked = _ref.isPlannedOrLocked,
      isExecution = _ref.isExecution,
      deliverColor = _ref.deliverColor,
      isPlanExecution = _ref.isPlanExecution,
      isLongHaul = _ref.isLongHaul,
      isPickType = _ref.isPickType;
  var svg = MARKER_STOP;

  if (isPlannedOrLocked) {
    svg = MARKER_STOP;
  }

  if (isExecution) {
    if (isLongHaul) {
      svg = isPickType ? EXECUTION_PICK_PIN : EXECUTION_DROP_PIN;
    } else {
      svg = EXECUTION_PIN;
    }
  }

  svg = svg.replaceAll('{{color}}', color);
  svg = svg.replaceAll('{{number}}', storeNumber);
  svg = svg.replaceAll('{{opacity}}', isPlanExecution ? '0.6' : '1');
  if (deliverColor) svg = svg.replaceAll('{{deliverColor}}', deliverColor);
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
};
export var buildPDPmarkerIcon = function buildPDPmarkerIcon(_ref2) {
  var color = _ref2.color,
      storeNumber = _ref2.storeNumber,
      pickupType = _ref2.pickupType,
      isPlannedOrLocked = _ref2.isPlannedOrLocked,
      isExecution = _ref2.isExecution;
  var svg;

  if (pickupType === 'PICK' && isPlannedOrLocked) {
    svg = PIN_STORE_PLAN_PICK;
    if (isExecution) svg = PIN_STORE_PLAN_PICK_EXECUTION;
  } else if (pickupType === 'PICK' && !isPlannedOrLocked) {
    svg = PIN_STORE_FINALIZE_PICK;
    if (isExecution) svg = PIN_STORE_FINALIZE_PICK_EXECUTION;
  } else if (pickupType !== 'PICK' && isPlannedOrLocked) {
    svg = PIN_STORE_PLAN_DROP;
    if (isExecution) svg = PIN_STORE_PLAN_DROP_EXECUTION;
  } else {
    svg = PIN_STORE_FINALIZE_DROP;
    if (isExecution) svg = PIN_STORE_FINALIZE_DROP_EXECUTION;
  }

  svg = svg.replaceAll('{{color}}', color);
  svg = svg.replaceAll('{{number}}', storeNumber);
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
};
export var getImgUrl = function getImgUrl(_ref3) {
  var orgCategoryName = _ref3.orgCategoryName,
      isLongHaul = _ref3.isLongHaul,
      isExecution = _ref3.isExecution;
  var pinDepot = '/images/mapview/pinDepot.svg';
  var pinDepotExecution = '/images/mapview/pinDepotExecution.svg';
  var pinXDoc = '/images/mapview/pinXDoc.svg';
  var pinSun = '/images/mapview/pinSun.svg';
  var pinPacking = '/images/mapview/pinPacking.svg';
  var pinPackingActual = '/images/mapview/pinPackingActual.svg';

  if (orgCategoryName === 'xDock') {
    return pinXDoc;
  }

  if (orgCategoryName === 'Sun') {
    return pinSun;
  }

  if (isExecution && (orgCategoryName === 'Transporter' || isLongHaul)) {
    return pinPackingActual;
  }

  if (orgCategoryName === 'Transporter' || isLongHaul) {
    return pinPacking;
  }

  return isExecution ? pinDepot : pinDepotExecution;
};
export var getMarkerIcon = function getMarkerIcon(_ref4) {
  var color = _ref4.color,
      isPlannedOrLocked = _ref4.isPlannedOrLocked,
      isLongHaul = _ref4.isLongHaul,
      deliver = _ref4.deliver,
      deliverInfo = _ref4.deliverInfo,
      index = _ref4.index,
      isExecution = _ref4.isExecution,
      deliverColor = _ref4.deliverColor;
  var orgCategoryName = get(deliverInfo, 'orgCategory[0].organizationCategoryName');
  var storeNumber = index;
  var type = get(deliver, 'depotInfo') ? MARKER_TYPES.DEPOT : MARKER_TYPES.STORE;

  switch (type) {
    case MARKER_TYPES.DEPOT:
      var imgDepotUrl = getImgUrl({
        orgCategoryName: orgCategoryName,
        isLongHaul: isLongHaul,
        isExecution: isExecution
      });
      return {
        url: imgDepotUrl,
        size: new window.google.maps.Size(45, 66),
        origin: new window.google.maps.Point(0, -10),
        scaledSize: new window.google.maps.Size(45, 66)
      };

    case MARKER_TYPES.STORE:
      var imgStoreUrl = buildMarkerIcon({
        color: color,
        storeNumber: storeNumber,
        isPlannedOrLocked: isPlannedOrLocked,
        isPlanExecution: isExecution,
        isExecution: isExecution,
        deliverColor: deliverColor
      });
      var pickupType = get(deliver, 'visit_type') === 1 ? 'PICK' : 'DROP';

      if (orgCategoryName === 'xDock') {
        imgStoreUrl = '/images/mapview/pinXDoc.svg';
      }

      if (isLongHaul) {
        imgStoreUrl = buildPDPmarkerIcon({
          color: color,
          storeNumber: storeNumber,
          pickupType: pickupType,
          isPlannedOrLocked: isPlannedOrLocked,
          isExecution: isExecution
        });
      }

      return {
        url: imgStoreUrl,
        size: new window.google.maps.Size(40, 40),
        origin: new window.google.maps.Point(0, 0),
        scaledSize: new window.google.maps.Size(40, 40)
      };

    default:
      return {};
  }
};
export var getNodeMarkersInfo = function getNodeMarkersInfo(deliverDetails) {
  return deliverDetails.map(function (deliverDetail) {
    var storeInfo = get(deliverDetail, 'storeInfo');
    var depotInfo = get(deliverDetail, 'depotInfo');
    var storeCoordinate = get(storeInfo, 'coordinate');
    var depotCoordinate = get(depotInfo, 'coordinate');
    return storeInfo ? {
      position: {
        lat: (storeCoordinate === null || storeCoordinate === void 0 ? void 0 : storeCoordinate.latitude) || null,
        lng: (storeCoordinate === null || storeCoordinate === void 0 ? void 0 : storeCoordinate.longitude) || null
      },
      title: storeInfo.organizationName || storeInfo.fullName,
      type: MARKER_TYPES.STORE,
      id: deliverDetail._id
    } : {
      position: {
        lat: (depotCoordinate === null || depotCoordinate === void 0 ? void 0 : depotCoordinate.latitude) || null,
        lng: (depotCoordinate === null || depotCoordinate === void 0 ? void 0 : depotCoordinate.longitude) || null
      },
      title: depotInfo.organizationName,
      type: MARKER_TYPES.DEPOT,
      id: deliverDetail._id
    };
  });
};
export function speedToColour(speed) {
  var AVERAGE_SPEED = 30;
  if (speed <= 0) return 'red';
  if (speed >= AVERAGE_SPEED) return 'green'; //intermediate colour between red and green

  var rate = Math.round(255 * speed / AVERAGE_SPEED);
  var red = 255 - rate;
  var green = rate;
  var r = red.toString(16);
  var g = green.toString(16);
  var b = '00';
  if (r.length === 1) r = '0' + r;
  if (g.length === 1) g = '0' + g;
  return '#' + r + g + b;
}
export function drawPathByPoints(startPoint, endPoint) {
  var locationDisabled = startPoint.locationDisabled;
  var drawingColour = locationDisabled ? 'gray' : speedToColour(endPoint.speed);
  var polyOptions = {
    strokeColor: 'WHATEVER',
    strokeOpacity: 1.0,
    strokeWeight: 3
  }; // eslint-disable-next-line no-undef

  var poly = new google.maps.Polyline(polyOptions);
  var path = poly.getPath(); //single section
  // eslint-disable-next-line no-undef

  var latLng1 = new google.maps.LatLng(startPoint.latitude, startPoint.longitude); // eslint-disable-next-line no-undef

  var latLng2 = new google.maps.LatLng(endPoint.latitude, endPoint.longitude);
  path.push(latLng1);
  path.push(latLng2); //make encoded path and draw
  // eslint-disable-next-line no-undef

  var encodedPath = google.maps.geometry.encoding.encodePath(path); // eslint-disable-next-line no-undef

  MapTool.drawPath(map, drawingColour, encodedPath);
}

function getMapStyle() {
  var style = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

  switch (style) {
    case 'dark':
      return [{
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{
          saturation: 36
        }, {
          color: '#000000'
        }, {
          lightness: 40
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{
          visibility: 'on'
        }, {
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }, {
          weight: 1.2
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 21
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 29
        }, {
          weight: 0.2
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 18
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 19
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }]
      }];

    case 'light':
      return [{
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [{
          color: '#444444'
        }]
      }, {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [{
          visibility: 'on'
        }]
      }, {
        featureType: 'administrative.province',
        elementType: 'labels.icon',
        stylers: [{
          hue: '#ff0000'
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{
          color: '#f2f2f2'
        }]
      }, {
        featureType: 'poi',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'road',
        elementType: 'all',
        stylers: [{
          saturation: -100
        }, {
          lightness: 45
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [{
          visibility: 'simplified'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'water',
        elementType: 'all',
        stylers: [{
          color: '#CAD2D3'
        }, {
          visibility: 'on'
        }]
      }];

    default:
      return [];
  }
}

export function getMapProps(mapTheme) {
  return {
    zoom: 11,
    mapTypeControl: true,
    fullscreenControl: true,
    zoomControl: false,
    streetViewControl: true,
    styles: getMapStyle(mapTheme)
  };
}
export var markerReplay = function markerReplay(color, vehicleType) {
  var svg = vehicleType === 'bike' || vehicleType === 'MOTOCYCLE' ? MARKER_BIKE : MARKER_TRUCK;
  svg = svg.replaceAll('{{color}}', color);
  var markerCode = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
  return {
    url: markerCode,
    size: new window.google.maps.Size(45, 66),
    origin: new window.google.maps.Point(0, -10),
    scaledSize: new window.google.maps.Size(45, 66)
  };
};