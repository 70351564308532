import get from 'lodash/get';
var USER = window.user;
var ROLE_BY_ACCOUNT = window.roleByAccount;
var MAIN_PERMISSIONS = ['create', 'read', 'update', 'delete', 'import', 'export'];
export var havePermissionsV2 = function havePermissionsV2(_ref) {
  var _allPermissions$permi;

  var moduleName = _ref.moduleName,
      permissionName = _ref.permissionName,
      permissions = _ref.permissions;
  var allPermissions = permissions || ROLE_BY_ACCOUNT;
  var foundModule = allPermissions === null || allPermissions === void 0 ? void 0 : (_allPermissions$permi = allPermissions.permissionsV2) === null || _allPermissions$permi === void 0 ? void 0 : _allPermissions$permi.find(function (module) {
    return module.module === moduleName;
  });
  var isMainPermission = MAIN_PERMISSIONS.includes(permissionName);

  if (isMainPermission) {
    var _mainPermissions$perm;

    var mainPermissions = get(foundModule, 'mainPermissions');
    return (_mainPermissions$perm = mainPermissions[permissionName]) === null || _mainPermissions$perm === void 0 ? void 0 : _mainPermissions$perm.isAllowed;
  }

  var otherPermissions = get(foundModule, 'otherPermissions');
  return otherPermissions ? otherPermissions[permissionName] : true;
};

function isAdminAccount() {
  return (ROLE_BY_ACCOUNT === null || ROLE_BY_ACCOUNT === void 0 ? void 0 : ROLE_BY_ACCOUNT.NameTable) === 'ADMIN';
}

export var havePermission = function havePermission(permission_type, permission_on) {
  if (isAdminAccount() && permission_on === 'ORGANIZATIONS') {
    return true;
  }

  if (ROLE_BY_ACCOUNT === '' && USER && USER.organizationIds && USER.organizationIds.length === 0 && USER.roleIds && USER.roleIds.length === 0 && permission_on === 'ORGANIZATIONS' && permission_type === 'insert') {
    return true;
  }

  return ROLE_BY_ACCOUNT && ROLE_BY_ACCOUNT[permission_type] && ROLE_BY_ACCOUNT[permission_type].includes(permission_on);
};