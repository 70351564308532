import get from 'lodash/get';
import moment from 'moment';
var MINUTES_PER_HOUR = 60;
var HOUR_PER_DAY = 24;
var DATE_FORMAT = 'MM/DD/YYYY';
var DATE_HOUR_FORMAT = 'MM/DD/YYYY HH:mm';

var getMinDeliverTime = function getMinDeliverTime(routes) {
  if (!routes) return '';
  var firstDeliveryRouteTime = get(routes, '[0].deliverDetail[0].deliverTime');
  return routes.reduce(function (acc, curr) {
    var currentFirstDeliveryRouteTime = get(curr, 'deliverDetail[0].deliverTime');
    var currTimeHour = parseInt(String(currentFirstDeliveryRouteTime).split(':')[0]);
    var currTimeMinute = parseInt(String(currentFirstDeliveryRouteTime).split(':')[1]);
    var accTimeHour = parseInt(String(acc).split(':')[0]);
    var accTimeMinute = parseInt(String(acc).split(':')[1]);
    var totalCurrMinutes = currTimeHour * MINUTES_PER_HOUR + currTimeMinute;
    var totalAccMinutes = accTimeHour * MINUTES_PER_HOUR + accTimeMinute;
    if (totalCurrMinutes < totalAccMinutes) return currentFirstDeliveryRouteTime;
    return acc;
  }, firstDeliveryRouteTime);
};

var getMaxDoneTime = function getMaxDoneTime(routes) {
  if (!routes) return '';
  var firstDeliveryRoute = get(routes, '[0].deliverDetail', []);
  var lastRouteDoneTime = get(firstDeliveryRoute[firstDeliveryRoute.length - 1], 'doneTime');
  return routes.reduce(function (acc, curr) {
    var deliverDetail = get(curr, 'deliverDetail', []);
    var lastDeliverDetail = deliverDetail[deliverDetail.length - 1];
    var currentLastRouteDoneTime = get(lastDeliverDetail, 'doneTime', '00:00');
    var currTimeHour = parseInt(String(currentLastRouteDoneTime).split(':')[0]);
    var currTimeMinute = parseInt(String(currentLastRouteDoneTime).split(':')[1]);
    var accTimeHour = parseInt(String(acc).split(':')[0]);
    var accTimeMinute = parseInt(String(acc).split(':')[1]);
    var totalCurrMinutes = currTimeHour * MINUTES_PER_HOUR + currTimeMinute;
    var totalAccMinutes = accTimeHour * MINUTES_PER_HOUR + accTimeMinute;
    if (totalCurrMinutes > totalAccMinutes) return currentLastRouteDoneTime;
    return acc;
  }, lastRouteDoneTime);
};

var convertAbiTimeToDate = function convertAbiTimeToDate(abiTime, selectedDate, isLongHaul) {
  if (isLongHaul) {
    return moment(abiTime).format(DATE_HOUR_FORMAT);
  }

  var timeHour = abiTime === null || abiTime === void 0 ? void 0 : abiTime.split(':')[0];
  var timeMinute = abiTime === null || abiTime === void 0 ? void 0 : abiTime.split(':')[1];
  var daysDistance = timeHour / HOUR_PER_DAY;

  if (daysDistance >= 1) {
    var convertHour = timeHour % HOUR_PER_DAY;
    var convertDay = moment(selectedDate).add(daysDistance, 'days');
    return "".concat(convertDay.format(DATE_FORMAT), " ").concat(convertHour, ":").concat(timeMinute);
  }

  return "".concat(moment(selectedDate).format(DATE_FORMAT), " ").concat(timeHour, ":").concat(timeMinute);
};

export var getTimelineDateTime = function getTimelineDateTime(_ref) {
  var routes = _ref.routes,
      selectedDate = _ref.selectedDate,
      isLongHaul = _ref.isLongHaul;
  var minDeliverTime = getMinDeliverTime(routes);
  var maxDoneTime = getMaxDoneTime(routes);
  var minDeliverDate = convertAbiTimeToDate(minDeliverTime, selectedDate, isLongHaul);
  var maxDoneDate = convertAbiTimeToDate(maxDoneTime, selectedDate, isLongHaul);
  return {
    startDate: minDeliverDate,
    endDate: maxDoneDate
  };
};