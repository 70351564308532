import Request from './Request';
import API_URL from './URLS'; // Shorthands

export var fetchUserList = function fetchUserList(params, options) {
  return Request.post(API_URL.GET_USER_LIST, params, options);
};
export var getUser = function getUser(params, options) {
  return Request.post(API_URL.READ_USER, params, options);
};
export var deleteUser = function deleteUser(params, options) {
  return Request.post(API_URL.DELETE_USER, params, options);
};
export var deleteUserGroup = function deleteUserGroup(params, options) {
  return Request.post(API_URL.DELETE_ORG_ROLE, params, options);
};
export var resetPasswordUser = function resetPasswordUser(params, options) {
  return Request.post(API_URL.RESET_PASSWORD_USER, params, options);
};
export var getListRoles = function getListRoles(params, options) {
  return Request.post(API_URL.GET_ROLE_NAME_LIST, params, options);
};
export var getOrgListRoles = function getOrgListRoles(params, options) {
  return Request.post(API_URL.GET_ORG_ROLE_LIST, params, options);
};
export var updateWebConfig = function updateWebConfig(params, options) {
  return Request.post(API_URL.UPDATE_WEB_CONFIG, params, options);
};
export var updateUser = function updateUser(params, options) {
  return Request.post(API_URL.UPDATE_USER, params, options);
};
export var createUser = function createUser(params, options) {
  return Request.post(API_URL.CREATE_USER, params, options);
};
export var UploadImgUser = function UploadImgUser(params, options) {
  return Request.post(API_URL.UPLOAD_IMG, params, options);
};
export var createSampleData = function createSampleData(params, options) {
  return Request.post(API_URL.CREATE_SAMPLE_DATA, params, options);
};
export var deleteSampleData = function deleteSampleData(params, options) {
  return Request.post(API_URL.DELETE_SAMPLE_DATA, params, options);
}; // EOF