import axios from 'axios';
import getCookie from '@api/getCookie';
var instance = axios.create({
  withCredentials: true
});
instance.interceptors.request.use(function (config) {
  config.headers['Root-Org-ID'] = rootOrganizationOnLoad._id; // Do something before request is sent

  if (!/^http/.test(config.url)) {
    if (!/^\//.test(config.url)) {
      config.url = '/' + config.url;
    }

    config.url = APIHOST + config.url;
  }

  var jwtToken = getCookie('jwtToken');
  config.headers['X-Access-Token'] = jwtToken;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
export default instance;