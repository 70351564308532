import _ from 'lodash';
export var getPayloadEditMode = function getPayloadEditMode(_ref) {
  var routes = _ref.routes,
      selectedDateAndBranch = _ref.selectedDateAndBranch;
  return {
    rootData: {
      organizationId: _.get(selectedDateAndBranch, 'branch.id'),
      routePlanId: _.get(_.head(routes), 'routePlanId'),
      dataDate: _.get(selectedDateAndBranch, 'date'),
      offset: new Date().getTimezoneOffset()
    },
    routeList: routes.map(function (route) {
      return {
        _id: _.get(route, '_id'),
        shiftStartTime: _.get(_.head(_.get(route, 'deliverDetail')), 'deliverTime'),
        driverInfo: {
          vehicleCode: _.get(route, 'driverInfo.vehicleCode')
        },
        transporterAcceptanceStatus: _.get(route, 'transporterAcceptanceStatus'),
        synced: _.get(route, 'synced'),
        optimizedAt: _.get(route, 'optimizedAt'),
        deliveryDate: _.get(route, 'deliveryDate'),
        deliverDetail: _.get(route, 'deliverDetail', []).map(function (deliver) {
          return {
            orderInfo: _.get(deliver, 'orderInfo', []).map(function (order) {
              return _.get(order, 'orderCode');
            }),
            depotInfo: _.get(deliver, 'depotInfo') ? {
              organizationCode: _.get(deliver, 'depotInfo.organizationCode')
            } : null,
            storeInfo: _.get(deliver, 'storeInfo') ? {
              customerCode: _.get(deliver, 'storeInfo.customerCode')
            } : null,
            tripCode: _.get(deliver, 'tripCode')
          };
        }),
        shiftCode: _.get(route, 'shiftCode'),
        createdBy: _.get(route, 'createdBy', {}),
        assignedDriver: _.get(route, 'assignedDriver')
      };
    })
  };
};