var _window$rootOrganizat;

var isDHL = (_window$rootOrganizat = window.rootOrganizationOnLoad) === null || _window$rootOrganizat === void 0 ? void 0 : _window$rootOrganizat.isTMSSyncing;
var order = [{
  v: "Order Date",
  required: true,
  dataType: "date"
}, {
  v: "Due Date",
  dataType: "date",
  required: isDHL
}, {
  v: "ETA Date",
  dataType: "date",
  required: isDHL
}, {
  v: "Order Code",
  required: true
}, {
  v: "Order Type"
}, {
  v: "Customer Code"
}, {
  v: "Customer Name"
}, {
  v: "Customer Address"
}, {
  v: "Pickup Point"
}, {
  v: "Depot Pickup Date",
  dataType: "date"
}, {
  v: "Original Order Date",
  dataType: "date"
}, {
  v: "Estimated Delivery Date",
  dataType: "date"
}, {
  v: "PO Due Date",
  dataType: "date"
}, {
  v: "POD Target Date",
  dataType: "date"
}, {
  v: "Customer Min Unloading Time"
}, {
  v: "Customer Max Unloading Time"
}, {
  v: "Allowed Vehicle Types"
}, {
  v: "Product Code",
  required: true
}, {
  v: "Product Name"
}, {
  v: "Time Window"
}, {
  v: "Number Of Cases"
}, {
  v: "Number Of Items",
  required: true
}, {
  v: "Total Price"
}, {
  v: "Customer Discount"
}, {
  v: "Sale Discount"
}, {
  v: "Promotion Discount"
}, {
  v: "IMVD Discount"
}, {
  v: "Pickup Order"
}, {
  v: "Service Time (minutes)"
}, {
  v: "Splitted"
}, {
  v: "Lot Number"
}, {
  v: "Expired Date",
  dataType: "date"
}, {
  v: "Note"
}, {
  v: "Ship-to Code",
  required: isDHL
}, {
  v: "Ship-to Name",
  required: isDHL
}, {
  v: "Ship-to Address",
  required: isDHL
}, {
  v: "Volume (m3)",
  required: isDHL
}, {
  v: "Weight (kg)",
  required: isDHL
}, {
  v: "Parent Customer Group Code"
}, {
  v: "MDP"
}, {
  v: "Mobile Number"
}, {
  v: "Latitude"
}, {
  v: "Longitude"
}];
var SYSTEMHEADER = {
  ORDER: order,
  ORDER_BRANCH: [{
    v: "Organization Code",
    required: true
  }, {
    v: "Shipper Code",
    required: true
  }].concat(order),
  PARTNER: [{
    v: "Organization Code"
  }, {
    v: "Partner Code"
  }, {
    v: "Partner Name"
  }, {
    v: "Partner Group Code"
  }, {
    v: "Title"
  }, {
    v: "Phone Number"
  }, {
    v: "Home Number"
  }, {
    v: "City"
  }, {
    v: "Street Address"
  }, {
    v: "Latitude"
  }, {
    v: "Longitude"
  }, {
    v: "Email"
  }, {
    v: "Open Time"
  }, {
    v: "Close Time"
  }, {
    v: "Lunch Time"
  }, {
    v: "Min Time"
  }, {
    v: "Max Time"
  }, {
    v: "Time Window"
  }, {
    v: "Bike Only"
  }, {
    v: "Truck Only"
  }, {
    v: "Sales Code"
  }, {
    v: "MDP"
  }, {
    v: "Serial Number"
  }, {
    v: "Allow Vehicle Types"
  }, {
    v: "Ship-to ID"
  }, {
    v: "Site Code"
  }, {
    v: "Ship-to Expected Lead Time"
  }, {
    v: "Ship-to Address"
  }, {
    v: "Ship-to Open and Close Time"
  }, {
    v: "Ship-to Timewindow"
  }, {
    v: "Ship-to Mobile Number"
  }, {
    v: "Ship-to Receiving Capacity "
  }, {
    v: "Ship-to Unloading Time"
  }],
  PRODUCT: [{
    v: "Product Category Code"
  }, {
    v: "Product Code"
  }, {
    v: "Product Name"
  }, {
    v: "Case Price"
  }, {
    v: "Item Price"
  }, {
    v: "Number Per Case"
  }, {
    v: "On-hand Quantity"
  }, {
    v: "Unit"
  }, {
    v: "Conversion Unit"
  }, {
    v: "Weight (kg)"
  }, {
    v: "Volume (m3)"
  }, {
    v: "Length (m)"
  }, {
    v: "Width (m)"
  }, {
    v: "Height (m)"
  }, {
    v: "Start Date"
  }, {
    v: "End Date"
  }, {
    v: "Parent Product Code"
  }, {
    v: "Tracking By"
  }, {
    v: "Variant Property"
  }, {
    v: "Product Serial"
  }, {
    v: "Temperature Level"
  }]
};
export default SYSTEMHEADER;